import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import './digital.css'

function Digitalm() {
  return (
    <>
          <Helmet>
        <title>Digital Marketing-Deinertech Software</title>
        {/* <meta name="description" content="Explore our digital marketing services including content marketing, social media marketing, SEO, and more at Deinertech Software. Boost your online presence and achieve your business goals with us." />
        <meta name="keywords" content="Digital Marketing, Content Marketing, Social Media Marketing, SEO, SEM, Email Marketing, Lead Generation, WhatsApp Marketing" />
        <meta name="author" content="Deinertech Software" />
        <meta property="og:title" content="Digital Marketing Services - Deinertech Software" />
        <meta property="og:description" content="Explore our digital marketing services including content marketing, social media marketing, SEO, and more. Boost your online presence with Deinertech Software's expert solutions." />
        <meta property="og:url" content="https://www.deinertechsoftware.com/digital" />
        <meta property="og:type" content="website" /> */}
</Helmet>
<About></About>


      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Digital Marketing</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Digital Marketing</h6>
        </div>
      </div>


      <div class="Acoount-info">
     <h2 className="digital-h2">Explore Our Online Growth Services</h2>
         <p class="dev-p1  mt-2"> We are passionate about driving measurable results through strategic and data-driven digital marketing solutions.With a team of experienced  marketers, we help businesses reach their target audience, increase brand visibility, and maximize their online ROI.  Our mission is to propel your business forward in the digital landscape and achieve sustainable growth</p>
      </div>





      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2 custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/conatct">
              <h2 class="ourservices-h2">Content Marketing</h2>
              <p class="ourcomay-p">Welcome to Deinertech Software, your premier partner in leveraging technology for business growth.
                We specialize in providing tailored solutions that empower businesses to thrive in the digital era.
                Whether you’re looking to enhance your online presence, streamline operations, or boost customer satisfaction,
                we have the expertise and tools to make it happen.</p>
                </Link>
              <button className="btn-digital"><Link to="/conatct" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/conatct">
            <img class="image-ourcompay " src={'./contentimge.png'} alt="Hospital Mang" />
            </Link>

          </div>
        </div>
      </div>
 


      <div class="container">
        <div class="row">
          <div class="col-md-5 order-1 order-md-2  custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/social">
            <img class="image-ourcompay " src={'./socialimge.png'} alt="Hospital Mang" />
            </Link>

          </div>
          <div class="col-md-6  order-2  order-md-1 custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/social">
              <h2 class="ourservices-h2">Social Media Marketing</h2>
              <p class="ourcomay-p">In today’s digital age, social media has become a powerful platform for businesses to connect with their
                audience, build brand awareness, and drive conversions. At Deinertech Software, we specialize in crafting tailored
                social media marketing strategies that elevate your brand’s presence and impact in the digital landscape.</p></Link>
              <button className="btn-digital"><Link to="/social" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2 custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/searchm">
              <h2 class="ourservices-h2">Search Engine Marketing</h2>
              <p class="ourcomay-p">In the competitive digital landscape, getting noticed by your target audience is crucial. Search Engine
                Marketing (SEM) is a powerful strategy that allows businesses to enhance their online visibility, attract qualified leads,
                and drive conversions. At Deinertech Software, we specialize in creating results-driven SEM campaigns tailored
                to your business objectives.</p>
                </Link>
              <button className="btn-digital"><Link to="/searchm" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/searchm">
            <img class="image-ourcompay " src={'./searchnewimg.png'} alt="Hospital Mang" />
            </Link>

          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-5  order-1 order-md-2  custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/Email">
            <img class="image-ourcompay " src={'./email marketingimg.png'} alt="Hospital Mang" />
            </Link>

          </div>
          <div class="col-md-6  order-2  order-md-1  custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/Email">
              <h2 class="ourservices-h2">Email Marketing</h2>
              <p class="ourcomay-p">Email marketing remains one of the most effective channels for reaching and engaging your target audience.
                At Deinertech Software, we offer comprehensive email marketing solutions designed to help you build relationships,
                drive conversions, and achieve your business goals.</p>
                </Link>
              <button className="btn-digital"><Link to="/Email" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        </div>
      </div>
 

      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2  custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/LeadG">
              <h2 class="ourservices-h2">Lead Generation</h2>
              <p class="ourcomay-p">In today’s competitive business landscape, generating quality leads is essential for sustained growth and
                success. At Deinertech Software, we specialize in creating targeted lead generation strategies that help
                businesses connect with potential customers, nurture relationships, and drive conversions.
              </p>
              </Link>
              <button className="btn-digital"><Link to="/LeadG" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/LeadG">
            <img class="image-ourcompay " src={'./leadimge.png'} alt="Hospital Mang" />
            </Link>

          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-5  order-1 order-md-2   custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/Email">
            <img class="image-ourcompay " src={'./whatsappimg.png'} alt="Hospital Mang" />
            </Link>

          </div>
          <div class="col-md-6 order-2 order-md-1  custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/Whatsapp">
              <h2 class="ourservices-h2">WhatsApp Marketing</h2>
              <p class="ourcomay-p">WhatsApp has become a ubiquitous communication tool, with billions of users worldwide. Leveraging WhatsApp for marketing purposes can be highly effective in reaching and engaging your target audience. At Deinertech Software, we offer tailored WhatsApp marketing solutions
              to help businesses boost engagement, drive conversions, and achieve their marketing goals.</p>
                </Link>
              <button className="btn-digital"><Link to="/Whatsapp" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        </div>
      </div>
























































































  

      <Footer></Footer>







    </>
  )
}
export default Digitalm;