import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './Enquiref.css';

const EnquireForm = ({ onSubmit = () => {} }) => { // Default to an empty function
    const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '' });
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 120000);

        return () => clearTimeout(timer);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            from_name: formData.name,
            from_email: formData.email,
            phone_number: formData.phoneNumber,
        };

        emailjs.send('service_ekwkbrx','template_1qb52j7' , templateParams, 'JgnrqzzAXY0wuBPvD')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Your message has been sent successfully!');
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send the message, please try again.');
            });

        setFormData({ name: '', email: '', phoneNumber: '' });
        onSubmit(formData);
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="modal d-block bg-secondary bg-opacity-50" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"></h5>
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="text-center mb-4">
                            <img src={'./images.png'} alt="Illustration" className="img-fluid form-image" />
                        </div>
                        <h2 className="text-center mb-3">Let’s Connect!</h2>
                        <p className="text-center">We're here to help you with your tech needs. <br />
                            Reach out to us for expert solutions and advice.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Enter your name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email address"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Enter your phone number"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnquireForm;