import React from 'react';
import './clites.css';

function Ouverclites() {
    const images = [
        require('../image/SHREE BIO-ORGANIC.png'),
        require('../image/slider18-removebg-preview.png'),
        require('../image/slider1.png'),
        require('../image/slider2.png'),
        require('../image/slider3.png'),
        require('../image/slider8.png'),
        require('../image/slider4.png'),
        require('../image/slider11.jpg'),
        require('../image/Slider5.png'),
        require('../image/slider9.png'),
    ];

    const images1 = [
        require('../image/slider10 (2).jpg'),
        require('../image/slider12.jpg'),
        require('../image/slider13.jpg'),
        require('../image/slider14.jpg'),
        require('../image/slider16.jpg'),
        require('../image/slider17.jpg'),
    
   
        require('../image/slider11 (2).jpg'),
        require('../image/slider16.jpg'),
        ];

    return (
        <div className="slider-wrapper">
            <div className="slider-title">
                <h1 className="our-clients">Our Clients</h1>
            </div>

            {/* Marquee for first set of images (scrolls left) */}
            <div className="marquee-slider">
                <div className="marquee">
                    <div className="marquee-inner">
                        {images.concat(images).map((image, index) => (
                            <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <img className="ouverclient-img img-fluid" src={image} alt={`Slider ${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Marquee for second set of images (scrolls right) */}
            <div className="marquee-slider reverse">
                <div className="marquee">
                    <div className="marquee-inner">
                        {images1.concat(images1).map((image, index) => (
                            <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <img className="ouverclient-img img-fluid" src={image} alt={`Slider ${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ouverclites;
