import React, { useState, useEffect } from 'react';
import './ImageSlider.css';

function ImageSlider() {
  const images = [
    require('./image/slider1.png'),
    require('./image/slider2.png'),
    require('./image/slider3.png'),
    require('./image/slider4.png'),
    require('./image/Slider5.png'),
    require('./image/slider8.png'),
    require('./image/slider9.png'),
    require('./image/slider10 (2).jpg'),
    require('./image/slider11 (2).jpg'),
    require('./image/slider12.jpg'),
    require('./image/slider13.jpg'),
    require('./image/slider14.jpg'),
    require('./image/slider16.jpg'),
    require('./image/slider17.jpg'),
    require('./image/slider18.jpg')

     ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      showNextImage();
    }, 1000); 

    return () => {
      clearInterval(interval); 
    };
  }, [currentImageIndex]); 
  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="clients">
            <h1 className="our-clients">Our Clients</h1>
        <div className="container1">
         <div className="slider-container">
        <div className="slider" style={{ transform: `translateX(-${currentImageIndex *20}%)` }}>
          {images.map((image, index) => (
            <img
              key={index}
              className="slider-img"
              src={image}
              alt={`Slider ${index}`}
            />
          ))}
        </div>
      </div>
    </div>
    </div>
  
  );
}

export default ImageSlider;
