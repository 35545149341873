import React from "react";

function Blog() {
    return (
        <>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-12 information">
                        <h3 class="info">Blog</h3>
                        <h3 class="info1">Trending IT
                            <span class="ourc-s"> Solution  </span>Article & Tips
                        </h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog;