import React from "react";
import './BlogD.css'

import { Link } from "react-router-dom";

function BlogD() {
  return (
    <>
      <div class="container cards-custm ">
        <div class="row">

          <div class="col-lg-3 col-md-4 col-sm-6">
            <Link to="/Blogwebd" style={{ textDecoration: 'none' }}>
              <div class="card exper-card1 shadow rounded ">
                <div className="img-card-erp">
                  <img src={'./designnew.png'} />
                </div>
                <div class="card-body">
                  <h6 class="Blog-D">Why Every Business Needs a Website
                    The Importance of Modern Web Design
                  </h6>
                  <p class="Blogd-p">In today's fast-paced business environment, having a strong online presence is essential...</p>
                  <a href="/Blogwebd" class="btn btn-primary read-more">Read More</a>

                </div>
              </div>
            </Link>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Erpblog" style={{textDecoration:'none'}}>
            <div class="card exper-card1 shadow rounded">
              <div className="img-card-erp ">
              <img src={'./erpblognew.png'}/>
                </div>
              <div class="card-body">
              <h6 class="Blog-D">ERP Solutions for Modern Businesses Streamlining Operations and Growth </h6>
               <p class="Blogd-p">Discover how ERP solutions can enhance business efficiency, streamline workflows, and provide a robust foundation</p>
               
               <a href="/Erpblog" class="btn btn-primary read-more">Read More</a>

              </div>
            </div>
          </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/DigitalBlog" style={{textDecoration:'none'}}>
            <div class="card exper-card1 shadow rounded">
              <div className="img-card-erp ">
              <img src={'/Digitalmblog.png'}/>
              </div>
              <div class="card-body">
              <h6 class="Blog-D">Why Digital Marketing is Potent For Your
              Business 
               </h6>
               <p class="Blogd-p">In today’s fast-paced business world,
              digital marketing has become a vital driver of business progression, subsidizing businesses</p>
               <a href="/DigitalBlog" class="btn btn-primary read-more">Read More</a>

              </div>
            </div>
           </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Readm" style={{textDecoration:'none'}}>
            <div class="card exper-card1 shadow rounded">
              <div className="img-card-erp">
              <img src={'/Blog.png'}/>
              </div>
              <div class="card-body">
              <h6 class="Blog-D">Java: Unleashing the Full Power of a Versatile and Robust Programming Giant.
               </h6>
               <p class="Blogd-p">Prepare to embark on a journey through the realm of Java - a programming language
               that stands tall ....</p>
               <a href="/Readm" class="btn btn-primary read-more">Read More</a>
              </div>
            </div>
            </Link>

          </div>
        </div>
      </div>




    </>
  )
}

export default BlogD;