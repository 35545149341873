import React, { useState } from "react";
import About from '../Navbar/About';
import Requstf from "../Requst/Requst";
import { Link } from "react-router-dom";
import './hospital.css';
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function HospitalM() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
  
    return (
        
        <>
            <Helmet>
                <title>Hospital Management System Deinertech Software</title>
                {/* <meta name="description" content="Explore Deinertech Software's advanced Hospital Management System, designed to streamline operations, reduce costs, and enhance patient care. Learn more about our solutions for hospitals and clinics." />
                <meta name="keywords" content="Hospital Management System, Healthcare Software, Pharmacy Software, Clinical Software, OPD Inpatient Software, Deinertech Software" />
                <meta name="author" content="Deinertech Software Pvt Ltd" />
                <meta property="og:title" content="Hospital Management System | Deinertech Software" />
                <meta property="og:description" content="Explore Deinertech Software's advanced Hospital Management System, designed to streamline operations, reduce costs, and enhance patient care. Learn more about our solutions for hospitals and clinics." />
                <meta property="og:image" content="/hospitalm.png" />
                <meta property="og:url" content="https://www.deinertech.com/Hospital-Management-System" />
                <meta property="og:type" content="website" /> */}
            </Helmet>

            <About />

            <div className="container-helthcare">
                <div className="text-center mt-5">
                    <h1 className="hosp-h1">Hospital Management System</h1>
                    <h6 className="hosp-h">
                        <Link to="/" className="dropdown-item" style={{ color: "black" }}>
                            Home <i className="bi bi-chevron-double-right"></i>
                        </Link>
                    </h6>
                    <h6 className="hosp-h">Hospital Management System</h6>
                </div>
            </div>

            <div className="container">
                <div className="container-info">
                    <div className="row">
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <img className="image-info" src={'/hospitalm.png'} alt="Hospital Mang" />
                        </div>
                        <div className="col-md-7">
                            <div className="content">
                                <h1 className="hos-heding">
                                    <span className="hospital-h">Hospital </span> Management System
                                </h1>
                                <p className="hos-p">Our Hospital Management Information System empowers hospitals with essential tools 
                                    for efficient service provision. From assisting doctors, assistants, and paramedical staff 
                                    in addressing queries promptly to supporting management tasks like staff shift 
                                    management, attendance tracking, HR management, payroll, accounting, and 
                                    inventory control, our system ensures smooth operations.
                                </p>

                                <p className="hos-p">Furthermore, our Hospital Management Information System has successfully reduced 
                                    hospital running and operational costs by minimizing the need for excessive stationery 
                                    and manual record-keeping. Tasks that were once handwritten can now be efficiently 
                                    managed within our software, accessible to authorized personnel. Rest assured, the 
                                    confidentiality of hospital data is guaranteed with advanced security protocols. 
                                    Reports, fiscal analyses, patient histories, and sensitive information remain 
                                    protected within our system, safeguarding against unauthorized access or breaches.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-box">
                <div className="box-info">
                    <div className="box-heading">
                        <img src={'/com.png'} alt="Pharmacy Software" />
                        <h5 className="box-h">Pharmacy Software:</h5>
                    </div>
                    <p className="hosp-p">Our Pharmacy Software is an essential module of our Hospital Management
                        Software, providing comprehensive support across all aspects. From inventory
                        management and accounting to vendor and customer records, this software
                        efficiently handles stock-related information. It's a highly advanced Hospital ERP
                        system trusted by numerous hospitals, clinics, and pharmacy stores.
                    </p>
                </div>

                <div className="box-info">
                    <div className="box-heading">
                        <img src={'/software.png'} alt="Clinical Software" />
                        <h5 className="box-h">Clinical Software:</h5>
                    </div>
                    <p className="hosp-p">The Clinical Software is more than just a data entry system—it's a comprehensive
                        clinic management resource that handles all aspects of clinic operations. From
                        managing patient health records and histories to diagnostic reports, It is the part of
                        one of the best Hospital Management Software that is recommended by most of the
                        hospitals and clinics.
                    </p>
                </div>

                <div className="box-info">
                    <div className="box-heading">
                        <img src={'/free.png'} alt="OPD/ Inpatient Software" />
                        <h5 className="box-h">OPD/ Inpatient Software:</h5>
                    </div>
                    <p className="hosp-p">Discover efficiency and optimization with our OPD/Inpatient Software, an essential
                        component of our Hospital Management Software. This software consolidates key
                        hospital operations, providing a centralized platform for data management, medical
                        report integration, patient health tracking, and real-time operational updates.
                    </p>
                </div>
            </div>

            <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"-10px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Enhance Healthcare Efficiency with Deinertech Software</h3>
                <h6 className="box-h6">Ready to implement a comprehensive and user-friendly Hospital Management System that optimizes operations? Partner with Deinertech Software for professional healthcare software solutions that streamline your hospital management processes. Contact us today to discuss your needs and embark on a journey towards operational excellence in healthcare.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer />
        </>
    );
}

export default HospitalM;
