
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import './Searchm.css'
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";
import React, { useState } from "react";

function Searchm() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (
  

    <>
      <Helmet>
        <title>Search Engine Marketing Deinertech Software</title>
        {/* <meta name="description" content="Drive targeted traffic and conversions with Deinertech Software's expert search engine marketing solutions. Partner with us for results-driven SEM campaigns tailored to your business needs." />
        <meta name="keywords" content="Search Engine Marketing, SEM, Online Advertising, Digital Marketing, Deinertech Software, Paid Search, PPC, Ad Campaigns, Online Visibility, Traffic Generation" />
        <meta name="author" content="Deinertech Software" />
        <meta property="og:title" content="Search Engine Marketing | Deinertech Software" />
        <meta property="og:description" content="Drive targeted traffic and conversions with Deinertech Software's expert search engine marketing solutions. Partner with us for results-driven SEM campaigns tailored to your business needs." />
        <meta property="og:url" content="https://www.deinertech.com/searchm" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      

      <About></About>
      <div class="container-Searcheng">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Search Engine Marketing</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Search Engine Marketing</h6>
        </div>
      </div>



      <div class="Acoount-info">
        <h3 class="conantctm-h2 mb-4">Drive Targeted Traffic And Conversions With Deinertech Software’s Search Engine Marketing Solutions</h3>
    
        <h6 class="dev-p1">In the competitive digital landscape, getting noticed by your target audience is crucial. Search Engine  Marketing (SEM) is a powerful strategy that allows businesses to enhance their online visibility, attract qualified leads,
           and drive conversions. At Deinertech Software, we specialize in creating results-driven SEM campaigns tailored to your business objectives.</h6>
     

        <h2 class="pos-h4">Our Approach to Social Engine Marketing</h2>
      </div>


    


<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical">
                    <h4 class="elc-h3">In-Depth Keyword Strategy</h4>
                    <h6 class="elc-h6">We conduct extensive keyword analysis to uncover the terms your audience is searching for. This ensures your SEM campaigns are optimized for maximum relevance and visibility in search results.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical">
                    <h4 class="elc-h3">Targeted Ad Placement</h4>
                    <h6 class="elc-h6">Our experts strategically position your ads on search engine results pages (SERPs) and across relevant networks to reach your ideal customers at the right moment, enhancing click-through rates and engagement.</h6>
                </div>
            </div>
        </div>

        <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/searchm.png'} alt="Search Engine Marketing" />
        </div>

        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Compelling Ad Campaigns</h4>
                    <h6 class="elc-h6">Our creative team crafts impactful ad campaigns designed to capture attention and drive traffic. We focus on engaging copy and visuals that resonate with your target audience and encourage action.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Continuous Performance Optimization</h4>
                    <h6 class="elc-h6">We monitor and analyze the performance of your SEM campaigns in real-time, adjusting strategies based on data insights to enhance effectiveness and improve your return on investment (ROI).</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-social">
                    <h4 class="elc-h3">Conversion Tracking Solutions</h4>
                    <h6 class="elc-h6">Effective conversion tracking is vital for measuring SEM success. We implement tracking systems that monitor critical actions, such as purchases and lead submissions, allowing us to attribute results accurately to specific campaigns.</h6>
                </div>
            </div>
        </div>
    </div>
</div>



      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose2">
              <h2 class="">Why Choose Deinertech Software for Search Engine Marketing?</h2>
              <br />


              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">
                    Expertise: Our team of SEM specialists has extensive experience in creating and managing successful SEM campaigns across various industries.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Customization: We tailor our SEM strategies to align with your business goals, target audience, and budget, ensuring maximum effectiveness.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Transparent Reporting: We provide regular reports and insights into your SEM campaign performance, keeping you informed every step of the way.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Continuous Improvement: We believe in continuous optimization and refinement to achieve optimal results and drive ongoing success.</h5>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Get Ahead with SEM from Deinertech Software</h3>
                <h6 class="box-h6">Ready to boost your online visibility, attract more qualified leads, and drive conversions? Partner with Deinertech Software for expert search engine marketing solutions. Contact us today to discuss
                  your SEM needs and take your digital marketing efforts to the next level.</h6>
                  <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

      <Footer></Footer>


















    </>
  )
}
export default Searchm;