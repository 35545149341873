import React from "react";
import About from "../Navbar/About";
import './expertise.css'
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Expertise() {
  return (
    <>
       <Helmet>
        {/* <meta name="description" content="Deinertech Software Pvt Ltd specializes in providing domain expertise in Healthcare, Advertising, Banking & Finance, Travel & Hospitality, Information Technology, and Real Estate. Explore our tailored solutions for your industry." />
        <meta name="keywords" content="Healthcare solutions, Advertising services, Banking & Finance technology, Travel & Hospitality innovations, Information Technology services, Real Estate solutions, Domain Expertise, Deinertech Software Pvt Ltd" />
        <meta name="author" content="Deinertech Software Pvt Ltd" /> */}
        <title>Domain Expertise Deinertech Software Pvt Ltd</title>
        {/* <meta property="og:title" content="Domain Expertise | Deinertech Software Pvt Ltd" />
        <meta property="og:description" content="Explore Deinertech Software Pvt Ltd's domain expertise in Healthcare, Advertising, Banking & Finance, Travel & Hospitality, Information Technology, and Real Estate. Tailored solutions for diverse industries." />
  
        <meta property="og:url" content="https://www.deinertech.com/Domain-expertise" /> */}
    
      </Helmet>
      <About></About>

      <div class="container-Expertise">
        <div class="text-center mt-5 ">
          <h1 class="exper-h1">Domain Expertise</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Domain Expertise</h6>
        </div>
      </div>



      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/health-care" className="dome-link">
            <div class="card exper-card1 shadow rounded">
              <div className="img-card-erp">
              <img class="card-img-top erp-img" src={'./expertices1.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h4 class="erp-title1">Healthcare</h4>
                <h6 class="erp-info"> Got it! Here's the revised content with your company name,  Deinertech Software Pvt Ltd</h6>
                <h6><Link to="/health-care" className="read-info">Read More</Link></h6>


              </div>
            
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Advertisement" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top erp-img" src={'./Adv1.png'} />
              </div>
              <div class="card-body">
                <h4 class="erp-title1">Advertising</h4>
                <h6 class="erp-info">Certainly! Here's a sample  content for advertising services  provided by Deinertech</h6>
                <h6><Link to="/Advertisement" className="read-info">Read More</Link></h6>
             </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/banking-and-finance" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top erp-img" src={'./banking1.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h4 class="erp-title1">Banking & Finance</h4>
                <h6 class="erp-info">Absolutely, here's a sample content for banking and finance services offered by Deinertech</h6>
                <h6><Link to="/banking-and-finance" className="read-info">Read More</Link></h6>

              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>



      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/travel-and-hospitality" className="dome-link">
           <div class="card exper-card1 shadow rounded">
           <div className="img-card-erp">
              <img class="card-img-top erp-img" src={'./terval.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h4 class="erp-title1">Travel & Hospitality</h4>
                <h6 class="erp-info"> we specialize in providing innovative technology  solutions for the travel and hospitality industry.</h6>
                <h6><Link to="/travel-and-hospitality" className="read-info">Read More</Link></h6>



              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/information-technologies" className="dome-link">
            <div class="card  exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top erp-img" src={'./reale.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h5 class="erp-title1">Information Technology</h5>
                <h6 class="erp-info">Got it! Here's the revised content with your company name, Deinertech Software Pvt Ltd soluation</h6>
                <h6><Link to="/information-technologies" className="read-info">Read More</Link></h6>




              </div>
            </div>
            </Link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/real-estate" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top erp-img" src={'./tecnology.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h5 class="erp-title1">Real Estate</h5>
                <h6 class="erp-info"> Deinertech, we understand the dynamic nature of the real estate industry. Our comprehensive solutions</h6>
                <h6><Link to="/real-estate" className="read-info">Read More</Link></h6>
                 </div>
            </div>
            </Link>
          </div>
          
        </div>
      
      </div>
















      <Footer></Footer>

    </>
  )
}
export default Expertise;