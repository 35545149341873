import React from 'react';


const ElfsightWidget = () => {
  return (
    <div>
      {/* <Helmet>
     
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet> */}
      <div className="elfsight-app-d18efe68-9087-4451-984e-94e4ca6d3b83" data-elfsight-app-lazy></div>
    </div>
  );
};

export default ElfsightWidget;
