import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './ourservices.css';
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Ourservices() {
  return (
    <>
      <Helmet>
        <title>Our Services Deinertech Software</title>
        <meta name="description" content="Explore the range of services offered by Deinertech Software, including Digital Marketing, Development, Design, and Salesforce Cloud solutions." />
        <meta name="keywords" content="Digital Marketing, Web Development, Design, Salesforce Cloud, Deinertech Software" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Our Services Deinertech Software" />
        <meta property="og:description" content="Explore the range of services offered by Deinertech Software, including Digital Marketing, Development, Design, and Salesforce Cloud solutions." />
        <meta property="og:image" content="https://yourwebsite.com/path-to-your-image.png" />
        <meta property="og:url" content="https://deinertech.com/services" />
        <meta property="og:type" content="website" />
      </Helmet>

      <About />

      <div className="container-Expertise">
        <div className="text-center mt-5">
          <h1 className="exper-h1">Services</h1>
          <h6 className="exper-h">
            <Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link>
          </h6>
          <h6 className="exper-h">Services</h6>
        </div>
      </div>

      <div className="Acoount-info1 col-md-12">
        <h1 className="Our-services-h1">Our Services</h1>
        <h6 className="Our-services-p">We are passionate about crafting visually stunning and user-friendly websites that leave a lasting impression. With a team of talented designers and developers, we combine creativity and technical expertise to deliver exceptional web design solutions. Our goal is to help businesses establish a strong online presence and achieve their digital objectives.</h6>
      </div>

      {/* Digital Marketing Section */}
      <div className="container">
        <div className="row">
          {/* For small screens: Image first, content after */}
          <div className="col-md-6 order-2 order-md-2 custom-padding">
            <div className="content">
              <Link to="/digital" className="linksalesc">
                <h2 className="ourservices-h2">Digital Marketing</h2>
                <p className="ourcomay-p">We are passionate about driving measurable results through strategic and data-driven digital marketing solutions. With a team of experienced marketers, we help businesses reach their target audience, increase brand visibility, and maximize their online ROI. Our mission is to propel your business forward in the digital landscape and achieve sustainable growth.</p>
              </Link>
              <button className="btn-digital"><Link to="/digital" className="Discover-more">Read more</Link></button>
            </div>
          </div>
          <div className="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
            <Link to="/digital" className="linksalesc">
              <img className="image-ourcompay img-fluid" src={'./digitalpage.png'} alt="Digital Marketing" />
            </Link>
          </div>
        </div>
      </div>
      

      {/* Development Section */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1 custom-padding">
            <div className="content">
              <Link to="/development" className="linksalesc">
                <h2 className="ourservices-h2">Development</h2>
                <p className="ourcomay-p">Your website is the heart of your online presence, but it's only one piece of the digital puzzle. To truly thrive in today's landscape, businesses need a robust suite of software and technology solutions. That's why Deinertech Software, your Pune-based IT partner, offers a full range of services to power your digital transformation. In today's digital world, your website is often the first point of contact between your business and potential customers.</p>
              </Link>
              <button className="btn-digital"><Link to="/development" className="Discover-more">Read more</Link></button>
            </div>
          </div>
          <div className="col-md-5 order-1  order-md-2 custom-padding d-flex justify-content-end">
            <Link to="/development" className="linksalesc">
              <img className="image-ourcompay img-fluid" src={'./developmentpage.png'} alt="Development" />
            </Link>
          </div>
        </div>
      </div>
   

      {/* Design Section */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-2 custom-padding">
            <div className="content">
              <Link to="/Design" className="linksalesc">
                <h2 className="ourservices-h2">Design</h2>
                <p className="ourcomay-p">That's where Deinertech Software, your trusted Pune-based IT partner, comes in. We understand the power of a well-designed and expertly developed website. Since 2023, we've been helping businesses across diverse industries achieve their online goals. Whether you're starting from scratch with a brand-new website or looking to revamp your existing one, our team has the skills and experience to deliver results.</p>
              </Link>
              <button className="btn-digital"><Link to="/Design" className="Discover-more">Read more</Link></button>
            </div>
          </div>
          <div className="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
            <Link to="/Design" className="linksalesc">
              <img className="image-ourcompay img-fluid" src={'./designpage (1).png'} alt="Design" />
            </Link>
          </div>
        </div>
      </div>


      {/* Salesforce Cloud Section */}
      

      <Footer />
    </>
  );
}

export default Ourservices;
