import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
function Design() {
  return (
    <>
      <Helmet>
        <title>Design Deinertech Software</title>
        {/* <meta name="description" content="Enhance your brand's visual identity with Deinertech Software's design services. We offer web designing, graphic design, UX/UI design, and video editing to create impactful and engaging digital experiences." />
        <meta name="keywords" content="Design Services, Web Designing, Graphic Design, UX/UI Design, Video Editing, Digital Solutions, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Design - Deinertech Software" />
        <meta property="og:description" content="Enhance your brand's visual identity with Deinertech Software's design services. We offer web designing, graphic design, UX/UI design, and video editing to create impactful and engaging digital experiences." />
        <meta property="og:url" content="https://www.deinertech.com/Design" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Deinertech Software" /> */}
        
      </Helmet>
      <About></About>


      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Design</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Design</h6>
        </div>
      </div>

      <div class="Acoount-info">
      
        <h3 class="digital-h2">Elevate Your Business with Deinertech Software's Comprehensive Digital Solutions</h3>
     

        <p class="dev-p1 mt-3"> Deinertech Software, your trusted IT partner in Pune, specializes in creating custom websites that are designed to grow your business online. Whether you need a new site or a redesign, our expert team delivers responsive, SEO-optimized websites that drive traffic and conversions. We also offer digital marketing services, including SEO and social media marketing, to enhance your online presence.</p>
      </div>


    



      <div class="container">
        <div class="row">
          <div class="col-md-6  order-2 order-md-2 custom-padding ">
            <div class="content">
            <Link  className="linksalesc " to="/Webdesign">
           <h2 class="ourservices-h2">Web Designing</h2>
              <p class="ourcomay-p">At Deinertech Software, we offer comprehensive web design services tailored to meet
                your business needs and elevate your online presence. Our expert web designers
                combine creativity with technical expertise to deliver visually stunning and user-friendly
                websites that engage and convert visitors. Here's what our web design services entail:</p>
                </Link>
              <button className="btn-digital"><Link to="/Webdesign" className="Discover-more">Read more </Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc " to="/Webdesign">
            <img class="image-ourcompay " src={'./webdesignnew.png'} alt="Hospital Mang" />
           </Link>
          </div>
        
        </div>
        
      
      </div>
     


      <div class="container">
        <div class="row">
          <div class="col-md-5 order-1 order-md-2 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc " to="/graphical"> 
            <img class="image-ourcompay " src={'./graphicnew.png'} alt="Hospital Mang" />
          </Link>
          </div>
          <div class="col-md-6 order-2 order-md-1 custom-padding ">
            <div class="content">
            <Link  className="linksalesc " to="/graphical"> 
              <h2 class="ourservices-h2">Graphic Design</h2>
              <p class="ourcomay-p">Transform your brand's vision into captivating visuals with our expert Graphic Design services at Deinertech
                Software. We specialize in creating stunning designs that leave a lasting impact and elevate your brand
                identity.</p>
                </Link>
              <button className="btn-digital"><Link to="/graphical" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        
        </div>
      </div>
    


      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2  custom-padding ">
            <div class="content">
            <Link  className="linksalesc " to="/UIUX">
              <h2 class="ourservices-h2">UX / UI Design</h2>
              <p class="ourcomay-p">UI/UX design refers to the process of creating digital interfaces that are both visually appealing and user-friendly.
                UI (User Interface) design focuses on the look and feel of the interface, including layout, typography, and
                color scheme, aiming to enhance user interaction and satisfaction. UX (User Experience) design involves
                understanding users' behaviours and needs, then designing interfaces that provide seamless, intuitive experiences.
                It encompasses user research, prototyping, and testing to ensure efficient navigation and meaningful interactions.</p>
                </Link>
                
              <button className="btn-digital"><Link to="/UIUX" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1  custom-padding d-flex justify-content-end">
             <Link  className="linksalesc " to="/UIUX">
            <img class="image-ourcompay " src={'./uiuxnewimg.png'} alt="Hospital Mang" />
             </Link>
          </div>
          
        </div>
      </div>

      <div class="container">
        <div class="row">
        <div className="col-md-5 order-1 order-md-2  custom-padding d-flex justify-content-end">
        <Link  className="linksalesc" to="/VideoE">
       
            <img class="image-ourcompay " src={'./videonew.png'} alt="Hospital Mang" />
</Link>
          </div>
          
          <div class="col-md-6 order-2 order-md-1 custom-padding ">
            <div class="content">
            <Link  className="linksalesc " to="/VideoE">
              <h2 class="ourservices-h2">Video edting </h2>
              <p class="ourcomay-p">Transform your brand's vision into captivating visuals with our expert Video Editing services at Deinertech Software. We specialize in creating stunning videos
                that leave a lasting impact and elevate your brand identity.</p>
                </Link>
              <button className="btn-digital"><Link to="/VideoE" className="Discover-more">Read more</Link></button>
            </div>
          </div>
         </div>
       </div> 
       
      

        <div class="container">
        <div class="row">
          <div class="col-11">
            <h1 class="develop-h1">Benefits of Choosing Deinertech</h1>
          </div>

          <div class="col-11">
  

                      <ul class="b">
                      <li class="carrer-li" style={{fontSize:"18px"}}>Streamlined Operations:Boost efficiency and productivity with custom software and data-driven insights.</li>
                      <li class="carrer-li" style={{fontSize:"18px"}}>Enhanced Customer Experience: Provide exceptional digital interactions across all touchpoints.</li>
                      <li class="carrer-li" style={{fontSize:"18px"}}>Increased Online Visibility: Reach the right audience through optimized websites and targeted digital solutions.</li>
                      <li class="carrer-li" style={{fontSize:"18px"}}>Competitive Advantage: Stand out in a crowded marketplace with innovative technology.</li>
                      <li class="carrer-li" style={{fontSize:"18px"}}>Ready to unlock your business potential? Contact Deinertech Software today for a free consultation.</li>

                    </ul>
          </div>
        </div>
      </div>
      <Footer></Footer>




    </>
  )
}
export default Design;