
import React from "react";
import { Link } from "react-router-dom";
import About from "../Navbar/About";
import Footer from "../Footer/Footer";



function Informationj() {
  function changeColor(button) {
    // Remove 'active' class from all buttons
    const buttons = document.querySelectorAll('.btn-custom');
    buttons.forEach(btn => btn.classList.remove('active'));

    // Add 'active' class to the clicked button
    button.classList.add('active');
  }

  return (
    <>
      <About></About>
      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Information Technology</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h" ><Link to="/Javas" className="dropdown-item" > Blog   <i class="bi bi-chevron-double-right"></i></Link></h6>
          <a href="/Informationj" class="javau-a">
            <h6 class="exper-h">Information Technology</h6>
          </a>
        </div>
      </div>





      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-9">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="item">
                  <img src={'./Blog.png'} alt="Image 1" class="img-fluid" />
                  <div class="java-p">
                    <p> <i class="bi bi-clock"><span class="javas-p">  April 27, 2024 </span></i></p>
                    <a href="/Bydeiner" class="javau-a">
                      <p> <i class="bi bi-person"><span class="javas-p">By  </span> <span class="span-dei">deinertech</span> </i></p></a>
                    <a href="/Informationj" class="javau-a">
                      <p> <i class="bi bi-tags">Information Technology</i></p></a>
                  </div>
                  <h4>Java: Unleashing the Power of a Versatile Programming<br /> Giant</h4>



                  <p>Prepare to embark on a journey through the realm of Java - a programming language <br /> that stands tall  as an undisputed titan in the digital age.</p>
                  <a href="/Readm" class="btn btn-primary read-more">Read More</a>
                </div>
              </div>

            </div>
          </div>
          <div class="col-lg-3 java-blog">
            <h4 class="search-title">Search</h4>
            <br />
            <div class="search-bar">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search..." />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="bi bi-search"></i>
                  </span>
                </div>
              </div>
            </div>
            <br />

            <h4 class="search-title">Recent Posts</h4>
            <br />

            <a href="/Javaut" class="javau-a">
              <h5>Java: Unleashing the <br /> Power of a  Versatile Programming Giant</h5></a>
            <br />


            <h4 class="search-title">Recent Comments</h4>

            <p>No comments to show.</p>
            <br />


            <div class="search-bar">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search..." />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="bi bi-search"></i>
                  </span>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h4 class="search-title">Categories</h4>
            <br />
            <br />

            <a href="/Informationj" class="javau-a">
              <h6 class="hover-effect">

                <i class="bi bi-chevron-right"></i> Information Technology
              </h6></a>
            <br />

            <br />


            <h4 class="search-title">Recent News</h4>
            <div class="recent-news">
              <img class="react-img img-fluid" src={'./Blog.png'} />
              <h6>Java: Unleashing the <br /> Power of a Versatile <br /> Programming Giant<br /> <br />
                <i class="bi bi-calendar-day"><span class="javas-p">    April 27, 2024 </span></i></h6></div>
            <br />
            <br />
            <h4 class="search-title">Archives</h4>
            <br />

            <h6 class="hover-effect">
              <a href="/April" class="javau-a">
                <i class="bi bi-chevron-right"></i> April</a>
            </h6>

          </div>
        </div>
      </div>



      <Footer></Footer>


    </>



  )
}
export default Informationj;