import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import './inventoery.css'
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";

function Inventory() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
    return (
        <>
            <Helmet>
                <title>Inventory Software Deinertech Software </title>
                {/* <meta name="description" content="Streamline your operations with our Inventory Management Software. Efficiently manage stock, warehouse operations, and shipments." />
                <meta name="keywords" content="Inventory Management Software, Stock Management, Warehouse Management, Barcode Scanning, Multi-Company, Stock Tracking" />
                <meta name="author" content="Deinertech Software Pvt Ltd" />
                <meta property="og:title" content="Inventory Software - Deinertech Software" />
                <meta property="og:description" content="Streamline your operations with our Inventory Management Software. Efficiently manage stock, warehouse operations, and shipments." />
                <meta property="og:url" content="https://www.deinertech.com/inventory-software" />
                <meta property="og:type" content="website" /> */}

            </Helmet>
            <About></About>


            <div class="container-inverntory">
                <div class="text-center mt-5">
                    <h1 class="hosp-h1">Inventory Software</h1>
                    <h6 class="hosp-h" ><Link to="/" className="dropdown-item" >Home</Link></h6>
                    <h6 class="hosp-h">Inventory Software</h6>
                </div>
            </div>
            <div class="container">
                <div class="container-info">
                    <div class="row">
                        <div class="col-md-5 d-flex justify-content-center align-items-center">
                            <img class="image-info " src={'/stock-tracking-system.png'} alt="Hospital Mang" />
                      
                        </div>
                        <div class="col-md-7">
                            <div class="content">
                            <h1 class="account-heding"> <span class="account-h">  Inventory   </span>Management System</h1>
                            <h5 class="account-h4">Optimize Your Operations with Inventory Management Software</h5>
                            <p class="hos-p">Streamline your processes and save time with our Inventory Management Software,
                                a collaborative tool designed to link all departments seamlessly.Our software acts as a
                                warehouse manager, enabling your team to add packages, enroll shipment charges, and track deliveries efficiently.</p>
                            <h5 class="account-h4">Efficient Stock Management</h5>
                            <p class="hos-p">Organize your stock effectively using our Stock Management Software.Tag, locate, place, and dispatch
                                stock with ease. Our software ensures fresh stock is tagged and placed in the right racks,
                                while outgoing  packages are labeled, shipped, and tracked. Keep your customers updated with shipment tracking details in real-time.</p>
                              
                                </div>
                        </div>
                    </div>
                </div>
            </div>


        
        

            <div class="Acoount-info">
                <h3 class="pos-h3">Inventory Management Software Modules Key</h3>
                <h3 class="pos-h3 ">Features:</h3>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 bulet-point-event">
                        <div class="bullet-points-left bullet-points p-md-5">
                            <ul>
                                <li> Multi Company and Branches</li>
                                <li> Multiple Price Levels</li>
                                <li>Item Packing and Measures</li>
                                <li>Stock Taking and Counting</li>
                                <li>Bill of Materials (BOM)</li>
                                <li> Colour and Size (apparel matrix)</li>
                                <li>Define Automatic Reorder Points</li>
                                <li>Stock Transfer</li>
                           
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="bullet-points p-md-5">
                            <ul class="left-ul">
                                <li> Multiple Categories and Departments</li>
                                <li>  Track Serial Numbers, Batch Numbers</li>
                                <li>  Define Currency and Price</li>
                                <li>  Security, Logins</li>
                                <li>  Vendor / Customer Transaction Drill Down</li>
                                <li>  Interactive Reporting</li>
                                <li>  Tax Codes</li>
                                <li>Barcode Printing and Scanning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"-10px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Streamline Your Inventory Operations with Our Comprehensive Software</h3>
                <h6 className="box-h6">Our Inventory Software simplifies stock management, order tracking, and performance analysis. It streamlines communication between inventory staff
                     while providing real-time analytics for better decision-making. Easily manage stock levels, purchase orders, and reports to save time and boost productivity.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
            <Footer></Footer>







        </>
    )
}
export default Inventory;