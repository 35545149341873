import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './UIUX.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";



function UIUX(){
    return(
       <>
         <Helmet>
        <title>UI/UX Design Deinertech Software</title>
        {/* <meta name="description" content="Discover top-notch UI/UX design services at Deinertech Software. Our expert team crafts visually appealing and user-friendly digital interfaces that enhance user experience and drive engagement." />
        <meta name="keywords" content="UI/UX Design, User Interface Design, User Experience Design, Digital Interfaces, Web Design, Mobile App Design, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="UI/UX Design - Deinertech Software" />
        <meta property="og:description" content="Discover top-notch UI/UX design services at Deinertech Software. Our expert team crafts visually appealing and user-friendly digital interfaces that enhance user experience and drive engagement." />
        <meta property="og:url" content="https://www.deinertech.com/UIUX" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
       <About></About>
       <div class="container-UIUX">
    <div class="text-center mt-5">
      <h1 class="helth-h1">UX / UI Design</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">UX / UI Design</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
            <h1 class="pos-h4 mb-4">What Is UI/UX Design?</h1>
          
          <h6 class="dev-p1">UI/UX design refers to the process of creating digital interfaces that are both visually appealing and user-friendly.
           UI (User Interface) design focuses on the look and feel of the interface, including layout, typography, and 
           color scheme, aiming to enhance user interaction and satisfaction. UX (User Experience) design involves 
           understanding users' behaviours and needs, then designing interfaces that provide seamless, intuitive experiences. 
           It encompasses user research, prototyping, and testing to ensure efficient navigation and  meaningful interactions. Ultimately, UI/UX design aims to optimize the user's
         journey through a digital product, enhancing usability, accessibility, and overall satisfaction.</h6>
              
         </div>
       


         <div class="Acoount-info">
            <h1 class="pos-h4 mb-4">Why UI/UX is important?</h1>
           
          <h6 class="dev-p1">UI/UX is crucial because it directly impacts how users interact with and perceive a digital 
          product or service. A well-designed UI/UX can:</h6>
              
         </div>
          
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Enhance user satisfaction</h4>
            <h6 class="elc-h6">Intuitive navigation, visually pleasing layouts, and
          seamless interactions contribute to a  positive user
          experience, increasing satisfaction and retention.</h6>
         </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Boost usability</h4>
            <h6 class="elc-h6">Clear and intuitive design elements streamline navigation, making it easier for users to accomplish tasks efficiently.</h6>
            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/deinertechuiux.png'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Improve accessibility</h4>
            <h6 class="elc-h6">Thoughtful UI/UX design considers diverse
              user needs, ensuring that the interface is
             usable and accessible to all, regardless of 
             abilities or devices used. </h6>
   
           
          
          </div>
        </div>


      

        <div class="elc ">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Drive engagement and <br/> conversions</h4>
            <h6 class="elc-h6">Engaging visuals, compelling content
               presentation, and smooth interactions can
               lead to increased user engagement and 
               higher conversion rates. </h6> 
      


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical2">
            <h4 class="elc-h3">Build brand credibility</h4>
            <h6 class="elc-h6">A polished and user-friendly interface reflects positively on the brand, instilling trust and s credibility among users.</h6> 
   
         </div>
        </div>
        </div>
        </div>
     
            <div class="ui-ux">
            <h6 class="ui-h6">UI/UX is crucial because it directly impacts how users interact with and perceive a digital <br/>
          product or service. A well-designed UI/UX can:</h6>
              
         </div>


     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Uiux-h2">
          <h2 class="">Why you should choose us for UI/UX Designing?</h2>
          <br/>
          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Experienced team blending creativity with user-centric design principles</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Customized digital experiences crafted for your brand.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">
             Thorough research and testing for optimal usability and accessibility</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">Innovation and collaboration driving solutions beyond expectations</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-5-circle-fill number-icon"></i>
             <h5 class="h5-hel">Solutions that drive results and enhance brand credibility</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-5-circle-fill number-icon"></i>
             <h5 class="h5-hel">Transformation of vision into visually stunning reality</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-6-circle-fill number-icon"></i>
             <h5 class="h5-hel">Seamless experiences connecting with your audience</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-7-circle-fill number-icon"></i>
             <h5 class="h5-hel">Achieving business objectives through engaging digital solutions</h5>
             </div>
          </div>


               
        </div>
      </div>
   </div>
  </div> 


      <Footer></Footer>
        



   

         













       </>
    )
}
export default UIUX;