import React from "react";
import './flipcard.css';

function Flipcard() {
  return (

    <div className="continer-flipcard">
      <div className="row justify-content-center display: flex">
      

<div className="col-lg-2 col-md-4 col-sm-5 ">
          <a href="/development" target="_blank" rel="noopener noreferrer">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front flip-card-front d-flex align-item-center flex-column justify-content-center">
                  <a href="/development" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img mt-0" src={'/web-programming.png'} alt="Avatar" />
                  </a>
                


                  <h4 class="flipcard-h2"> Development</h4>
                </div>
                <div className="flip-card-back">
                  <a href="/development" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img" src={'/web-programming.png'} alt="Avatar" />
                  </a>
                  <h4 class="flipcard-h2">Development</h4>
                  <p class="flipcard-p">Deinertech  streamlines, drives success, and optimizes costs for development</p>
                </div>
              </div>
            </div>
          </a>
        </div>




        <div className="col-lg-2 col-md-4 col-sm-5 ">
          <a href="/digital" target="_blank" rel="noopener noreferrer">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front flip-card-front d-flex align-item-center flex-column justify-content-center">
                  <a href="digital" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img mt-0" src={'/digital-marketing (2).png'} alt="Avatar" />
                  </a>
                


                  <h4 class="flipcard-h2">Digital Marketing  </h4>
                </div>
                <div className="flip-card-back">
                  <a href="digital" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img" src={'/digital-marketing (2).png'} alt="Avatar" />
                  </a>
                  <h4 class="flipcard-h2">Digital  Marketing  </h4>
                  <p class="flipcard-p">Deinertech digital marketers simplify strategies, drive online success, and optimize costs effectively</p>
                </div>
              </div>
            </div>
          </a>
        </div>



        <div className="col-lg-2 col-md-4 col-sm-5 ">
          <a href="/Design" target="_blank" rel="noopener noreferrer">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front flip-card-front d-flex align-item-center flex-column justify-content-center">
                  <a href="/Design" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img mt-0" src={'/web-programming.png'} alt="Avatar" />
                  </a>
                


                  <h4 class="flipcard-h2">Design </h4>
                </div>
                <div className="flip-card-back">
                  <a href="/Design" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img" src={'/web-programming.png'} alt="Avatar" />
                  </a>
                  <h4 class="flipcard-h2">Design</h4>
                  <p class="flipcard-p">Deinertech, we believe that great design is at the intersection of form and function.</p>
                </div>
              </div>
            </div>
          </a>
        </div>



        <div className="col-lg-2 col-md-4 col-sm-5">
          <a href="/erp-solution" target="_blank" rel="noopener noreferrer">
            <div className="flip-card">
              <div className="flip-card-inner">

                <div className="flip-card-front flip-card-front d-flex align-item-center flex-column justify-content-center">
                  <a href="/erp-solution" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img mt-0" src={'/sales.png'} alt="Avatar" />
                  </a>
               
                  <h4 class="flipcard-h2">ERP  Solution</h4>
                </div>
                <div className="flip-card-back">
                  <a href="/erp-solution" target="_blank" rel="noopener noreferrer">
                    <img className="flip-img" src={'/sales.png'} alt="Avatar" />
                  </a>
                  {/* change */}
                  <h4 class="flipcard-h2">ERP  Solution</h4>
                  <p class="flipcard-p">Discover how Deinertech Software can transform your business operations with our comprehensive ERP solution</p>
                </div>
              </div>
            </div>
          </a>
        </div>

      </div>



    </div>

  );
}

export default Flipcard;
