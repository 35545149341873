import Requstf from "../Requst/Requst";
import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import './salesc.css'
import { Helmet } from "react-helmet";

function Salesc(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    return(
       <>
    
       <About></About>
       <div class="container-salesc">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Sales Cloud</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Sales Cloud</h6>
    </div>
  </div>
        <div class="Acoount-info">
        <h2 class="conantctm-h2 "> <span class="h1-heading">Welcome to  </span> Deinertech Software – Your Trusted IT Consulting Partner</h2>
           <h6 class="dev-p1 mt-0">At Deinertech Software, we understand the dynamic landscape of modern businesses and the crucial role that technology plays in their success. As your trusted IT consulting partner, we offer a range of services designed  to streamline your operations, enhance your technological capabilities, and drive growth.</h6>
              <h1 class="pos-h4">Sales Cloud</h1>
         </div>

          
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Lead Management</h4>
            <h6 class="elc-h6">Lead management in Sales Cloud helps businesses capture, track, and manage potential sales 
                opportunities from various sources, ensuring no lead is overlooked and increasing conversion chances.
                 Key features include lead capture from web forms, emails, and social media, lead scoring to prioritize
                  based on demographics and engagement, assignment rules to automatically allocate leads to sales
                   reps based on criteria like geography or product line, and lead nurturing using automated email
                    campaigns and follow-up reminders</h6>
                
                
           
           
             
         </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Opportunity Management</h4>
            <h6 class="elc-h6">Opportunity management involves tracking and managing sales opportunities through the sales
                 pipeline, from initial contact to closing the deal. Sales Cloud provides robust tools for 
                 this purpose, starting with sales pipeline visualization, which allows businesses to see 
                 all sales opportunities at various stages in the pipeline, providing a clear and 
                 comprehensive view of potential revenue. Deal tracking enables monitoring the progress 
                 of deals, including key activities and communications.</h6> 

            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/Sales-Cloud.webp'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Sales Automation</h4>
            <h6 class="elc-h6">Sales automation tools in Sales Cloud streamline repetitive tasks and processes, 
                enabling sales reps to focus on high-value activities like selling and customer engagement.
                 Key features include email templates for quick and professional communication, 
                 task automation to create tasks and reminders based on predefined triggers,
                  workflow rules to automate processes such as lead assignment and approval routing, 
                  and quote generation based on customer data. These tools increase productivity by
                   reducing time spent on administrative tasks.</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Reporting and Dashboards</h4>
            <h6 class="elc-h6">Reporting and dashboards in Sales Cloud provide valuable insights into sales 
                performance and key metrics, facilitating data-driven decision-making and progress tracking 
                towards goals. Key features include customizable reports for detailed analysis of various sales
                 aspects like lead conversion rates and sales cycle length, real-time dashboards offering
                  visualizations of key metrics and KPIs.</h6> 
       


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-acc">
            <h4 class="elc-h3">Account and Contact Management</h4>
            <h6 class="elc-h6">Sales Cloud's account and contact management feature provides a comprehensive view of
                 customer accounts and contacts, empowering sales teams to maintain detailed records and strengthen 
                 customer relationships. Key features include maintaining customer profiles with detailed information 
                 on accounts and key contacts, tracking interaction history across emails, calls, and meetings, 
                 visualizing relationship mapping to understand influence and decision-making.</h6>

         </div>
        </div>
        </div>
        </div>

     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Us?</h2>
          <br/>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Expertise: Our team comprises seasoned IT professionals with deep industry knowledge and technical expertise.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Client-Centric Approach: We prioritize understanding your business needs and delivering solutions that add tangible value.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Innovation: Stay ahead of the curve with innovative IT solutions that drive digital transformation and business growth.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">Reliability: Count on us for reliable, responsive support and proactive maintenance to keep your systems running smoothly.</h5>
             </div>
          </div>
               
        </div>
      </div>
   </div>
  </div>
  <br/>
  
 
   
<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Get in Touch Today</h3>
                <h6 class="box-h6">Partner with Deinertech Software for comprehensive IT consulting services that empower your business to thrive in the digital age. Contact us to schedule
                a consultation and take the first step towards a more efficient, secure, and competitive IT environment.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} 


   
<Footer></Footer>
         













       </>
    )
}
export default  Salesc;