import React, { useState } from "react";
import About from "./Navbar/About";
import Footer from "./Footer/Footer";
import Requstf from "./Requst/Requst";
import './helathcare.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Banking() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <Helmet>
        <title>Travel and Hospitality-Deinertech-Software</title>
        {/* <meta name="description" content="Discover innovative technology solutions for the travel and hospitality industry with Deinertech. From hotel management systems to online booking platforms, we help businesses enhance customer experiences and drive growth." />
        <meta name="keywords" content="Travel, Hospitality, Technology Solutions, Hotel Management, Online Booking, CRM, Revenue Management" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Travel and Hospitality Solutions - Deinertech Software" />
        <meta property="og:description" content="Innovative technology solutions for the travel and hospitality industry, offering hotel management systems, online booking platforms, CRM, and revenue management tools." />
        <meta property="og:url" content="https://deinertech.com/travel-and-hospitality" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About />

      <div className="container-travel">
        <div className="text-center mt-5">
          <h1 className="helth-h1">Travel and Hospitality</h1>
          <h6 className="Helath-h"><Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link></h6>
          <h6 className="Helath-h">Travel and Hospitality</h6>
        </div>
      </div>

      <div className="welcome">
        <div className="text-center Wel-come">
          <h2 className="heading-h2">Welcome To <span className="span">Deinertech Travel and Hospitality Solutions</span></h2>
        </div>
        <div className="hel-th">
          <h5 className="helath-p mt-0">Deinertech, we specialize in providing innovative technology solutions for the travel and hospitality industry. 
          From hotels to travel agencies, we offer a range of services designed to enhance customer experiences, streamline operations, and drive business growth.</h5>
        </div>
      </div>

      <div className="our-solution">
        <div className="text-center mt-2 our-solutions">
          <h2 className="solution-h2 mb-0">Our Services</h2>
        </div>
      </div>

      {/* card */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="elc mb-4">
              <div className="box-elc">
                <h4 className="elc-h3">Hotel Management System</h4>
                <h6 className="elc-h6">hotel management system. From reservations and core banking solutions. From account management check-ins to housekeeping and guest services, our solution helps you deliver exceptional guest experiences.</h6>
              </div>
            </div>

            <div className="elc">
              <div className="box-elc">
                <h4 className="elc-h3">Customer Relationship Management (CRM)</h4>
                <h6 className="elc-h6">Build lasting relationships with your guests through our CRM solutions. Personalize interactions, gather valuable insights, and drive loyalty marketing campaigns and loyalty programs.</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-center">
            <img className="Helth-img mb-4" src={require('./image/pngtree.png')} alt="Health Image" />
          </div>

          <div className="col-md-4">
            <div className="elc mb-4">
              <div className="box-elc right-border">
                <h4 className="elc-h3">Online Booking Platforms</h4>
                <h6 className="elc-h6">Attract more customers and increase bookings with our solutions. Make it easy for travelers to find and book accommodations, flights, and activities.</h6>
              </div>
            </div>

            <div className="elc">
              <div className="box-elc right-border">
                <h4 className="elc-h3">Revenue Management</h4>
                <h6 className="elc-h6">Optimize your revenue strategy with our revenue management tools. Monitor market trends, adjust pricing strategies in real-time, and maximize revenue across all distribution channels.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="Choose1">
              <h2 className="h2-choose">Why Choose Us</h2>

              <div className="row mb-4 align-items-center">
                <div className="col-md-12 col-12 text-center d-flex built">
                  <i className="bi bi-1-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Industry Experience: With years of experience in the travel and hospitality sector, we understand the unique challenges and opportunities facing businesses in this industry.</h5>
                </div>
                <div className="col-md-11 col-12 d-flex built">
                  <i className="bi bi-2-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Scalable Solutions: Our scalable solutions are designed to grow with your business. Whether you're a small boutique hotel or a large chain, we have the tools and expertise to support your needs.</h5>
                </div>

                <div className="col-md-11 col-12 d-flex built">
                  <i className="bi bi-3-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Innovative Technology: Stay ahead of the competition with our innovative technology solutions. From mobile apps to AI-powered chatbots, we leverage the latest advancements to enhance customer experiences and drive business results.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="box-start1 shadow rounded">
              <div className="box-contant">
                <h3 className="box-h3">Elevate Your Travel and Hospitality Business with Deinertech</h3>
                <h6 className="box-h6">
                  Partner with Deinertech to unlock new opportunities and delight your guests at every touchpoint. Contact us today to discover how our solutions can transform your travel and hospitality business.
                </h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

      <Footer />
    </>
  );
}

export default Banking;
