import React, { useState } from "react";
import About from "./Navbar/About";
import Footer from "./Footer/Footer";
import './helathcare.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Requstf from "./Requst/Requst";

function Information() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (
    <>
     <Helmet>
        <title>Information Technologies Deinertech Software</title>
        {/* <meta name="description" content="Discover cutting-edge Information Technology solutions by Deinertech Software. We offer expert IT services including software development, IT support, consulting, and more." />
        <meta name="keywords" content="Information Technology, IT Solutions, Software Development, IT Support, Managed IT Services, IT Consulting" />
        <meta name="author" content="Deinertech Software" />
    <meta property="og:title" content="Information Technologies | Deinertech Software" />
  <meta property="og:description" content="Explore comprehensive IT services including software development, consulting, and managed IT services by Deinertech Software." />

  <meta property="og:url" content="https://www.deinertech.com/Information" />
  <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>



      <div class="container-infomartiont">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Information Technologies</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Information Technologies</h6>
        </div>
      </div>
       <div class="welcome">
        <div class="text-center Wel-come">
          <h2 className="heading-h2">Welcome To <span class="span">Deinertech Software: Your Premier IT Solutions & Service Provider</span></h2>
        </div>
        <div class=" hel-th">
          <h5 class="helath-p mt-0">Discover how Deinertech Software can transform your business with leading-edge Information Technology  solutions. Our dedicated team of experts is committed to delivering innovation, efficiency, and reliability to every project, empowering businesses of all sizes to thrive in today’s digital landscape.</h5>
        </div>
      </div>

      <div class="our-solution">
        <div class="text-center our-solutions">
          <h2 class="solution-h2 mb-0">Our Services</h2>
        </div>
      </div>



      {/* card */}

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc">
                <h4 class="elc-h3">
                  Software Development</h4>
                <h6 class="elc-h6">
                  Our seasoned developers craft tailored software solutions, leveraging cutting-edge technologies
                  to deliver scalable and robust applications,
                  including web, mobile, and enterprise software.</h6>

              </div>
            </div>

            <div class="elc">
              <div class="box-elc">
                <h4 class="elc-h3">
                  IT Support</h4>
                <h6 class="elc-h6">
                  Access reliable and responsive IT support services to keep your systems running smoothly.
                  From troubleshooting to maintenance, our team is here to assist you every step of the way.
                </h6>


              </div>
            </div>

            <div class="elc">
              <div class="box-elc">
                <h4 class="elc-h3">
                  Customer Relationship Management (CRM)</h4>
                <h6 class="elc-h6">
                  Seamlessly transition to the cloud with our expert cloud migration and support services.
                  Whether you're migrating existing infrastructure   or  implementing cloud-native solutions, we  ensure a smooth and efficient transition.
                </h6>



              </div>
            </div>
          </div>

          <div class="col-md-4   container-fluid d-flex justify-content-center align-items-center">
            <img class=" Helth-img mb-4" src={require('./image/pngtree-information.png')} alt="Health Image" />

          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc right-border">
                <h4 class="elc-h3">

                  IT Consulting
                </h4>
                <h6 class="elc-h6">

                  Maximize efficiency and drive growth with our strategic IT consulting services. Our consultants  work closely with you to identify challenges
                  , devise tailored strategies, and implement solutions  that propel your business forward.

                </h6>


              </div>
            </div>

            <div class="elc">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                  Help Desk

                </h4>
                <h6 class="elc-h6">
                  Streamline IT support processes and enhance user experience with our comprehensive help desk services.
                  Our knowledgeable technicians are available to address inquiries and resolve issues promptly.
                </h6>

              </div>
            </div>

            <div class="elc">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                  Managed IT Services
                </h4>
                <h6 class="elc-h6">

                  Focus on your core business while we handle IT management. Our proactive monitoring,maintenance,
                  and support services ensure optimal performance and reliability for your IT infrastructure.
                </h6>


              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose1">
              <h2 class="h2-choose">Why Choose Us</h2>

              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">Expertise: Benefit from our years of experience and proven track record in tackling complex IT challenges.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel"> Innovation: Stay ahead of the curve with our commitment to embracing emerging technologies and innovative solutions.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel"> Client-Centric Approach: Your success is our priority. We listen to your needs and deliver solutions that exceed expectations.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Reliability: Count on us for dependable support and timely delivery, backed by our unwavering commitment to customer satisfaction.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-5-circle-fill number-icon"></i>
                  <h5 class="h5-hel">
                    Scalability: Our solutions are designed to grow with your business, whether you're a startup or a large enterprise.</h5>
                </div>





              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="box-start1 shadow rounded">
              <div class="box-contant">
                <h3 class="box-h3">Get in Touch</h3>
                <h6 class="box-h6">
                  Ready to elevate your business with cutting-edge IT solutions?
                  Contact us today to schedule a consultation and discover how Deinertech Software can
                  drive your success in the digital age.
                </h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer></Footer>





    </>

  )
}
export default Information;