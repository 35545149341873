import React from "react";
import './Domain.css'
import { Link } from "react-router-dom";



function Domain() {




  return (
    <>

      <div classs="information">
        <h4 class="info"> Awesome Services</h4>
        <h3 class="info1">Our<span class="half2">Domain </span> Expertise</h3>
        <p class="info2"> We’ve excelled our experience in a wide range of industries to bring valuable insights and provide our customers with the truly beneficial  solutions.</p>
      </div>


      <div class="container cards-custm">
        <div class="row">
          <a className="Domain-href" href="/health-care">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="card exper-card shadow rounded ">
                <img src={'./Untitled design (5).png'} />
                <div class="card-body">
                  <h4 class="Blog-D">Healthcare
                  </h4>
                  <p class="Blogd-p">Deinertech Software Pvt Ltd, we’re dedicated to revolutionizing the healthcare industry....</p>
                  <a href="/health-care" class="btn btn-primary read-more">Read More</a>


                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="row">
          <a className="Domain-href" href="/advertisement">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="card exper-card shadow rounded ">
                <img src={'./Untitled design (7).png'} />
                <div class="card-body">
                  <h4 class="Blog-D">Advertising
                  </h4>
                  <p class="Blogd-p">Deinertech, we specialize in delivering results-driven advertising solutions to help businesses thrive.....</p>
                 
                  <a href="/advertisement" class="btn btn-primary read-more">Read More</a>




                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="row">

          <a className="Domain-href" href="/banking-and-finance">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="card exper-card shadow rounded ">
                <img src={'/Untitled design (8).png'} />
                <div class="card-body">
                  <h4 class="Blog-D">Banking & Finance
                  </h4>
                  <p class="Blogd-p">Deinertech, we understand the complexities of the banking and finance industry. That's why we offer.....</p>

                  <a href="/banking-and-finance" class="btn btn-primary read-more">Read More</a>

                </div>
              </div>

            </div>
          </a>
        </div>

      </div>



      <div class="container cards-custm">
        <div class="row">
          <a className="Domain-href" href="/travel-and-hospitality">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="card exper-card shadow rounded ">
                <img src={'/Untitled design (10).png'} />
                <div class="card-body">
                  <h4 class="Blog-D">Travel & Hospitality
                  </h4>
                  <p class="Blogd-p">Deinertech, we specialize in providing innovative technology solutions for the travel and hospitality industry....</p>

                  <a href="/travel-and-hospitality" class="btn btn-primary read-more">Read More</a>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="row">
          <a className="Domain-href" href="/information-technologies">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="card  exper-card shadow rounded " >
                <img src={'/Untitled design (11).png'} />
                <div class="card-body">
                  <h4 class="Blog-D">
                    Information Technology
                  </h4>
                  <p class="Blogd-p">Discover how Deinertech Software can transform your business with leading-edge Information Technology....</p>

                  <a href="/information-technologies" class="btn btn-primary read-more">Read More</a>



                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="row">
          <a className="Domain-href" href="/real-estate">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="card exper-card shadow rounded ">
                <img src={'./Untitled design (9).png'} />
                <div class="card-body">
                  <h4 class="Blog-D">
                    Real Estate
                  </h4>
                  <p class="Blogd-p">Deinertech, we understand the dynamic nature of the real estate industry. Our comprehensive solutions.....</p>

                  <a href="/real-estate" class="btn btn-primary read-more">Read More</a>

                </div>
              </div>
            </div>
          </a>
        </div>
      </div>













    </>
  );
}

export default Domain;
