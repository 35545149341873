import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from '../Heaerd/Headerd';
import './navbar.css';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    setIsMenuOpen(false);
    setActiveDropdown(null);
    document.body.classList.remove('no-scroll');
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    document.body.classList.toggle('no-scroll', !isMenuOpen);
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleDropdownItemClick = () => {
    if (window.innerWidth <= 768) {
      setActiveDropdown(null); // Close dropdown on item click in small screens
      setIsMenuOpen(false); // Also close the main menu
    }
    window.scrollTo({ top: 0, behavior: 'instant' });
  };

  const handleDropdownToggle = (dropdown) => {
    toggleDropdown(dropdown);
    if (activeDropdown !== dropdown) {
      navigate(`/${dropdown}`);
    }
  };

  return (
    <div className="container-navbar">
      <Header />
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img className="img-fluid logo" src="./logo deinertech.gif" alt="Logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className={`navbar-toggler-icon ${isMenuOpen ? 'd-none' : ''}`}></span>
            <span className={`close-btn ${isMenuOpen ? '' : 'd-none'}`}>&times;</span>
          </button>

          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav" ref={dropdownRef}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={() => {
                  toggleMenu();
                  window.scrollTo({ top: 0, behavior: 'instant' });
                }}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/ourcompany" className="nav-link" onClick={() => {
                  toggleMenu();
                  window.scrollTo({ top: 0, behavior: 'instant' });
                }}>
                  Our Company
                </Link>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  onClick={() => handleDropdownToggle('domain-expertise')}
                >
                  Domain Expertise
                </span>
                <ul className={`dropdown-menu ${activeDropdown === 'domain-expertise' ? 'show' : ''}`}>
                  <li><Link to="/health-care" className="dropdown-item" onClick={handleDropdownItemClick}>Health Care</Link></li>
                  <li><Link to="/banking-and-finance" className="dropdown-item" onClick={handleDropdownItemClick}>Banking and Finance</Link></li>
                  <li><Link to="/advertisement" className="dropdown-item" onClick={handleDropdownItemClick}>Advertisement</Link></li>
                  <li><Link to="/travel-and-hospitality" className="dropdown-item" onClick={handleDropdownItemClick}>Travel and Hospitality</Link></li>
                  <li><Link to="/information-technologies" className="dropdown-item" onClick={handleDropdownItemClick}>Information Technologies</Link></li>
                  <li><Link to="/real-estate" className="dropdown-item" onClick={handleDropdownItemClick}>Real Services</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  onClick={() => handleDropdownToggle('OurServices')}
                >
                  Our Services
                </span>
                <ul className={`dropdown-menu ${activeDropdown === 'OurServices' ? 'show' : ''}`}>
                  <li><Link to="/development" className="dropdown-item" onClick={handleDropdownItemClick}>Development</Link></li>
                  <li><Link to="/design" className="dropdown-item" onClick={handleDropdownItemClick}>Design</Link></li>
                  <li><Link to="/digital" className="dropdown-item" onClick={handleDropdownItemClick}>Digital Marketing</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  onClick={() => handleDropdownToggle('erp-solution')}
                >
                  ERP Solutions
                </span>
                <ul className={`dropdown-menu ${activeDropdown === 'erp-solution' ? 'show' : ''}`}>
                  <li><Link to="/hospital-management-system" className="dropdown-item" onClick={handleDropdownItemClick}>Hospital Management System</Link></li>
                  <li><Link to="/school-management-system" className="dropdown-item" onClick={handleDropdownItemClick}>School Management System</Link></li>
                  <li><Link to="/hr-software" className="dropdown-item" onClick={handleDropdownItemClick}>HR Software</Link></li>
                  <li><Link to="/accounting-software" className="dropdown-item" onClick={handleDropdownItemClick}>Accounting Software</Link></li>
                  <li><Link to="/payroll-software" className="dropdown-item" onClick={handleDropdownItemClick}>Payroll Software</Link></li>
                  <li><Link to="/inventory-software" className="dropdown-item" onClick={handleDropdownItemClick}>Inventory Software</Link></li>
                  <li><Link to="/event-management-system" className="dropdown-item" onClick={handleDropdownItemClick}>Event Management System</Link></li>
                  <li><Link to="/pos-software" className="dropdown-item" onClick={handleDropdownItemClick}>POS Software</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  onClick={() => handleDropdownToggle('careers')}
                >
                  Career
                </span>
                <ul className={`dropdown-menu ${activeDropdown === 'careers' ? 'show' : ''}`}>
                  <li><Link to="/careers" className="dropdown-item" onClick={handleDropdownItemClick}>Work With Us</Link></li>
                  <li><Link to="/internship" className="dropdown-item" onClick={handleDropdownItemClick}>Internship</Link></li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/blog" className="nav-link" onClick={() => {
                  toggleMenu();
                  window.scrollTo({ top: 0, behavior: 'instant' });
                }}>
                  Blog
                </Link>
              </li>

              <Link to="/contact-form" className="Enquiry d-none d-lg-inline-block" onClick={() => {
                toggleMenu();
                window.scrollTo({ top: 0, behavior: 'instant' });
              }}>
                Enquiry Now
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
