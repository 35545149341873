import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";

function ReturnPrivacy() {
  return (
    <>
       <Helmet>
        <title>Return Policy - Deinertech Software</title>
       
      </Helmet>
      <About></About>

      <div class="container-Expertise">
        <div class="text-center mt-5">

          <h1 class="exper-h1">Return Policy</h1>

          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Return Policy</h6>
        </div>
      </div>



      <div className="col-md-10">
        <div className="content right-column bullet-points-right dataeng-col  ">
          <h2 class="retuen-h2">How We Use Your Information</h2>
          <p class="retuen-h2">We may use your information for various purposes, including:</p>

          <ul>
            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>   Responding to your inquiries and requests (via email: contact@deinertech.com)</p>

            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>    Providing and improving the Site and Services</p>

            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>    Verifying your authority to access the Site</p>


            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>    Considering your eligibility for employment</p>

            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>    Analyzing website traffic and user behavior</p>


            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>     Sending you relevant marketing communications (with your consent)</p>

            <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>     Fulfilling service requests from our clients</p>

          </ul>
        </div>
      </div>
   

      <div className="col-md-10">
        <div className="content right-column bullet-points-right dataeng-col  ">
          <h2 class="retuen-h2">Your Choices</h2>
          <p class="retuen-h2">We collect informat</p>
      

          <ul>
            <p> <i class="bi bi-record-circle-fill icon-cir">   <span class="dataeng-li">Opt-out of marketing communications </span> <span class="unsub">by clicking the "unsubscribe" link in any email you receive from us. </span></i></p>
            <p> <i class="bi bi-record-circle-fill icon-cir">  <span class="dataeng-li">Limit information collected through the Site </span><span class="unsub">     by adjusting your browser settings (e.g., cookies).</span></i></p>
            <p> <i class="bi bi-record-circle-fill icon-cir">  <span class="dataeng-li">Children's Privacy:  </span><span class="unsub">   Our Site and Services are not directed towards children under 18. We do not knowingly collect personal information from children under 18.</span></i></p>
            <p> <i class="bi bi-record-circle-fill icon-cir">  <span class="dataeng-li"> Request to review, correct, or delete your information </span> <span class="unsub"> by contacting us at contact@deinertech.com. </span></i></p>

          </ul>
        </div>
      </div>
      <Footer></Footer>












    </>
  )
}
export default ReturnPrivacy;