import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './event.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";

function Eventm() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
    return (
        <>
         <Helmet>
                <title>Event Management System Deinertech Software</title>
                {/* <meta name="description" content="Discover our comprehensive Event Management System, ideal for all types of organizations and events. Efficiently manage data sharing, scalability, security, and more." />
                <meta name="keywords" content="Event Management System, Conference Management, Symposium Management, Online Events, Data Sharing, Secure Events" />
                <meta name="author" content="Deinertech Software Pvt Ltd" />
                <meta property="og:title" content="Event Management System - Deinertech Software" />
                <meta property="og:description" content="Discover our comprehensive Event Management System, designed for organizations and events of all sizes. Manage data sharing, scalability, security, and more efficiently with our advanced solutions." />
                <meta property="og:url" content="https://www.deinertech.com/event-management-system" />
                <meta property="og:type" content="website" /> */}

            </Helmet>
            <About></About>

            <div class="container-eventm">
                <div class="text-center mt-5">
                    <h1 class="hosp-h1">Event Management System</h1>
                    <h6 class="hosp-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
                    <h6 class="hosp-h">Event Management System</h6>
                </div>
            </div>



            <div class="container">
                <div class="container-info">
                    <div class="row">
                        <div class="col-md-5 d-flex justify-content-center align-items-center">
                            <img class="image-info " src={'/AdobeStock.jpeg'} alt="Hospital Mang" />
                          </div>
                        <div class="col-md-7">
                            <div class="content">
                        <h1 class="account-heding"> <span class="account-h">Event</span> Management System </h1>
                            <h5 class="account-h4">Elevate Your Events with Our Comprehensive Management System</h5>
                            <p class="hos-p">Our Event Management System is versatile and ideal for all types of  <br />organizations and events.
                                Business setups can host technological and <br />strategic webinars using our multi-dimensional solution,
                                while educational <br />institutions can conduct online educational seminars with a large number <br />of attendees without performance issues.</p>
                            <h4 class="account-h4">Efficient Data Sharing</h4>
                            <p class="hos-p">Our Conference Management System in ensures seamless data sharing,<br /> allowing users to download and share audio, video,
                                documents, and other <br /> files directly through the platform. This eliminates the need for additional  <br />data carriers and comes
                                equipped with a data transfer kit for <br />added convenience.</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="Acoount-info">
                <h3 class="pos-h3 mt-4">Symposium Management System</h3>
                <h3 class="pos-features mt-0">Features:</h3>
            </div>
            
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 bulet-point-event">
                        <div class="bullet-points-left bullet-points p-md-5">
                            <ul class="left-ul">
                                <li>Pre Program Module</li>
                                <li>Program Schedule</li>
                                <li>Activity Calendar</li>
                                <li> Detailed Scientific Programs <br /> and workshops</li>
                                <li> Online Abstract Submission </li>
                                <li>Collect abstracts for any <br />format (oral, poster, <br /> workshop and session)</li>
                                <li> File up loader</li>
                                <li>Clear step by step guide</li>
                                <li> System generated confirmation Emails</li>
                                <li>All abstract goes directly to  <br />database and access by admin panel,</li>
                                <li>no data entry required</li>
                              
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="bullet-points p-md-5">
                            <ul class="left-ul">

                                <li>Batch registration mode</li>
                                <li>System generated confirmation emails</li>
                                <li> Editable Personal information</li>
                                <li> By Single registration participants <br /> can apply for multiple sessions</li>
                                <li>Can submit multiple abstracts</li>
                                <li>Editable Session selection</li>
                                <li>System generated bank vouchers</li>
                                <li>Online Payment (by credit <br /> card or by bank voucher)</li>
                                <li> System generated E-cards with <br />barcode for verified participants
                                    FAQs</li>
                                <li>Online and Offline user support</li>
                                <li>Online Registration</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"-10px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Streamline Your Event Operations with Our Comprehensive Management System</h3>
                <h6 className="box-h6">Our Event Management System simplifies event planning, registration, and performance tracking. It streamlines communication between organizers and attendees while providing real-time analytics for better decision-making. Easily manage event schedules, attendee lists, and reports to save time and boost productivity.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
            <Footer></Footer>





        </>
    )
}
export default Eventm;