import Requstf from "../pages/Requst/Requst";
import React, { useState } from "react";
import About from "../pages/Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer/Footer";
import { Helmet } from "react-helmet";


function Revenuc(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    return(
       <>
           <Helmet>
           <title>Revenue Cloud Deinertech Software</title>
           {/* <meta name="description" content="Discover how Deinertech Software's Revenue Cloud solutions can streamline your revenue management, CPQ automation, advanced analytics, and billing processes." />
           <meta name="keywords" content="Revenue Cloud, CPQ automation, revenue management, billing automation, analytics, Deinertech Software" />
           <meta name="author" content="Deinertech Software" />
           <meta property="og:title" content="Revenue Cloud - Deinertech Software" />
        <meta property="og:description"
          content="Discover how Deinertech Software's Revenue Cloud solutions can streamline your revenue management, CPQ automation, advanced analytics, and billing processes."/>  <meta
          property="og:url"  content="https://www.deinertech.com/Revenuc"/>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Deinertech Software" /> */}
       </Helmet>
       <About></About>
       <div class="container-salesc">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Revenue Cloud</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Revenue Cloud</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
   
            <h1 class="pos-h4">Welcome to Deinertech Software – Your Trusted IT Consulting Partner</h1>
            <br/>
          <h6 class="pos-h6">At Deinertech Software, we understand the dynamic landscape of modern businesses and the crucial role that <br/> technology plays in their success. As your trusted IT consulting partner, we offer a range of services designed <br/> to streamline your operations, enhance your technological capabilities, and drive growth.</h6>
              <h1 class="pos-h4">Revenue Cloud</h1>
         </div>

          
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">End-to-End Revenue Management Solutions</h4>
            <h6 class="elc-h6">Revenue Cloud offers a comprehensive suite of solutions designed to manage the entire revenue lifecycle, from lead 
              generation to revenue recognition. It encompasses various functionalities such as quoting, pricing, contracting, billing,
               and revenue optimization. By providing end-to-end revenue management capabilities, Revenue Cloud enables businesses to streamline 
               processes, increase efficiency, and maximize revenue potential.</h6>
                
                
           
           
             
         </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Configure, Price, Quote (CPQ) Automation</h4>
            <h6 class="elc-h6">One of the core features of Revenue Cloud is its 
              CPQ automation functionality. CPQ streamlines the configuration, pricing, and 
              quoting processes for complex products and services. It allows sales teams to generate accurate
               quotes quickly, ensure pricing consistency, and optimize deal margins. By automating CPQ processes,
                Revenue Cloud helps organizations accelerate sales cycles, improve deal accuracy, and drive revenue growth.</h6> 

            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/Revenue-Cloud-768x768.webp'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Advanced Analytics and Insights</h4>
            <h6 class="elc-h6">Revenue Cloud provides advanced analytics and reporting capabilities to help organizations gain valuable insights into their revenue performance 
              and trends. It offers customizable dashboards, reports, and KPIs that enable businesses
               to track key metrics such as sales pipeline, win rates, pricing effectiveness, and revenue growth.
                By leveraging Revenue Cloud's analytics tools, organizations can identify revenue optimization opportunities,
                 track performance against targets.</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Billing Automation and Revenue Recognition</h4>
            <h6 class="elc-h6">Another critical aspect of Revenue Cloud is its billing automation and revenue recognition functionalities.
               Revenue Cloud automates billing processes, facilitates accurate revenue recognition, and ensures compliance with accounting
                standards such as ASC 606 and IFRS 15. By automating billing cycles, Revenue Cloud helps organizations improve billing accuracy, 
                enhance financial transparency, and optimize revenue recognition practices.</h6> 
       


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-acc">
            <h4 class="elc-h3">Contract Lifecycle Management (CLM)</h4>
            <h6 class="elc-h6">Revenue Cloud includes robust contract lifecycle management capabilities to manage contracts from creation to renewal seamlessly.
               CLM centralizes contract data, standardizes contract workflows, and ensures compliance with regulatory requirements. It enables organizations to
                streamline contract negotiations, reduce contract cycle times, and mitigate risks associated with contract management. With Revenue Cloud's CLM,
                 businesses can optimize contract terms, improve contract visibility, and enhance revenue predictability.</h6>

         </div>
        </div>
        </div>
        </div>
     <br/>

     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Us?</h2>
          <br/>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Expertise: Our team comprises seasoned IT professionals with deep industry knowledge and technical expertise.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Client-Centric Approach: We prioritize understanding your business needs and delivering solutions that add tangible value.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Reliability: Count on us for reliable, responsive support and proactive maintenance to keep your systems running smoothly.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">Innovation: Stay ahead of the curve with access to cutting-edge cloud technologies and solutions that drive business growth and innovation.</h5>
             </div>
          </div>
               
        </div>
      </div>
   </div>
  </div>
  <br/>
  <br/>

        <div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-10 col-sm-12 mx-auto d-block">
        <div class="box-start2">
          <div class="box-contant">
            <h3 class="box-h3">Get Started Today</h3>
            <h6 class="box-h6">Partner with Deinertech Software for comprehensive IT consulting services that empower your business to
               thrive in the digital age. Contact us to schedule a consultation 
              and take the first step towards a more efficient, secure, and competitive IT environment.
            </h6>
            <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
<Footer></Footer>
         
       </>
    )
}
export default Revenuc
;