import React from "react";


function Services(){
    return(
       <>

<div className="container mt-4">
        <h1 className="info1">
        <h4 class="info"> Awesome Services</h4>
          <span className="half1">We Rank Among the Best</span>
          <span className="half2">IT Solutions</span> and Services
          <br/>
      
        </h1>
      </div>
        
        
        

        

</>
    )
}
 export default Services;