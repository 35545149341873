import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function VideoE() {
    return (
       <>
         <Helmet>
            <title>Video Editing Services Deinertech Software</title>
            {/* <meta name="description" content="Elevate your brand with professional video editing services from Deinertech Software. We offer custom video design, marketing content, and more to enhance your brand identity." />
            <meta name="keywords" content="Video Editing, Video Marketing, Video Design, Custom Video, Video Solutions, Deinertech Software" />
            <meta name="author" content="Deinertech Software Pvt Ltd" />
            
         
            <meta property="og:title" content="Video Editing Services - Deinertech Software" />
            <meta property="og:description" content="Elevate your brand with professional video editing services from Deinertech Software. We offer custom video design, marketing content, and more to enhance your brand identity." />

            <meta property="og:url" content="https://www.deinertech.com/VideoE" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Deinertech Software" /> */}
            
      
         </Helmet>

         <About />

         <div className="container-videoe">
            <div className="text-center mt-5">
                <h1 className="exper-h1">Video Editing</h1>
                <h6 className="exper-h">
                    <Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link>
                </h6>
                <h6 className="exper-h">Video Editing</h6>
            </div>
         </div>

         <div className="Acoount-info">
            <h1 className="pos-h4 mb-4">Video Editing</h1>
       
            <h6 className="dev-p1">
                Transform your brand's vision into captivating visuals with our expert Video Editing services at Deinertech Software. We specialize in creating stunning videos that leave a lasting impact and elevate your brand identity.
            </h6>
         </div>

         <div className="our-solution">
            <div className="text-center mt-4 our-solutions">
                <h2 className="solution-h2">Our Creative Services</h2>
            </div>
         </div>

         <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="elc mb-4">
                        <div className="box-graphical">
                            <h4 className="elc-h3">Professional Video Editing</h4>
                            <h6 className="elc-h6">Craft compelling videos that resonate with your audience. From seamless transitions to color grading and typography, we'll create cohesive video elements that reflect your brand's personality and vision.</h6>
                        </div>
                    </div>
                    <div className="elc mb-4">
                        <div className="box-graphical">
                            <h4 className="elc-h3">Video Marketing Content</h4>
                            <h6 className="elc-h6">Make a statement with eye-catching video marketing materials. Our team produces engaging promotional videos, explainer videos, social media clips, and video ads that communicate your message effectively and drive engagement.</h6>
                        </div>
                    </div>
                    <div className="elc mb-4">
                        <div className="box-graphical">
                            <h4 className="elc-h3">Video Editing Solutions</h4>
                            <h6 className="elc-h6">Enhance your online presence with engaging video content. We create captivating video interfaces, dynamic video layouts, and video templates that captivate and retain viewers.</h6>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 text-center">
                    <img className="video-img mb-4" src={'/3809105.jpg'} alt="Video Editing"/>
                </div>

                <div className="col-md-4">
                    <div className="elc mb-4">
                        <div className="box-graphical right-border">
                            <h4 className="elc-h3">Video Editing Expertise</h4>
                            <h6 className="elc-h6">Leave a lasting impression in the digital world. We excel in editing promotional videos, corporate videos, tutorials, and social media content that showcase your brand's professionalism.</h6>
                        </div>
                    </div>
                    <div className="elc mb-4">
                        <div className="box-graphical">
                            <h4 className="elc-h3">Video Storytelling and Animation</h4>
                            <h6 className="elc-h6">Bring concepts to life with custom video animations and storytelling. Our editors specialize in creating compelling visuals that enhance brand storytelling and convey ideas creatively through dynamic video content.</h6>
                        </div>
                    </div>
                    <div className="elc">
                        <div className="box-graphical right-border">
                            <h4 className="elc-h3">Video Editing for Impact</h4>
                            <h6 className="elc-h6">Transform complex information into visually engaging video content. We simplify data and statistics into digestible videos that inform and inspire, making your message clear and memorable.</h6>
                        </div>
                    </div>
                </div>
            </div>
         </div>

         <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="Choose1">
                    <h6 class="graphical-h2 mt-4" style={{color:"blue"}}>OUR PROCESS</h6>
                       
                        <h2 className="graphical-h2">We believe in collaboration and transparency throughout the video editing journey:</h2>
                       
                        <div className="row mb-4 align-items-center">
                            <div className="col-md-12 col-12 text-center d-flex built">
                                <i className="bi bi-1-circle-fill number-icon"></i>
                                <h5 className="h5-hel">Discovery: We listen to your goals and ideas to understand your brand's essence.</h5>
                            </div>
                            <div className="col-md-11 col-12 d-flex built">
                                <i className="bi bi-2-circle-fill number-icon"></i>
                                <h5 className="h5-hel">Conceptualization: Our team brainstorms creative video concepts tailored to your vision.</h5>
                            </div>
                            <div className="col-md-11 col-12 d-flex built">
                                <i className="bi bi-3-circle-fill number-icon"></i>
                                <h5 className="h5-hel">Editing Iteration: We refine videos based on your feedback and input.</h5>
                            </div>
                            <div className="col-md-11 col-12 d-flex built">
                                <i className="bi bi-4-circle-fill number-icon"></i>
                                <h5 className="h5-hel">Finalization: We ensure the final videos meet the highest standards of quality and align with your brand guidelines.</h5>
                            </div>
                        </div>         
                    </div>
                </div>
            </div>
         </div>

         <Footer />
       </>
    )
}

export default VideoE;
