import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './payroll.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";
function Payroll() {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
      
    return (
        <>

                <Helmet>
                <title>Payroll Software Deinertech Software</title>
                {/* <meta name="description" content="Enhance your payroll management with customized software solutions. Our payroll software offers scalability, efficient tracking, and tailored features for businesses of all sizes." />
                <meta name="keywords" content="Payroll Software, Payroll Management, HR Software, Payroll Tracking, Business Software Solutions, Custom Payroll Software" />
                <meta name="author" content="Deinertech Software Pvt Ltd" />
                <meta property="og:title" content="Payroll Software | Deinertech Software" />
                <meta property="og:description" content="Enhance your payroll management with customized software solutions. Our payroll software offers scalability, efficient tracking, and tailored features for businesses of all sizes." />
                <meta property="og:url" content="https://www.deinertech.com/payroll-software" />
                <meta property="og:type" content="website" /> */}
            </Helmet>
            <About></About>


            <div class="container-payroll">
                <div class="text-center mt-5">
                    <h1 class="hosp-h1">Payroll Software</h1>
                    <h6 class="hosp-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
                    <h6 class="hosp-h">Payroll Software</h6>
                </div>
            </div>



<div class="container">
                <div class="container-info">
                    <div class="row">
                        <div class="col-md-5 d-flex justify-content-center align-items-center">
                            <img class="image-info " src={'/payroll-calculator.png'} alt="Hospital Mang" />
                            {/* <img src="your-image.jpg" alt="Image" class="image"/> */}
                        </div>
                        <div class="col-md-7">
                            <div class="content">
                            <h3 class="payroll-heding"> <span class="payroll-h">PAYROLL </span> SOFTWARE DEVELOPMENT </h3>
                            <h5 class="account-h4">Enhance Your Payroll Management with Customized Software</h5>
                            <p class="hos-p">Our Payroll Software Development services empower businesses in streamlining HR and
                                payroll operations. Our software strengthens payroll management, remuneration calculations, and
                                related operations with user-friendly features and robust database management. Our software  is designed
                                to remember your preferences and provide  seamless access wherever you go.</p>

                            <h5 class="account-h4">Tailored Solutions for Every Business</h5>
                            <p class="hos-p">Our Payroll Software caters to businesses of all sizes, from small businesses to large corporations. It offers scalability and
                                customization options to suit  specific business needs. Easily add or exclude features based on your  requirements
                                with quick customization options. Continuous support  ensures a smooth experience for you and your team. </p>

                            <h6 class="account-h4">Efficient Payroll Tracking System</h6>
                            <p class="hos-p">Track payroll efficiently with our Payroll Tracking Software, essential for  distributing bonuses and incentives accurately.
                                Our software monitors team progress and provides detailed reports with a single click, ensuring precise payments and budget management.
                                Simplify payroll processes  and keep your team satisfied with our intuitive tracking system.</p>
                                
                                </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="Acoount-info">
                <h3 class="payroll-h3"> <span class="acc-spn">Payroll</span> Tracking Solutions Module</h3>
                <h4 class="h4-pay">Employee Data Management</h4></div>
                <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 bulet-point-event">
                        <div className="bullet-points-left bullet-points p-md-5">
                            <ul>
                                <li>Alerts (Notification &amp; reminders)</li>
                                <li>Noticeboard </li>
                                <li>Company policy</li>
                                <li>Employee profile</li>
                                <li>Employee leave details</li>
                                <li>Employee gratuity details</li>
                                <li>Staff Requests</li>
                                <li>Performance appraisals</li>
                                <li>Employee attendance tracking</li>
                                <li>Job role changes</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="bullet-points p-md-5">
                            <ul className="left-ul">
                            <li>Leave requests <br/> (Annual, sick, LOP, etc.)</li>
                            <li>Document requests  <br/>(Salary certificate, NOC, etc.)</li>
                            <li>Staff loan requests</li>
                                <li>Reimbursement requests</li>
                                <li> Training requests</li>
                                <li>Business travel request</li>
                                <li>View and attached documents <br/> with requests</li>
                                <li>Online Payslip generation</li>
                              
                            </ul>
                        </div>
                    </div>
                </div>
              </div>

            <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"-10px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Streamline Your Payroll Operations with Our Comprehensive Software</h3>
                <h6 className="box-h6">Our Payroll Software simplifies financial records, payroll management, and performance tracking. It streamlines communication between payroll staff while providing real-time analytics for better decision-making.Easily manage salaries, deductions, and reports to save time and boost productivity.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

            <Footer></Footer>





        </>
    )
}
export default Payroll;