
import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

function Ecommercesm() {
  return (
    <>
      <About></About>


      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">eCommerce Marketing</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">eCommerce Marketing</h6>
        </div>
      </div>

      <div class="Acoount-info">
        <h1 class="pos-h4">eCommerce Marketing</h1>
        <br />
        <h6 class="pos-h6">We are passionate about crafting visually stunning and user-friendly  <br />
          websites that leave a lasting impression. With a team of talented designers <br />
          and developers, we combine creativity and technical expertise to deliver <br />
          exceptional web design solutions. Our goal is to help businesses  <br />establish a strong online presence and achieve their digital objectives.</h6>

      </div>

      <br />
      <br />
      <br />


      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card">

              <div class="card-body">
                <h4 class="dev-info1">eCommerce Optimization</h4>
                <br />
                <h6 class="dev-info">We develop powerful server-side applications and APIs that ensure seamless functionality.</h6>
                <br />
                <h6><Link to="/conatct" className="read-dev">Read More</Link></h6>


              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card">
              <div class="card-body">
                <h4 class="dev-info1">eCommerce SEO</h4>
                <br />
                <h6 class="dev-info">We are passionate about driving measurable results through strategic and data-driven digital marketing solutions.</h6>
                <br />
                <h6><Link to="/social" className="read-dev">Read More</Link></h6>



              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card">
              <div class="card-body">
                <h4 class="dev-info1">eCommerce PPC</h4>
                <br />
                <h6 class="dev-info">Design & optimize scalable data pipelines to streamline the ingestion, processing, & storage of your data.</h6>
                <br />
                <h6><Link to="/searchm" className="read-dev">Read More</Link></h6>

              </div>
            </div>
          </div>
        </div>
      </div>


      <br />
      <br />

      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card">

              <div class="card-body">
                <h4 class="dev-info1">Shopify Web Design</h4>
                <br />
                <h6 class="dev-info">We specialize in crafting high-quality, user-centric mobile applications for both Android and iOS platforms.</h6>
                <br />
                <h6><Link to="/Email" className="read-dev">Read More</Link></h6>


              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card">
              <div class="card-body">
                <h4 class="dev-info1">Shopify SEO</h4>
                <br />
                <h6 class="dev-info">We conduct in-depth research and usability testing to ensure your website delivers an exceptional user experience.</h6>
                <br />
                <h6><Link to="/LeadG" className="read-dev">Read More</Link></h6>



              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card">
              <div class="card-body">
                <h4 class="dev-info1">Target Plus Marketplace</h4>
                <br />
                <h6 class="dev-info">We conduct in-depth research and usability testing to ensure your website delivers an exceptional user experience.</h6>
                <br />
                <h6><Link to="/Whatsapp" className="read-dev">Read More</Link></h6>

              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer></Footer>









    </>
  )
}
export default Ecommercesm;