import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function SalesF() {
  return (
    <>
  
      <About></About>


      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Salesforce Cloud</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Salesforce Cloud</h6>
        </div>
      </div>


      <div class="Acoount-info">
        <h2>Transform Your Operations with Scalable Solutions</h2>
       <p class="dev-p1 mt-2">Salesforce is a cloud-based customer relationship management (CRM) platform that offers a suite of tools and services to help businesses manage their sales,  marketing,customer service, and more.The Salesforce platform operates entirely in the cloud, meaning that users can access it from anywhere with an internet connection, rather than relying on on-premises software.</p>
      </div>

       <div class="container">
        <div class="row">
          <div class="col-md-6  order-2 order-md-2 custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/Salesc">
              <h2 class="ourservices-h2">Sales Cloud</h2>
              <p class="ourcomay-p">Sales Cloud is designed to optimize the sales process, enabling sales teams to manage relationships with prospects and customers more effectively.Sales Cloud is a customer relationship management (CRM) platform by Salesforce, specifically tailored to optimize and streamline the sales process. It provides tools that help sales teams manage relationships with prospects and customers more efficiently and effectively.</p></Link>
              <button className="btn-digital"><Link to="/Salesc" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5  order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/Salesc">
            <img class="image-ourcompay " src={'./sales cloud.png'} alt="Hospital Mang" />
            </Link>

          </div>
        </div>
      </div>
     


      <div class="container">
        <div class="row">
          <div class="col-md-5  order-1 order-md-2   custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/SalesE">
            <img class="image-ourcompay " src={'./Einstein Analytics.png'} alt="Hospital Mang" />
          </Link>
          </div>

          <div class="col-md-6 order-2 order-md-1  custom-padding ">
            <div class="content">
              <Link className="linksalesc" to="/SalesE">
              <h2 class="ourservices-h2">Einstein Analytics</h2>
              <p class="ourcomay-p">Einstein Analytics provides advanced analytics and business intelligence, turning data into actionable insights. This AI-powered platform integrates data from various sources.
              Einstein Analytics, now known as Tableau CRM, is Salesforce's AI-powered advanced analytics and business intelligence platform. It enables businesses to transform vast amounts of data into actionable 
              insights, helping them make informed decisions. 
              Here’s a closer look at what Einstein Analytics offers</p>
              </Link>
              <button className="btn-digital"><Link to="/SalesE" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        </div>
      </div>
 


      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2  custom-padding ">
            <div class="content">
            <Link className="linksalesc" to="/Saless">
              <h2 class="ourservices-h2">Service Cloud</h2>
              <p class="ourcomay-p">Service Cloud enhances customer service operations by providing comprehensive tools for case management, 
                a robust knowledge base, omni-channel routing, and field service management. 
                Service Cloud by Salesforce is a leading customer service platform designed to improve customer satisfaction and service efficiency. It provides a suite of tools that enable
                 businesses to deliver personalized, responsive, and effective support across multiple channels.</p>
                 </Link>
              <button className="btn-digital"><Link to="/Saless" className="Discover-more">Read more</Link></button>
            </div>
          </div>
 
          <div class="col-md-5 order-1 order-md-1  custom-padding d-flex justify-content-end">
          <Link className="linksalesc" to="/Saless">
            <img class="image-ourcompay " src={'./Service Cloud.png'} alt="Hospital Mang" />
            </Link>  
            </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-5  order-2 order-md-2 custom-padding d-flex justify-content-end">
          <Link className="linksalesc" to="/Marketingc">
            <img class="image-ourcompay " src={'./marketing.png'} alt="Hospital Mang" />
           </Link>
          </div>
          <div class="col-md-6 order-2 order-md-1 custom-padding ">
            <div class="content">
            <Link className="linksalesc" to="/Marketingc">
              <h2 class="ourservices-h2">Marketing Cloud</h2>
              <p class="ourcomay-p">Marketing Cloud allows businesses to create and manage sophisticated marketing campaigns 
                across multiple channels. With capabilities in email marketing, social media management. Salesforce Marketing Cloud is a comprehensive platform that enables businesses to create, manage, and optimize sophisticated marketing campaigns across multiple channels.
                 It offers a wide range of tools designed to engage customers through personalized, data-driven marketing strategies.</p></Link>
              <button className="btn-digital"><Link to="/Marketingc" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2  custom-padding ">
            <div class="content">
            <Link className="linksalesc" to="/Helaths">
              <h2 class="ourservices-h2">Health Cloud</h2>
              <p class="ourcomay-p">Health Cloud is tailored for healthcare providers and payers, offering tools to manage patient data, 
                coordinate care plans, and integrate with electronic health records (EHRs).
                Salesforce Health Cloud is a comprehensive customer relationship management (CRM) platform specifically designed for the healthcare industry. It provides healthcare providers, payers, and life sciences organizations with the tools they need to manage patient relationships, coordinate care, and integrate with 
                electronic health records (EHRs). Here’s a closer look at what Health Cloud offers:
              </p>
              </Link>
              <button className="btn-digital"><Link to="/Helaths" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link className="linksalesc" to="/Helaths">
            <img class="image-ourcompay " src={'./health cloud.png'} alt="Hospital Mang" />
            </Link>

          </div>
        </div>
      </div>


      <div class="container">
        <div class="row">
          <div class="col-md-5 order-1 order-md-2 custom-padding d-flex justify-content-end">
          <Link className="linksalesc" to="/Revenuc">
            <img class="image-ourcompay " src={'./revenue cloud.png'} alt="Hospital Mang" />
            </Link>

          </div>
          <div class="col-md-6  order-2 order-md-1 custom-padding ">
            <div class="content">
            <Link className="linksalesc" to="/Revenuc">
              <h2 class="ourservices-h2">Revenue Cloud</h2>
              <p class="ourcomay-p">Revenue Cloud enables businesses to manage their revenue growth more effectively by providing solutions for sales,
                 billing, and revenue recognition. It integrates sales and finance. Salesforce Revenue Cloud is a powerful platform designed to help businesses manage their revenue processes more effectively, from sales to billing to revenue recognition. By integrating sales and finance, Revenue Cloud provides a unified 
                 solution that streamlines operations, increases transparency, and accelerates revenue growth.</p></Link>
              <button className="btn-digital"><Link to="/Revenuc" className="Discover-more">Read more</Link></button>
            </div>
          </div>
        </div>
      </div>
   




















































































  

      <Footer></Footer>







    </>
  )
}
export default SalesF;