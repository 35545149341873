import Requstf from "../pages/Requst/Requst";
import React, { useState } from "react";
import About from "../pages/Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer/Footer";
import { Helmet } from "react-helmet";


function SalesE(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    return(
       <>
         <Helmet>
                <title>Einstein Analytics Reliable IT Support-Deinertech Software</title>
                {/* <meta name="description" content="Explore Einstein Analytics by Deinertech Software, offering advanced data visualization, predictive analytics, and intelligent discovery. Ensure your IT infrastructure runs smoothly with our reliable IT support services." />
                <meta name="keywords" content="Einstein Analytics, IT Support, Data Visualization, Predictive Analytics, Intelligent Discovery, Deinertech Software" />
                <meta name="author" content="Deinertech Software" />
                <meta property="og:title" content="Einstein Analytics - Reliable IT Support - Deinertech Software" />
             <meta property="og:description" content="Discover Einstein Analytics, advanced data visualization and IT support by Deinertech Software, empowering businesses with seamless solutions." />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.deinertech.com/SalesE" /> */}
            </Helmet>
       <About></About>
       <div class="container-salesc">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Einstein Analytics</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Einstein Analytics</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
   
            <h1 class="conantctm-h2 ">Reliable IT Support Services by Deinertech Software</h1>
           
          <h6 class="dev-p1 mt-0">At Deinertech Software, we understand that a reliable IT infrastructure is the backbone of modern businesses. 
            Our IT support services are designed to ensure that your systems operate
             seamlessly, allowing you to focus on your core business activities without interruptions.</h6>
           

             <h1 class="pos-h4">Einstein Analytics</h1>
         </div>

          
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Advanced Data Visualization</h4>
            <h6 class="elc-h6">Einstein Analytics provides a sophisticated array of interactive and customizable
                 data visualization tools, empowering users to explore data dynamically through charts, graphs, maps,
                  and tables. This comprehensive suite of visualization options facilitates a deeper understanding of 
                  data patterns and trends. Moreover, users can effortlessly craft dynamic dashboards that 
                  present key metrics and KPIs in real-time, enabling quick and informed decision-making.</h6>
                
                
           
           
             
         </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Predictive Analytics</h4>
            <h6 class="elc-h6">Leveraging cutting-edge AI and machine learning algorithms, Einstein Analytics unlocks the power of
                 predictive analytics, enabling businesses to anticipate future trends and outcomes. By harnessing predictive insights,
                  organizations can proactively optimize sales strategies, mitigate risks, and capitalize on emerging opportunities.
                   This forward-thinking approach empowers businesses to stay ahead of the curve in a rapidly evolving market landscape.</h6> 

            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/Einstein-Analytics002.webp'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Data Integration</h4>
            <h6 class="elc-h6">Seamlessly integrating with a myriad of data sources, including Salesforce CRM, 
                external databases, and third-party applications, Einstein Analytics provides users with access 
                to unified datasets from multiple sources. This holistic view of data enables organizations to gain 
                a comprehensive understanding of their business operations and performance. By integrating diverse data 
                sources, Einstein Analytics empowers users to derive valuable insights and drive informed decision-making</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Collaboration and Sharing</h4>
            <h6 class="elc-h6">Einstein Analytics fosters a culture of collaboration by facilitating seamless sharing of dashboards, insights,
                 and data stories among team members. This collaborative environment empowers teams to work together in
                  real-time, annotate dashboards, and engage in discussions to drive collective decision-making. 
                  By promoting collaboration, Einstein Analytics enhances teamwork and facilitates knowledge sharing,
                   ultimately driving organizational success.</h6> 
       


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-acc">
            <h4 class="elc-h3">Intelligent Discovery</h4>
            <h6 class="elc-h6">Einstein Analytics harnesses AI-driven insights to automatically unearth relevant patterns, trends, and anomalies within the data. This intelligent discovery capability enables users to uncover hidden insights and 
                actionable recommendations without the need for manual analysis. 
                By leveraging AI-driven insights, organizations can gain a deeper
                 understanding of their data landscape and make informed decisions with confidence.</h6>

         </div>
        </div>
        </div>
        </div>
     <br/>

     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Deinertech Software for IT Support?</h2>
          <br/>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Prompt Response: Our team responds swiftly to IT issues, minimizing downtime and ensuring continuous business operations.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Expertise: Benefit from our team of skilled IT professionals who have extensive experience across a wide range of technologies and industries.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Tailored Solutions: We understand that every business is unique. Our IT support services are tailored to meet your specific needs and budgetary requirements</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">
             Reliability: Count on us for reliable, proactive support that keeps your IT systems running smoothly, allowing you to focus on your business goals.</h5>
             </div>
          </div>
               
        </div>
      </div>
   </div>
  </div>
  <br/>
  <br/>

     


<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Get in Touch Today</h3>
                <h6 class="box-h6">Experience reliable IT support services that empower your business to thrive. Contact Deinertech Software to discuss your IT support requirements and take the first step towards a more efficient and secure IT environment.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
<Footer></Footer>
         
       </>
    )
}
export default  SalesE;