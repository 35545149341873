import React, { useState, useEffect } from "react";
import './client.css';

function Revenu() {
    const [clients, setClients] = useState(1);
    const [projects, setProjects] = useState(1);
    const [retention, setRetention] = useState(1);
    const [increase, setIncrease] = useState(1);

    useEffect(() => {
        let clientsCounter = 0;
        let projectsCounter = 0;
        let retentionCounter = 0;
        let increaseCounter = 0;

        const clientsInterval = setInterval(() => {
            if (clientsCounter < 350) {
                clientsCounter += 5;
                setClients(clientsCounter);
            } else {
                clearInterval(clientsInterval);
            }
        }, 50);

        const retentionInterval = setInterval(() => {
            if (retentionCounter < 72) {
                retentionCounter += 2;
                setRetention(retentionCounter);
            } else {
                clearInterval(retentionInterval);
            }
        }, 50);

        const increaseInterval = setInterval(() => {
            if (increaseCounter < 200) {
                increaseCounter += 2;
                setIncrease(increaseCounter);
            } else {
                clearInterval(increaseInterval);
            }
        }, 50);

        const projectsInterval = setInterval(() => {
            if (projectsCounter < 150) {
                projectsCounter += 2;
                setProjects(projectsCounter);
            } else {
                clearInterval(projectsInterval);
            }
        }, 50);

        return () => {
            clearInterval(clientsInterval);
            clearInterval(projectsInterval);
            clearInterval(increaseInterval);
            clearInterval(retentionInterval);
        };
    }, []);

    return (
        <div className="container ">
            <div className="box justify-content-center display: flex mb-3">
                <div className="box-item">
                    <img className="box-img" src={require('./image/client.png')} alt="Clients" />
                    <div>
                        <h3>{clients}+</h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div className="box-item">
                    <img className="box-img" src={require('./image/recation.png')} alt="Retention" />
                    <div>
                        <h3>{retention}%</h3>
                        <p>Client Retention</p>
                    </div>
                </div>
                <div className="box-item">
                    <img className="box-img" src={require('./image/revenu.png')} alt="Revenue" />
                    <div>
                        <h3>{increase}%</h3>
                        <p>Increase in Revenue</p>
                    </div>
                </div>
                <div className="box-item">
                    <img className="box-img" src={require('./image/project.png')} alt="Projects" />
                    <div>
                        <h3>{projects}+</h3>
                        <p>Successful Projects</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Revenu;
