import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './Privacy Policy.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Privacy() {
  return (
    <>

<Helmet>
        <title>Privacy Policy Deinertech Software</title>
        {/* <meta name="description" content="Learn how Deinertech Software Private Limited collects, uses, and safeguards your personal information through our Privacy Policy." />
        <meta name="keywords" content="Privacy Policy, Personal Information, Data Protection, Deinertech Software" />
        <meta name="author" content="Deinertech Software" />
        <meta property="og:title" content="Privacy Policy | Deinertech Software" />
        <meta property="og:description" content="Learn how Deinertech Software Private Limited collects, uses, and safeguards your personal information through our Privacy Policy." />
        <meta property="og:url" content="https://www.deinertech.com/privacy" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>

      <div class="container-Expertise">
        <div class="text-center mt-5">

          <h1 class="exper-h1">Privacy Policy</h1>

          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Privacy Policy</h6>
        </div>
      </div>
      <div class="Acoount-info">
        <h3  class="pos-h4">Deinertech Software Private Limited</h3>

        <h1 class="pos-h4"> Protecting Your Privacy</h1>
  
        <p class="privacy-p">Deinertech Software Private Limited (“Deinertech,” “we,” “us,” or “our”) is committed to protecting the privacy of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website ([website address]) (the “Site”) and our services
          (the “Services”).</p>
      </div>


      <div className="col-md-10 mt-4">
        <div className="content right-column bullet-points-right dataeng-col  ">
          <h2 class="data-h3">Information We Collect</h2>
          <p class="data-p">We collect information from you in two ways:</p>

          <ul>
            <li class> <span class="dataeng-li">Through the Site: </span>You can generally visit the Site without revealing any personal information. However, in certain sections, we may invite you to contact us, inquire about a job, or request information. In such situations, you may disclose your name, email address, phone number, and other relevant details. We may also track and store anonymous information about your browsing activity on the Site.</li>
            <li>  <span class="dataeng-li">Through the Services: </span>When our clients use our Services, they may provide us with information about you, including personal information such as your name, address, email address, phone number, and IP address.</li>
            <li>  <span class="dataeng-li">Children's Privacy:  </span>Our Site and Services are not directed towards children under 18. We do not knowingly collect personal information from children under 18.</li>
            <li>  <span class="dataeng-li">Privacy Policy Updates: </span>We may update this Privacy Policy from time to time. We will post any changes on our website and may also notify registered users via email.</li>
            <li>  <span class="dataeng-li"> Contact Us: </span>If you have any questions regarding this Privacy Policy, please contact us at contact@deinertech.com.</li>
          </ul>
        </div>
      </div>



      <Footer></Footer>












    </>
  )
}
export default Privacy;