import React, { useState } from 'react';
import './Google.css';


const ImageWithHoverContent = () => {
  const [clickedIndex, setClickedIndex] = useState(null);

  const blogs = [
    {
      id: 1,
      date: "April 27, 2024",
      author: "deinertech",
      title: "Java: Unleashing the Power of a Versatile Programming Giant",
      description: "Prepare to embark on a journey through the realm of Java - a programming language that stands…",
      image: './Blog.png',
      link: '/Blogwebd'
    },
    {
      id: 2,
      date: "May 15, 2024",
      author: "deinertech",
      title: "ERP Systems: Revolutionizing Business Operations",
      description: "Enterprise Resource Planning (ERP) systems are transforming the way businesses operate by.…",
      image: './erpblog.png',
      link: '/Erpblog'
    },
    {
      id: 3,
      date: "June 3, 2024",
      author: "deinertech",
      title: "Web Design Trends Shaping 2024",
      description: "Web design is continuously evolving, with new trends emerging that can significantly enhance user experience....",
      image: './Untitled design (3).png',
      link: '/Blogwebd'
    },
    {
      id: 4,
      date: "June 2, 2024",
      author: "deinertech",
      title: "Why Digital Marketing is Potent For Your Business ",
      description: "In today’s fast-paced business world, digital marketing has become a vital driver of business.....",
      image: './Digitalmblog.png',
      link: '/DigitalBlog'
    },
  ];

  const handleClick = (index) => {
    setClickedIndex(clickedIndex === index ? null : index); // Toggle the clicked state for the specific blog
  };

  return (
    <div className="information">
      <h3 className="Blog-h3">Blog</h3>
      <h1 className="info1">
        <span className="half1">Trending IT</span>
        <span className="half2">Solution</span> Article & Tips
      </h1>

      <div className="container blog-row justify-content-center display: flex ">
        {blogs.map((blog, index) => (
          <div key={blog.id} className="Blog">
            <img
              src={blog.image}
              alt={blog.title}
              className="hover-image "
            />
            <div
              className={`content-isClicked ${clickedIndex === index ? 'hidden' : ''}`}
              onClick={() => handleClick(index)}
            >
              <p className="content-title"><i className="bi bi-calendar-day-fill"> {blog.date} </i></p>
              <p className="content-title"><i className="bi bi-person-circle"> {blog.author} </i></p>
              <h6 className='blog-h4'>{blog.title}</h6>
            </div>
            <div
              className={`hover-content ${clickedIndex === index ? 'hovered' : 'hidden'}`}
              onClick={() => handleClick(index)}
            >
              <h5>{blog.title}</h5>
              <h6>{blog.description}</h6>
              <br />
              <a href={blog.link} className="hover-button">Read More</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageWithHoverContent;
