import Requstf from "../pages/Requst/Requst";
import React, { useState } from "react";
import About from "../pages/Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer/Footer";
import './Marketingc.css'
import { Helmet } from "react-helmet";

function MarketingC(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    return(
       <>
             <Helmet>
                <title>Marketing Cloud Deinertech Software</title>
                {/* <meta name="description" content="Unlock the power of Marketing Cloud with Deinertech Software. Streamline your business with multi-channel marketing automation, advanced analytics, and customer journey orchestration." />
                <meta name="keywords" content="Marketing Cloud, Cloud Migration, Multi-Channel Marketing Automation, Advanced Analytics, Customer Journey, Deinertech Software" />
                <meta name="author" content="Deinertech Software Pvt Ltd" />
                <meta property="og:title" content="Marketing Cloud - Deinertech Software" />
                <meta property="og:description" content="Unlock the power of Marketing Cloud with Deinertech Software, offering marketing automation, advanced analytics, and customer journey orchestration." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://yourwebsite.com/Marketingc" /> Add your actual URL */}
            </Helmet>
       <About></About>
       <div class="container-salesc">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Marketing Cloud</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Marketing Cloud</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
   
            <h2 class="conantctm-h2 ">Streamline Your Business with Cloud Migration & Support by Deinertech Software</h2>
           
          <h6 class="dev-p1 mt-0">In today’s digital age, harnessing the power of the cloud is essential for businesses looking to enhance <br/> flexibility, scalability, and efficiency. Deinertech Software offers comprehensive cloud migration and support <br/> services to help you seamlessly transition to the cloud and optimize your IT infrastructure.</h6>
           

             <h1 class="pos-h4">Marketing Cloud</h1>
         </div>

          
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Multi-Channel Marketing Automation</h4>
            <h6 class="elc-h6">Marketing Cloud offers robust multi-channel marketing automation capabilities, allowing businesses to reach customers across various channels such as
                 email, social media, mobile, web, and advertising. This comprehensive approach enables organizations to create targeted and
                  personalized campaigns that resonate with their audience, driving engagement and conversion.</h6>
                
                
           
           
             
         </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Advanced Analytics and Reporting</h4>
            <h6 class="elc-h6">Marketing Cloud provides advanced analytics and reporting capabilities that enable organizations to track 
                and measure the performance of their marketing campaigns in real-time. By monitoring key metrics such as open rates, 
                click-through rates, conversion rates, and ROI, businesses can gain valuable insights into campaign effectiveness, optimize
                 strategies, and drive continuous improvement.</h6> 

            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/Marketing-Cloud-768x768.webp'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Customer Journey Orchestration</h4>
            <h6 class="elc-h6">With Marketing Cloud's customer journey orchestration tools, businesses can design
                 and automate personalized customer journeys that span multiple touchpoints and channels.
                  By mapping out the customer journey and delivering relevant content and offers at each stage,
                   organizations can nurture leads, drive conversions, and foster long-term customer relationships.</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Integration and Scalability</h4>
            <h6 class="elc-h6">As a cloud-based platform, Marketing Cloud offers seamless 
                integration with other Salesforce products and third-party applications,
                 enabling businesses to leverage their existing data and systems for marketing purposes.
                  Additionally, Marketing Cloud's scalable architecture allows organizations to adapt and
                   grow their marketing initiatives as their business needs evolve, ensuring flexibility and future-proofing their investment.</h6> 
       


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-mar">
            <h4 class="elc-h3">Data-driven Segmentation and Personalization</h4>
            <h6 class="elc-h6">Marketing Cloud empowers businesses to leverage data-driven segmentation and personalization to tailor marketing messages and
                 offers to individual customer preferences and behaviors. By analyzing customer
                  data and insights, organizations can create targeted segments and deliver personalized
                   content that resonates with each recipient, enhancing engagement and driving results.</h6>

         </div>
        </div>
        </div>
        </div>
     <br/>

     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Deinertech Software for Cloud Migration & Support?</h2>
          <br/>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Expertise: Benefit from our team's extensive experience in cloud technologies, migration strategies, and best practices.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Scalability: Scale your cloud resources up or down based on demand, ensuring cost-effectiveness and flexibility for your business.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Reliability: Count on us for reliable cloud services with minimal downtime and maximum performance, backed by industry-leading SLAs.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">Innovation: Stay ahead of the curve with access to cutting-edge cloud technologies and solutions that drive business growth and innovation.</h5>
             </div>
          </div>
               
        </div>
      </div>
   </div>
  </div>
  <br/>
  <br/>

    

<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Unlock the Power of the Cloud with Deinertech Software</h3>
                <h6 class="box-h6">Experience seamless cloud migration and reliable support services that empower your business to thrive in the digital era. Contact us today to learn more about our cloud services and take the first step towards a more agile and efficient IT infrastructure.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
<Footer></Footer>
         
       </>
    )
}
export default MarketingC
;