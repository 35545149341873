import React, { useState } from "react";
import "./Ceo.css"; // Custom styles

const CEOCard = () => {
  const [visibleCards, setVisibleCards] = useState(Array(10).fill(false)); // Adjust to manage the number of cards

  const handleImageClick = (index) => {
    const updatedVisibility = [...visibleCards];
    updatedVisibility[index] = !updatedVisibility[index];
    setVisibleCards(updatedVisibility);
  };

  const ceos = [
    {
      name: "Ramanand Roy",
      title: "Director",
      info: "",
      image: "./Ramanand.jpeg",
    },
    {
        name: "Praveen Kumar",
      title: "Co-Founder & Managing Director",
      info: "Entrepreneur, Business Strategist.",
      image: "./prveen.jpg",
  
    },
    {

      name: "Seema Rai",
      title: "Vice President",
      info: "Messaging, Research & Content Marketing, General Manager.",
      image: "./seema.jpg",
     
    },
    {
     

      name: "Sobha Rai",
      title: "Human Resources",
      info: "Attracts, retains, and develops talent to build a strong workforce.",
       image: "./seema.jpg",
    },
    {
      name: "Sajjan Shingare",
      title: "Marketing & Strategy",
      info: "Marketing Maverick & Social Entrepreneur | Brewing Innovation",
      image: "./sajjan.jpg",
    },
    {
   


      name: "Sumit Shinde",
      title: "Associate Software Developer",
      info: "Developing innovative software solutions.",
      image: "./dummy.jpg",

    },
    {
      name: "Shubham Birajdar",
      title: "Web Developer",
       image: "./dummy.jpg",
    },
    {
      name: "Priyanka Bonde",
      title: "UI-UX designer",
     
      image: "/seema.jpg",
    },
    {
      name: "vaishnavi kshirsagar",
      title: "MERN Stack Developer",
     
      image: "./seema.jpg",
    },
    {
      name: "Akanksha Chaudhari",
      title: "MERN Stack Developer",
   
      image: "./seema.jpg",
    },
  ];

  return (
    <>
      <div className="team-intro mt-4">
        <h1>Globally Sourced, Locally Smart.</h1>
        <p>
          We’re a team of creative and strategic thinkers with international experience and deep cultural knowledge.
          We unlock powerful solutions for your unique challenges, anywhere in the world.
        </p>
      </div>

      <div className="container my-5">
        <div className="d-flex flex-column align-items-center">
          {/* Row 1: 1 Card */}
          <div className="row justify-content-center w-100 mb-5 mt-4">
            <div className="col-lg-4 d-flex justify-content-center">
              <div className="card shadow-lg text-center ceo-card animated-border">
                <div className="card-img-wrapper" onClick={() => handleImageClick(0)}>
                  <img src={ceos[0].image} alt="CEO" className="ceo-image" />
                </div>
                <div className="card-body">
                  <h5 className="ceo-name">{ceos[0].name}</h5>
                  <p className="ceo-title">{ceos[0].title}</p>
                  <p className="ceo-info">{ceos[0].info}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Row 2: 2 Cards */}
          <div className="row justify-content-center w-100 mb-5 mt-4">
            {ceos.slice(1, 3).map((ceo, index) => (
              <div key={index + 1} className="col-lg-4 col-md-6 mb-5 d-flex justify-content-center">
                <div className="card shadow-lg text-center ceo-card animated-border">
                  <div className="card-img-wrapper" onClick={() => handleImageClick(index + 1)}>
                    <img src={ceo.image} alt={`CEO ${index + 2}`} className="ceo-image" />
                  </div>
                  <div className="card-body">
                    <h5 className="ceo-name">{ceo.name}</h5>
                    <p className="ceo-title">{ceo.title}</p>
                    {visibleCards[index + 1] && <p className="ceo-info">{ceo.info}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Row 3: 3 Cards */}
          <div className="row justify-content-center w-100 mb-5 mt-4">
            {ceos.slice(3, 6).map((ceo, index) => (
              <div key={index + 3} className="col-lg-4 col-md-6 mb-5 d-flex justify-content-center">
                <div className="card shadow-lg text-center ceo-card animated-border">
                  <div className="card-img-wrapper" onClick={() => handleImageClick(index + 3)}>
                    <img src={ceo.image} alt={`CEO ${index + 4}`} className="ceo-image" />
                  </div>
                  <div className="card-body">
                    <h5 className="ceo-name">{ceo.name}</h5>
                    <p className="ceo-title">{ceo.title}</p>
                    {visibleCards[index + 3] && <p className="ceo-info">{ceo.info}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Row 4: 4 Cards */}
          <div className="row justify-content-center w-100 mb-5 mt-4">
            {ceos.slice(6, 10).map((ceo, index) => (
              <div key={index + 6} className="col-lg-3 col-md-6 mb-5 d-flex justify-content-center">
                <div className="card shadow-lg text-center ceo-card animated-border">
                  <div className="card-img-wrapper" onClick={() => handleImageClick(index + 6)}>
                    <img src={ceo.image} alt={`CEO ${index + 7}`} className="ceo-image" />
                  </div>
                  <div className="card-body">
                    <h5 className="ceo-name">{ceo.name}</h5>
                    <p className="ceo-title">{ceo.title}</p>
                    {visibleCards[index + 6] && <p className="ceo-info">{ceo.info}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </>
  );
};

export default CEOCard;
