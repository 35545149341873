import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './development.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
function Development() {
  return (
    <>
     <Helmet>
        <title>Development Deinertech Software </title>
        {/* <meta name="description" content="Elevate your business with Deinertech Software's comprehensive digital solutions including web development, mobile app development, and data engineering. Discover how our expertise can enhance your online presence and drive business growth." />
        <meta name="keywords" content="Development Services, Web Development, Mobile App Development, Data Engineering, AI, Machine Learning, Digital Solutions, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Development - DeineTech Software" />
        <meta property="og:description" content="Elevate your business with DeineTech Software's comprehensive digital solutions, including web development, mobile app development, and data engineering. Discover how our expertise can enhance your online presence and drive business growth." />
        <meta property="og:url" content="https://www.deinertechsoftware.com/development" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      
      <About></About>
      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Development</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home<i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Development</h6>
        </div>
      </div>

      <div class="Acoount-info">
  
        <h3 class="digital-h2">Elevate Your Business with Deinertech Software's Comprehensive Digital Solutions</h3>
      
        
        <p class="dev-p1 mt-2">Your website is the heart of your online presence, but it's only one piece of the digital puzzle. To truly thrive in today's landscape, businesses  need a robust suite of software and technology solutions. That's why Deinertech Software, your Pune-based IT partner, offers a full range of  services to power your digital transformation. In today's digital world, your website is often the first point of contact between your business and potential customers. It's your online storefront, your brand ambassador, and a crucial tool for generating leads and driving sales. A poorly designed or outdated website can deter visitors, while a well-crafted one builds trust, engages users, and drives conversions.
        </p>
      </div>



      <div class="container">
        <div class="row">
           <div class="col-md-6 order-2 order-md-2 custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/webdev">
              <h2 class="ourservices-h2">Web Development</h2>
              <p class="ourcomay-p">In the digital age, having a powerful and functional website is crucial for businesses to succeed online.
                Deinertech Software offers comprehensive web development services that combine cutting-edge
                technologies, creativity, and expertise to deliver exceptional digital solutions tailored to your business needs.</p></Link>
              <button className="btn-digital"><Link to="/webdev" className="Discover-more">Read more</Link></button>
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-1  custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/webdev">
            <img class="image-ourcompay " src={'./web developmentnew.png'} alt="Hospital Mang" />
            </Link>
         </div>
         </div>
      </div>
      <br />


      <div class="container">
        <div class="row">
          <div class="col-md-5 order-1 order-md-2 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc" to="/Mobiledev">
         <img class="image-ourcompay " src={'./mobile app developmentnew.png'} alt="Hospital Mang" />
         </Link>
           </div>
          <div class="col-md-6  order-2 order-md-1 custom-padding ">
            <div class="content">
            <Link  className="linksalesc" to="/Mobiledev">
              <h2 class="ourservices-h2">Mobile App Development</h2>
              <p class="ourcomay-p">In the mobile-first era, having a powerful and user-friendly mobile app is essential for businesses to engage
                customers, drive sales, and enhance brand loyalty. Deinertech Software specializes in creating innovative and
                feature-rich mobile apps that deliver exceptional user experiences and drive business growth.</p>  
                </Link>
              <button className="btn-digital"><Link to="/Mobiledev" className="Discover-more">Read more</Link></button>
            </div>
            </div>
            </div>
           </div> <br />


      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-2 custom-padding ">
            <div class="content">
            <Link  className="linksalesc " to="/Dataeng">
              <h2 class="ourservices-h2">Data Engineering & Ai/ML</h2>
              <p class="ourcomay-p">Deinertech, we understand the transformative power of data. Our Data Engineering,
                Artificial Intelligence (AI), and Machine Learning (ML) services are designed to unlock the full
                potential of your data, driving insights, innovation, and growth. Whether you’re looking to
                harness the power of AI to automate processes, leverage ML for predictive analytics, or build
                robust data pipelines, we have the expertise and technology to turn your data into a strategic asset.</p>
                </Link>
              <button className="btn-digital"><Link to="/Dataeng" className="Discover-more">Read more</Link></button>
            </div>
          </div>
         
          <div class="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
          <Link  className="linksalesc " to="/Dataeng">
            <img class="image-ourcompay " src={'./M.png'} alt="Hospital Mang" />
          </Link>
          </div>
         
        </div>
      </div>

      <Footer></Footer>




    </>
  )
}
export default Development;