
import React from "react";
import { Link } from "react-router-dom";
import './Javap.css';
import About from "../Navbar/About";
import BlogD from "../BlogD/BlogD";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";


function Javas() {
  function changeColor(button) {
    const buttons = document.querySelectorAll('.btn-custom');
    buttons.forEach(btn => btn.classList.remove('active'));


    button.classList.add('active');
  }

  return (
    <>
    <Helmet>
        <title>Blog at Deinertech Software</title>
        {/* <meta name="description" content="Explore the world of Java, a versatile programming language that has stood the test of time in the digital age." />
        <meta name="keywords" content="Java, programming, software development, versatile programming, Deinertech Software" />
        <meta name="author" content="Deinertech Software" /> */}
      </Helmet>

      <About></About>
      <div class="container-Blog">
        <div class="text-center mt-5">
          <h1 class="hosp-h1">Blog</h1>
          <p class="hosp-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></p>
          <h6 class="hosp-h">Blog</h6>
        </div>
      </div>
    

      <BlogD></BlogD>
  


      <Footer></Footer>


    </>



  )
}
export default Javas;