import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";
import './Pos.css';

function Pos() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
    return (
        <>
            <About></About>
            <Helmet>
                <title>POS Software Deinertech Software</title>
                {/* <meta name="description" content="Discover our comprehensive POS Software designed for small and medium-sized businesses. Enhance your retail operations with user-friendly features, detailed reports, and affordable solutions." />
                <meta name="keywords" content="POS Software, Retail Management System, Point of Sale, Inventory Management, Sales Reports, Customer Management, Small Business Software" />
                <meta name="author" content="Deinertech Software Pvt Ltd" />
                <meta property="og:title" content="POS Software | Deinertech Software" />
                <meta property="og:description" content="Discover our comprehensive POS Software designed for small and medium-sized businesses. Enhance your retail operations with user-friendly features, detailed reports, and affordable solutions." />
               <meta property="og:url" content="https://www.deinertech.com/pos-software" />
                <meta property="og:type" content="website" /> */}
            </Helmet>

            <div class="container-Possystyem">
                <div class="text-center mt-5">
                    <h1 class="hosp-h1">POS Software</h1>
                    <h6 class="hosp-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
                    <h6 class="hosp-h">POS Software</h6>
                </div>
            </div>

            <div class="container">
                <div class="container-info">
                    <div class="row">
                        <div class="col-md-5 d-flex justify-content-center align-items-center">
                            <img class="image-info " src={'/seo-default-img.png'} alt="Hospital Mang" />
                            {/* <img src="your-image.jpg" alt="Image" class="image"/> */}
                        </div>
                        <div class="col-md-7">
                            <div class="content">
                            <h1 class="account-heding"> <span class="account-h">Retail  </span> Management System </h1>
                            <h5 class="account-h4">Efficient Point of Sale Software for Small and Medium-Sized  Businesses</h5>
                            <p class="hos-p">Our Point of Sale Software is a comprehensive solution tailored for small  and
                                medium-sized businesses. It's designed to meet the needs of retail stores,
                                boutiques, restaurants, and various business types, incorporating essential
                                features to enhance performance and efficiency.</p>
                            <h5 class="account-h4">User-Friendly Interface</h5>
                            <p class="hos-p">Our Retail Management Software features a user-friendly interface, ensuring ease
                                of use for all users. We prioritize intuitive
                                software that enhances productivity without complications</p>
                          
                                </div>
                        </div>
                    </div>
                </div>
            </div>


            
       <div class="Acoount-info">
                <h3 class="pos-h3 mt-4">POS Software Module Key Features:</h3>
                <h3 class="pos-features">Features:</h3>
            </div>
            
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 bulet-point-event">
                        <div class="bullet-points-left bullet-points p-md-5">
                            <ul class="left-ul">
                                <li>Item and Stock Management</li>
                                <li>sales and Customer Management</li>
                                <li>Purchase and Supplier Management</li>
                                <li>Financial Accounting</li>
                                <li>Reports and Analysis</li>
                                <li>Barcode designing and Printing</li>
                                <li>Printing in both Dot Matrix<br/>and Laser Printers </li>
                                <li>Schemes And Promotions</li>
                                <li> Customer Loyalty Programs</li>
                                <li>Payments to supplier</li>
                                <li>Receive payments from customer</li>
                                <li>Stock Transfer</li>
                                <li>Send SMS and Email to <br/>customers/ suppliers</li>
                             
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="bullet-points p-md-5">
                            <ul class="left-ul">

                            <li>Dual Measuring Unit</li>
                                <li>Subsidy Management</li>
                                <li>Access Rights</li>
                                <li>Gift Card</li>
                                <li>Employee &amp; Supplier Management</li>
                                <li>Custom Fields</li>
                                <li>Weighing Scale Interface</li>
                                <li>BOM &amp; Making Cost</li>
                                <li>Online Shop</li>
                                <li>Secondary Measuring Unit</li>
                                <li>Customer Groups</li>
                                <li>Offers and Discounts</li>
                                <li>Search by Stock</li>
                                <li>Accounting Software Bridge</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

             
            <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"-10px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Streamline Your Sales Operations with Our Comprehensive POS System</h3>
                <h6 className="box-h6">Our POS System simplifies sales transactions, inventory management, and performance tracking. It streamlines communication between staff and customers while providing real-time analytics for better decision-making. Easily manage sales, stock levels, and reports to save time and boost productivity.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
            <Footer></Footer>




        </>
    )
}
export default Pos;