import React from 'react';

import './Header.css';

const Header = () => {
    return (
        <div className="header-container">
            <div className="contact-info">
                <div className="location">
                    <span className="location-name">Pune:</span>
                </div>
                <div className="location">
                    <i className="bi bi-telephone-inbound-fill header-icon">
                    <a href="tel:+918766833680" style={{textDecoration:"none", color:"whitesmoke"}}>     <span className="phone-number">+91 9209298516</span>
                  </a>  </i>
                    <i className="bi bi-telephone-inbound-fill header-icon">
                    <a href="tel:++918766833680" style={{textDecoration:"none", color:"whitesmoke"}}><span className="phone-number">+91 8766833680</span>
                    </a></i>
                    <a className="header-icon" href="mailto:contact@deinertech.com">
                        <i className="bi bi-envelope-at header-icon">
                            <span className="email">contact@deinertech.com</span>
                        </i>
                    </a>
                </div>
            </div>
            <div className="social-icons">
                <a href="https://www.instagram.com/deinertechsoftware" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-instagram icon"></i>
                </a>
                <a href="https://www.facebook.com/share/mDdMkDrWPC6era9G/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-facebook icon"></i>
                </a>
                <a href="https://www.linkedin.com/company/deinertech-software-private-limited" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-linkedin icon"></i>
                </a>
                <a href="https://youtube.com/@deinertechsoftware?si=1hfpHHzrs6OTSIM6" target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube icon youtub-icon"></i></a>
               
            </div>
        </div>
    );
};

export default Header;
