import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import './companyf.css';

function Companyform() {
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    emailAddress: '',
    phoneNumber: '',
    country: '',
    state: '',
    message: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare email parameters
    const emailParams = {
      to_email: 'contact@deinertech.com',
      from_name: formData.fullName,
      company_name: formData.companyName,
      from_email: formData.emailAddress,
      phone_number: formData.phoneNumber,
      country: formData.country,
      state: formData.state,
      message: formData.message,
    };

    // Send email using EmailJS
    emailjs.send('service_d7cut7g', 'template_midg6h6', emailParams, 'hvV3zPqE-5UmRmgoT')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setSuccess('Message sent successfully!');
        setError('');
        // Clear form data
        setFormData({
          fullName: '',
          companyName: '',
          emailAddress: '',
          phoneNumber: '',
          country: '',
          state: '',
          message: '',
        });
      })
      .catch((err) => {
        console.error('Email sending failed:', err);
        setError('Failed to send the message. Please try again.');
        setSuccess('');
      });
  };

  return (
    <Container className="mt-5">
      {/* Display success message at the top */}
      {success && <Alert variant="success">{success}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Row>
        <Col xs={12} md={7}>

          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="formBasicFullName">
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicCompanyName">
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />

            <Row>
              <Col>
                <Form.Group controlId="formBasicEmailAddress">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicPhoneNumber">
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />

            <Row>
              <Col>
                <Form.Group controlId="formBasicCountry">
                  <Form.Control
                    as="select"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    <option>Select country...</option>
                    <option>USA</option>
                    <option>Canada</option>
                    <option>UK</option>
                    <option>India</option>
                    {/* Add more countries as needed */}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicState">
                  <Form.Control
                    type="text"
                    placeholder="Your State"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />

            <Col md={10}>
              <Form.Group controlId="formSkills">
                <Form.Label>Your Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <br />
            <br />

            <Button  className='btn-comp-form' variant="primary" type="submit">
              Send Message
            </Button>
          </Form>
        </Col>
        <Col xs={12} md={5}>
          <Card className="company-card">
            <Card.Body>
              <Card.Title>Why We?</Card.Title>
              <br />

              <Card.Text>

                
              <div class=" record-c d-flex">
              <i class="bi bi-record-fill"></i><p class="">Most Trusted Brand</p>
                            </div>

                            <div class="record-c d-flex">
                            <i class="bi bi-record-fill"></i><p class="li-form">More than 350 satisfied users</p>
                            </div>

                            <div class=" record-c d-flex">
                            <i class="bi bi-record-fill"></i><p class="li-form">The Management team has more than 12 years of experience</p>
                            </div>

                            <div class=" record-c d-flex">
                            <i class="bi bi-record-fill"></i><p class="li-form">We offer a one-stop shop for all your digital growth needs.</p>
                            </div>

                            <div class=" record-c d-flex">
                            <i class="bi bi-record-fill"></i><p class="li-form">We provide a comprehensive suite of IT solutions and services</p>
                            </div>
                            <div class=" record-c d-flex">
                            <i class="bi bi-record-fill"></i><p class="li-form">24/7 Support: Get help anytime, anywhere for ultimate convenience.</p>
                            </div>

               
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Companyform;
