import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Requstf.css';

function Requstf({ onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '' // New state for the message input
    });

    const [submissionStatus, setSubmissionStatus] = useState(''); // State to track submission status

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Define the template parameters
        const templateParams = {
            from_name: formData.name,
            from_email: formData.email,
            phone_number: formData.phoneNumber,
           
        };

        // Send the email
        emailjs.send('service_ekwkbrx', 'template_1qb52j7', templateParams, 'JgnrqzzAXY0wuBPvD')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSubmissionStatus('success'); // Set submission status to success
            }, (error) => {
                console.log('FAILED...', error);
                setSubmissionStatus('failure'); // Set submission status to failure
            });

        // Clear form data
        setFormData({
            name: '',
            email: '',
            phoneNumber: '',
         
        });

        // Automatically close the modal after 2 seconds if successful
        if (submissionStatus === 'success') {
            setTimeout(() => {
                onClose();
            }, 2000);
        }
    };

    return (
        <div className="overlay">
            <div className="request-form">
                <div className="modal d-block bg-secondary bg-opacity-50" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-center mb-4">
                                    <img src={'./images.png'} alt="Illustration" className="img-fluid form-image" />
                                </div>
                                <h2 className="text-center mb-3">Let’s Connect!</h2>
                                <p className="text-center">We're here to help you with your tech needs. <br />
                                    Reach out to us for expert solutions and advice.</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Enter your name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email address"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder="Enter your phone number"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                  
                                    <div className="d-grid">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>

                                {/* Display submission status message */}
                                {submissionStatus === 'success' && (
                                    <div className="alert alert-success mt-3">
                                        <label className="form-label text-success">Success:</label>
                                        Your message has been sent successfully!
                                    </div>
                                )}
                                {submissionStatus === 'failure' && (
                                    <div className="alert alert-danger mt-3">
                                        <label className="form-label text-danger">Error:</label>
                                        Failed to send the message, please try again.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Requstf;
