import { React, useRef } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import ResponsiveForm from "../Form/Form";
import './intership.css';
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Intership() {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Internship Deinertech Software</title>
        <meta name="description" content="Join our Digital Marketing & Software Development Internships at Deinertech Software. Gain real-world experience, expert mentorship, and more." />
        <meta name="keywords" content="Internship, Digital Marketing Internship, Software Development Internship, Deinertech Software, Real-World Experience, Expert Mentorship" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Internship Deinertech Software"/>
        <meta property="og:description" content="Explore our internship opportunities in Digital Marketing and Software Development at Deinertech Software. Learn from experts and gain practical experience." />
        <meta property="og:image" content="https://deinertech.com/health-image.png" />
        <meta property="og:url" content="https://deinertech.com/internship" />
        <meta property="og:type" content="website" />
      </Helmet>
      <About />
      <div className="container-intership">
        <div className="text-center mt-5">
          <h1 className="helth-h1">Internship</h1>
          <h6 className="Helath-h"><Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link></h6>
          <h6 className="Helath-h">Internship</h6>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 left-content">
            <h4 className="Intership-h3">Internship Program</h4>
            <h1>Digital Marketing & Software Development Internships at Deinertech Software</h1>
            
            <p style={{textAlign:"left"}}> Are you a student eager to make your mark in the dynamic worlds of digital marketing and software development? Deinertech Software offers immersive in-office internships that will kickstart your journey in these exciting industries. Get ready to learn from the best, gain real-world experience, and build a future-proof skill set!</p>
            <h3>Why Choose Our Internship Programs?</h3>
            <ul>
              <li className="carrer-li">Real-World Experience: Work on live projects for top brands and see your work come to life.</li>
              <li className="carrer-li">Expert Mentorship: Receive personalized guidance from seasoned marketing and development professionals.</li>
              <li className="carrer-li">Master the Latest Tools & Technologies: Become proficient in cutting-edge marketing platforms and software development frameworks.</li>
              <li className="carrer-li">Expand Your Network: Forge valuable connections within a leading tech company.</li>
              <li className="carrer-li">Dynamic Work Environment: Collaborate with our passionate team and experience the thrill of innovation.</li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 right-content">
            <img src={'./h6-image-1.png'} className="img-fluid" alt="Placeholder Image" />
          </div>
        </div>
      </div>

      <div className="intership-h mt-2">
        <h2>What You'll Gain</h2>
      </div>
      <div className="container" style={{marginBottom:"-35px"}}>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="row no-gutters">
                <div className="col-md-3 d-flex">
                  <img src={'./emp.png'} className="card-imgs" alt="Small Image" />
                </div>
                <div className="col-md-9">
                  <div className="card-inter">
                    <h5 className="card-header">Software Development Interns:</h5>
                    <ul className="intership-list">
                      <li className="pro-li">Programming Languages (Java, Python, etc.)</li>
                      <li className="pro-li">Software Development Lifecycle</li>
                      <li className="pro-li">Version Control Systems (Git)</li>
                      <li className="pro-li">Agile Methodologies</li>
                      <li className="pro-li">Testing and Debugging</li>
                      <br />
                      <button className="inter-btn" onClick={scrollToForm}>Apply for Interns</button>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="row no-gutters">
                <div className="col-md-3 d-flex">
                  <img src={'./emp.png'} className="card-imgs" alt="Small Image" />
                </div>
                <div className="col-md-9">
                  <div className="card-inter">
                    <h5 className="card-header">Digital Marketing Interns:</h5>
                    <ul className="intership-list">
                      <li className="pro-li">SEO & Content Marketing</li>
                      <li className="pro-li">Social Media Strategy</li>
                      <li className="pro-li">Data Analytics</li>
                      <li className="pro-li">Email Marketing</li>
                      <li className="pro-li">Marketing Automation</li>
                      <br />
                      <button className="inter-btn" onClick={scrollToForm}>Apply for Interns</button>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-intership"  style={{marginBottom:"-35px"}}>
        <div className="box-heading">
          <img className="" src={'./shield.png'} />
          <h5 className="box-h">Simplified Points:</h5>
        </div>
        <ul className="intership-list">
          <li>Embrace Learning: Gain hands-on experience and develop your skills with expert guidance.</li>
          <li>Connect with Mentors: Receive personalized support from experienced professionals invested in your growth.</li>
          <li>Join a Winning Team: Collaborate with talented individuals and contribute to exciting projects.</li>
          <li>Build a Well-Rounded Skillset: Develop both creative and analytical problem-solving abilities.</li>
          <li>Discover a Dynamic Environment: Thrive in a workplace that fosters learning, collaboration, and a touch of fun.</li>
        </ul>
      </div>

      <div className="container" ref={formRef} >
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="accordion" id="accordionExample">
              {/* Your accordion items */}
            </div>
          </div>
        </div>
      </div>
    <ResponsiveForm ></ResponsiveForm>

      <div className="intership-h">
        <h1>Life At Deinertech Software</h1>
        <img className="deiner-img" src={'./d3.png'} />
      
      
      </div>
      <Footer/>
    </>
  );
}

export default Intership;
