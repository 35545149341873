import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";
import About from "../Navbar/About";
import Footer from "../Footer/Footer";
import './Webdevlop.css';

function Webdevelopment() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }

  return (
    <>
      <Helmet>
        <title>Web Development Services Deinertech</title>
        {/* <meta name="description" content="Unlock your digital potential with Deinertech Software's web development services. Offering customized solutions, CMS, responsive design, and more." />
        <meta name="keywords" content="web development, custom web solutions, CMS, responsive design, e-commerce solutions, scalable web development" />
        <meta property="og:title" content="Web Development Services | Deinertech" />
        <meta property="og:description" content="Unlock your digital potential with Deinertech Software's web development services. Offering customized solutions, CMS, responsive design, and more." />
        <meta property="og:url" content="https://www.deinertech.com/webdev" /> */}
      </Helmet>

      <About />
      <div className="container-webdevelopment">
        <div className="text-center mt-5">
          <h1 className="helth-h1">Web Development</h1>
          <h6 className="Helath-h">
            <Link to="/" className="dropdown-item">
              Home <i className="bi bi-chevron-double-right"></i>
            </Link>
          </h6>
          <h6 className="Helath-h">Web Development</h6>
        </div>
      </div>

      <div className="Acoount-info">
        <h2 className="conantctm-h2">Unlock Your Digital Potential With Deinertech Software’s Web Development Services</h2>
        <br />
        <h5 className="dev-p1">In the digital age, having a powerful and functional website is crucial for businesses to succeed online. Deinertech Software offers comprehensive web development services that combine cutting-edge technologies, creativity, and expertise to deliver exceptional digital solutions tailored to your business needs.</h5>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="elc mb-4">
              <div className="box-graphical">
                <h4 className="elc-h3">E-commerce Websites </h4>
                <h6 className="elc-h6">Deinertech Software is a premier web design company in Pune specializing in dynamic e-commerce websites. Our mobile-friendly solutions feature customizable galleries, secure payment gateways, and intuitive shopping carts, delivering an exceptional online shopping experience that drives sales and attracts customers.</h6>
              </div>
            </div>

            <div className="elc">
              <div className="box-graphical">
                <h4 className="elc-h3">Dynamic Websites</h4>
                <h6 className="elc-h6">Deinertech Software creates dynamic websites that provide real-time content updates and interactive features. Our responsive, SEO-optimized designs enhance user engagement and drive conversions, offering businesses the flexibility to grow and adapt in today’s fast-paced digital landscape.
                </h6>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-center">
            <img className="Helth-img mb-4" src={'/deinertechdesign.png'} alt="Web Development Image" />
          </div>

          <div className="col-md-4">
            <div className="elc mb-4">
              <div className="box-graphical right-border">
                <h4 className="elc-h3">Single-page Websites</h4>
                <h6 className="elc-h6">Deinertech Software creates dynamic single-page websites that provide a seamless user experience. Our responsive designs feature fast loading times and engaging layouts, optimized for SEO to boost visibility and conversions, helping your business connect effectively with its audience</h6>
              </div>
            </div>

            <div className="elc">
              <div className="box-graphical right-border">
                <h4 className="elc-h3">Landing  Pages</h4>
                <h6 className="elc-h6">Deinertech Software specializes in crafting high-converting landing pages that engage visitors. Our SEO-optimized designs include persuasive content and clear calls-to-action, driving user interaction and boosting conversions. Let us help you elevate your marketing campaigns and achieve your business goals</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-md-4">
          <div className="elc">
            <div className="box-graphical1">
              <h4 className="elc-h3">Portfolio websites</h4>
              <h6 className="elc-h6">Deinertech Software creates exceptional portfolio websites that effectively showcase your talents and projects. Our tailored designs are fully responsive and SEO-optimized, providing an engaging user experience. Stand out from the competition, attract potential clients, and leave a lasting impression with a stunning portfolio that reflects your unique style and expertise!</h6>
            </div>
          </div>
        </div>
      </div>
 
         {/* Digital Marketing Section */}
         <div className="container">
        <div className="row web-dev">
          {/* For small screens: Image first, content after */}
          <div className="col-md-6 order-2 order-md-2  custom-padding">
            <div className="content">
           
                <h2 className="ourservices-h2">What We Do</h2>
                <p className="ourcomay-p">We are passionate about driving measurable results through strategic and data-driven digital marketing solutions. With a team of experienced marketers, we help businesses reach their target audience, increase brand visibility, and maximize their online ROI. Our mission is to propel your business forward in the digital landscape and achieve sustainable growth.</p>
    
      </div>
    </div>
          
         
          <div className="col-md-5 order-1 order-md-1 custom-padding d-flex justify-content-end">
           
              <img className="image-ourcompay img-fluid" src={'./what we do.png'} alt="Digital Marketing" />
           
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1 custom-padding">
            <div className="content">
              
                <h2 className="ourservices-h2">Built using the latest web technologies</h2>
                <p className="ourcomay-p">  Deinertech Software, our expert team develops robust and reliable web development frameworks tailored to your needs. We offer both pre-built and custom frameworks designed for speed, stability, and flexibility, ensuring you get the best return on your investment.
              We have successfully crafted digital solutions for businesses of all sizes, from startups to large enterprises, across various sectors, including financial services, arts, and culture.
              Have an idea for a digital product or service? Let us help you turn your vision into reality. With our comprehensive expertise, we create technically feasible websites that align with your business goals.</p>
              <div className="row">
  <div className="col-12 col-md-6">
    <ul className="list-unstyled">
      <li><i className="bi bi-check-lg"></i> Innovative approach</li>
      <li><i className="bi bi-check-lg"></i> Optimal performance</li>
      <li><i className="bi bi-check-lg"></i> Responsive design</li>
      <li><i className="bi bi-check-lg"></i> Easy navigation</li>
      <li><i className="bi bi-check-lg"></i> Customizable content</li>
    </ul>
  </div>
  <div className="col-12 col-md-6">
    <ul className="list-unstyled">
      <li><i className="bi bi-check-lg"></i> Media support</li>
      <li><i className="bi bi-check-lg"></i> Enhanced security</li>
      <li><i className="bi bi-check-lg"></i> Continuous Optimization</li>
      <li><i className="bi bi-check-lg"></i> User-friendly</li>
      <li><i className="bi bi-check-lg"></i> Fast-loading</li>
      <li><i className="bi bi-check-lg"></i> Increased revenue</li>
    </ul>
  </div>
</div>

        
       
       </div>
     </div>
          <div className="col-md-5 order-1  order-md-2 custom-padding d-flex justify-content-end">
          <img className="image-ourcompay img-fluid" src={'./technology.png'} alt="Development" />
        </div>
        </div>
      </div>
     


 
   




      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="Choose3">
              <h2>Why Choose Deinertech Software for Web Development?</h2>
              <br />
              <div className="row mb-4 align-items-center">
                <div className="col-md-12 col-12 text-center d-flex built">
                  <i className="bi bi-1-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Technical Expertise: Our team comprises experienced web developers proficient in a wide range of technologies, including HTML, CSS, JavaScript, PHP, and more.</h5>
                </div>
                <div className="col-md-11 col-12 d-flex built">
                  <i className="bi bi-2-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Innovative Solutions: We stay updated with the latest web development trends and technologies to deliver innovative solutions that set your website apart.</h5>
                </div>
                <div className="col-md-11 col-12 d-flex built">
                  <i className="bi bi-3-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Security and Compliance: We prioritize security measures and adhere to industry best practices and standards to ensure that your website is secure, reliable, and compliant.</h5>
                </div>
                <div className="col-md-11 col-12 d-flex built">
                  <i className="bi bi-4-circle-fill number-icon"></i>
                  <h5 className="h5-hel">Collaborative Approach: We believe in collaboration and transparency throughout the development process, keeping you informed and involved at every stage.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <br/>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded">
              <div className="box-contant">
                <h3 className="box-h3">Take Your Online Presence to the Next Level with Deinertech Software</h3>
                <h6 className="box-h6">Ready to launch a powerful and user-friendly website that drives results? Partner with Deinertech Software for professional web development services that propel your digital presence forward. Contact us today to discuss your web development needs and embark on a journey towards digital success.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

      <Footer />
    </>
  );
}

export default Webdevelopment;
