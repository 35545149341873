import Requstf from "../Requst/Requst";
import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import './LeadG.css'




function LeadGen() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (
    <>
      <Helmet>
        <title>Lead Generation Deinertech Software</title>
        {/* <meta name="description" content="Generate quality leads and drive growth with Deinertech Software's targeted lead generation strategies. Our expertise ensures sustained business success." />
        <meta name="keywords" content="Lead Generation, Quality Leads, Business Growth, Digital Marketing, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Lead Generation - Deinertech Software" />
        <meta property="og:description" content="Generate quality leads and drive growth with Deinertech Software's targeted lead generation strategies. Our expertise ensures sustained business success." />
        <meta property="og:url" content="https://www.deinertech.com/LeadG" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>
      <div class="container-Leadg">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Lead Generation</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Lead Generation</h6>
        </div>
      </div>



      <div class="Acoount-info">
        <h2 class="conantctm-h2 mb-3">Generate Quality Leads And Drive Growth With  <br /> Deinertech Software</h2>
     
        <h6 class="dev-p1">In today’s competitive business landscape, generating quality leads is essential for sustained growth and  
          success. At Deinertech Software, we specialize in creating targeted lead generation strategies that help  businesses connect with potential customers, nurture relationships, and drive conversions.</h6>
    

        <h2 class="pos-h4">Our Lead Generation Approach</h2>
      </div>

{/* 
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Strategic Planning</h4>
                <h6 class="elc-h6">We begin by understanding your target audience, buyer personas, and unique value propositions. Our team then develops a strategic lead generation plan that aligns with your business goals and maximizes ROI.</h6>





              </div>
            </div>

            <div class="elc">
              <div class="box-graphical">
                <h4 class="elc-h3">Content Creation</h4>
                <h6 class="elc-h6">Compelling and relevant content is at the
                  heart of effective lead generation. We create
                  high-quality content assets such as blogs,
                  whitepapers, eBooks, webinars, and case
                  studies that educate, inform, and attract potential leads</h6>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/Lead.png'} alt="Health Image" />


          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Multi-Channel Approach</h4>
                <h6 class="elc-h6"> We leverage a mix of digital channels such as
                  search engine marketing (SEM), social media
                  marketing, email marketing, and content  marketing to reach and engage your target audience at every stage of the buyer's journey.</h6>


              </div>
            </div>




            <div class="elc">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Lead Capture and Nurturing</h4>
                <h6 class="elc-h6">pages, and call-to-action (CTA) buttons to
                  capture leads and gather valuable
                  information. Our lead nurturing strategies
                  include automated email workflows,
                  personalized communications, and targeted
                  follow-ups to move leads through the sales funnel.</h6>


              </div>
            </div>
          </div>
        </div>
      </div> */}

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical">
                    <h4 class="elc-h3">Customized Lead Generation Framework</h4>
                    <h6 class="elc-h6">We start with a deep dive into your market to identify your ideal customers and their needs. Our team designs a bespoke lead generation framework that aligns with your unique business objectives and optimizes outreach.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical">
                    <h4 class="elc-h3">Innovative Content Strategies</h4>
                    <h6 class="elc-h6">Effective lead generation relies on captivating content. We produce a variety of high-value content formats, such as insightful infographics, engaging videos, and persuasive case studies, to attract and convert your target audience.</h6>
                </div>
            </div>
        </div>

        <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/Lead.png'} alt="Lead Generation" />
        </div>

        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Diverse Multi-Channel Engagement</h4>
                    <h6 class="elc-h6">We employ a robust mix of channels, including social media, email, and PPC advertising, to effectively engage your audience at multiple touchpoints, ensuring no opportunity is missed in the lead generation process.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Effective Lead Capture Techniques</h4>
                    <h6 class="elc-h6">We utilize targeted landing pages and compelling calls-to-action (CTAs) to optimize lead capture. Our nurturing strategies focus on personalized follow-ups and strategic content delivery to build relationships and drive conversions.</h6>
                </div>
            </div>
        </div>
    </div>
</div>




   
  

      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose-led">
              <h2 class="">Why Choose Deinertech Software for Lead Generation?</h2>
              <br />

              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">Expertise: Our team has extensive experience in designing and executing successful lead generation campaigns across various industries.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Customization: We tailor our lead generation strategies to meet your specific business needs, target audience, and budget, ensuring optimal results.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Technology Integration: We leverage cutting-edge tools and technologies to streamline lead generation processes, track performance, and drive continuous improvement.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built mb-4">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Transparency: We provide transparent reporting and analytics, giving you visibility into campaign performance and actionable insights for decision-making.</h5>
                </div>
              </div>
                 </div>
          </div>
        </div>
      </div>
   





<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-10 col-sm-12 mx-auto d-block">
        <div class="box-start2">
          <div class="box-contant">
            <h3 class="box-h3">Start Generating Quality Leads Today</h3>
            <h6 class="box-h6">Ready to take your lead generation efforts to the next level? Partner with Deinertech Software and let us help you generate quality leads,
            nurture relationships, and drive growth for your business. Contact us today to discuss your lead generation goals and get started on the path to success.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer></Footer>




















    </>
  )
}
export default LeadGen;