import React, { useState } from "react";
import { Helmet } from "react-helmet";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './hrms.css';
import Footer from "../Footer/Footer";
import Requstf from "../Requst/Requst";

function Hrms() {

  
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    const [activeCollapse, setActiveCollapse] = useState(null);

    const handleCollapseToggle = (id) => {
        setActiveCollapse(activeCollapse === id ? null : id);
    };

    return (
        <>
            <Helmet>
                <title>HR Software Deintech</title>
                {/* <meta name="description" content="Optimize your HR processes with our comprehensive Human Resource Management Software. Streamline HR operations and ensure compliance with global standards." />
                <meta name="keywords" content="HR software, Human Resource Management, Employee Management, HR solutions" />
                <meta name="author" content="Deintech Software" />
                <meta property="og:title" content="HR Software - Deintech" />
                <meta property="og:description" content="Optimize your HR processes with our comprehensive Human Resource Management Software. Streamline HR operations and ensure compliance with global standards." />
                <meta property="og:url" content="https://www.deinertech.com/hr-software" /> */}
            </Helmet>

            <About />

            <div className="container-hrms">
                <div className="text-center mt-5">
                    <h1 className="hosp-h1">HR Software</h1>
                    <h6 className="hosp-h">
                        <Link to="/" className="dropdown-item">Home  <i className="bi bi-chevron-double-right"></i></Link>
                    </h6>
                    <h6 className="hosp-h">HR Software</h6>
                </div>
            </div>
            <div className="container">
                <div className="container-account">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <img className="image-account1" src={'/human-resource png.png'} alt="Hr Mang" />
                        </div>
                        <div className="col-md-6">
                            <div className="content">
                                <h3 className="account-heding">Online  <span className="account-h">Human</span> Resource Management Software</h3>
                                <h5 className="account-h4">Optimize Your HR Processes with Our Comprehensive Software</h5>
                                <p className="hos-p">Our Human Resource Management System is designed to enhance your HR 
                                    department and streamline processes to align with global standards. From
                                    compliance to assembling a robust HR team, our system offers a wide range of features tailored to your business needs.</p>
                                <h5 className="account-h4">Beyond Web Development: Complete Business Support</h5>
                                <p className="hos-p">We offer more than just web development and design services. Our Human Resource Management Software reflects our team's expertise with its intuitive <br /> interface,
                                    fast speed, and essential HR features. It's user-friendly and requires minimal training, suitable for businesses of all sizes and types.</p>
                                <h5 className="account-h4">Versatile HR Management System</h5>
                                <p className="hos-p">Our HR Management Software is versatile, catering to businesses of all sizes and industries.
                                    It covers essential HR operations such as employee shift  management, information registers, bonuses, and benefits,
                                    ensuring efficiency  and compliance for every business</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="hrms-info">
                            <h3 className="hrms-h3">Human Resource Management Software</h3>
                            <h4 className="hrms-h3">Employee Data Management</h4>
                            <ul className="custom-list">
                                <li>Employee general information, photo, note attachment files, medical information, tax information, monthly timesheet, payroll reports <br />(based on the tax information and time sheet), and medical insurance and emergency contacts</li>
                                <li>Employee benefits form helps you record all medical and detail insurance programs that your company have for your employees.</li>
                                <li>Create HR payroll report, phone list, employee list, department list reports, and you can add your own report later based on your need.</li>
                                <li>Employee vacation and sick leave management</li>
                                <li>Employee job history information management</li>
                                <li>Employee certification and awards management</li>
                                <li>Employee performance evaluations information management</li>
                                <li>Employee equipment tracking</li>
                                <li>Employee resume and training records management</li>
                                <li>You can output your employee data and payroll data to Microsoft® Excel® file and PDF file as well.</li>
                                <li>The database records all employee information and you can review it by name list, phone list, or department lists.</li>
                                <li>Create Employee Performance Review Forms and employee EEO Forms and keep these form records at document management system.</li>
                                <li>Benefits information management</li>
                                <li>Grievance history information (e.g.complaints, dispute etc.) management</li>
                                <li>Generate organization chart by using MS Visio and drag to employees management form</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
          
            <div className="collapse-div mb-4">
                <div className="container">
                    <hr className="my-hr" />
                </div>
                <p className="collapse-p">
                    <a
                        className={`btn btn-white ${activeCollapse === 'collapseExample' ? 'active' : ''}`}
                        role="button"
                        onClick={() => handleCollapseToggle('collapseExample')}
                    >
                        Job Opening
                    </a>
                    <a
                        className={`btn btn-white ${activeCollapse === 'collapseExample1' ? 'active' : ''}`}
                        role="button"
                        onClick={() => handleCollapseToggle('collapseExample1')}
                    >
                        Applicants
                    </a>
                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample2' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample2')}
                    >
                        Time & Attendance
                    </button>
                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample3' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample3')}
                    >
                        Employee Training Data
                    </button>
                    <br />
                    <br />
                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample4' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample4')}
                    >
                        Learning & Professional Development
                    </button>

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample5' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample5')}
                    >
                        Employee Benefit Information
                    </button>

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample6' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample6')}
                    >
                        Company Information
                    </button>

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample7' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample7')}
                    >
                        HR Documents
                    </button>
                    <br />
                    <br />

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample8' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample8')}
                    >
                        Project Information Tracking
                    </button>

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample9' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample9')}
                    >
                        Employee Termination Process
                    </button>

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample10' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample10')}
                    >
                        Employee Status Management
                    </button>

                    <button
                        className={`btn btn-white ${activeCollapse === 'collapseExample11' ? 'active' : ''}`}
                        type="button"
                        onClick={() => handleCollapseToggle('collapseExample11')}
                    >
                        Miscellaneous HR Information
                    </button>
                </p>

            
                
        <div className={`collapse ${activeCollapse === 'collapseExample' ? 'show' : ''}`} id="collapseExample">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>We offer a comprehensive HR software solution that covers all aspects of HR management.</li>
                            <li>Our system supports job posting, applicant tracking, and detailed job opening management.</li>
                            <li>Manage job descriptions, requirements, and application statuses easily.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        
        <div className={`collapse ${activeCollapse === 'collapseExample1' ? 'show' : ''}`} id="collapseExample1">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Track and manage applicant information efficiently.</li>
               <li>Review resumes, schedule interviews, and communicate with candidates through the system.</li>
              <li>Keep records of all applicant interactions and statuses.</li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample2' ? 'show' : ''}`} id="collapseExample2">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Monitor employee time and attendance with our integrated system.</li>
                 <li>Track working hours, breaks, and absences.</li>
                <li>Generate reports and ensure compliance with company policies.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        <div className={`collapse ${activeCollapse === 'collapseExample3' ? 'show' : ''}`} id="collapseExample3">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Manage employee training programs and records.</li>
                 <li>Track training completion and certifications.</li>
                 <li>Ensure employees meet required skill levels and training goals.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>


        <div className={`collapse ${activeCollapse === 'collapseExample4' ? 'show' : ''}`} id="collapseExample4">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Track and support employee learning and professional development.</li>
                <li>Manage learning resources and development plans.</li>
                <li>Encourage ongoing education and skill enhancement.</li>

               </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample5' ? 'show' : ''}`} id="collapseExample5">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Manage and track employee benefits and compensation.</li>
                            <li>Ensure accurate benefit allocation and compliance.</li>
                            <li>Communicate benefit options and updates to employees.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`collapse ${activeCollapse === 'collapseExample6' ? 'show' : ''}`} id="collapseExample6">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Maintain up-to-date company information.</li>
                <li>Track organizational structure, policies, and contact details.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`collapse ${activeCollapse === 'collapseExample7' ? 'show' : ''}`} id="collapseExample7">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Store and manage important HR documents and forms.</li>
                <li>Ensure secure access and compliance with document retention policies.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`collapse ${activeCollapse === 'collapseExample8' ? 'show' : ''}`} id="collapseExample8">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Track and manage project-related HR information.</li>
                <li>Monitor project assignments, progress, and resource allocation.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample9' ? 'show' : ''}`} id="collapseExample9">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Manage employee termination and offboarding processes.</li>
                <li>Ensure proper handling of termination paperwork and exit procedures.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`collapse ${activeCollapse === 'collapseExample10' ? 'show' : ''}`} id="collapseExample10">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Track and manage employee status changes.</li>
                <li>Update records for promotions, transfers, and other status changes.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`collapse ${activeCollapse === 'collapseExample11' ? 'show' : ''}`} id="collapseExample11">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                <li>Manage additional HR information and miscellaneous records.</li>
                <li>Customize data fields and records to meet specific needs.</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
           </div>

           <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"15px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Streamline Your HR Operations with Our Comprehensive Management System</h3>
                <h6 className="box-h6">Our HR Management System simplifies employee records, payroll, and performance tracking. 
                It streamlines communication between HR and staff while providing real-time analytics for better decision-making. 
              Easily manage leave, attendance, and reports to save time and boost productivity.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
            <Footer />
        </>
    );
}

export default Hrms;
