import React from "react";
import './Slideshows.css';

function CardsSlider() {
  return (
    <>

      <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">

          <div class="carousel-item active">
            <div class="container">
            <div className="row  d-flex justify-content-center">

                <div class="col-md-12">
                  <h4 class="slideshow-h">Clients Who Love Us</h4>
                  <h3 class="slideshow-h3"> <span class="slides-span">Deinertech Software  </span> – Our Testimonials</h3>
                  <p class="slides-p">Deinertech Software, our clients are more than just customers – They’re partners in success. We’re<br />
                    passionate about delivering exceptional results, and hearing positive feedback from satisfied clients<br />
                    fuels our dedication. See what some of our happy clients have to say about their experiences with us:</p>
                </div>
                <div class="col-md-5 mb-3 mb-md-0">
                  <div class="card h-100">
                    <div class="card-sildess">
                      <h4 class="slides-h5">
                        Akshay Deo </h4>

                      <h6 class="co-founder">Co- Founder || Ambika Properties</h6>
                      <p class="card-slideshowp">We started working with Deinertech Software six months ago to revamp our website and online
                        marketing strategy. We were struggling to stay afloat during the pandemic downturn, but Praveen and

                        Aarin from Deinertech were amazing! They completely redesigned our website, making it more user-
                        friendly and mobile-optimized. They also implemented a targeted SEO campaign that landed us on the

                        first page of Google search results for our key services. Since then, we've seen a 25% increase in website
                        traffic and a 10% rise in conversions. We're finally getting back on track, and we owe a huge debt of
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="card h-100" >
                    <div class="card-sildess ">
                      <h4 class="slides-h5">
                        Kumar Saurabh</h4>

                      <h6 class="co-founder">Founder || Currymate Spices</h6>
                      <p class="card-slideshowp">I recently engaged with Deinertech Software for their web development services, and overall, I was quite
                        satisfied with the experience. The team was responsive and attentive to my requirements throughout
                        the project. They demonstrated strong technical expertise and delivered a website that met my
                        expectations in terms of functionality and design. However, there were a few minor delays in the
                        delivery timeline, which could be improved. Nonetheless, I would recommend Deinertech Software for
                        their reliable and quality service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item" >
            <div class="container">
              <div class="row justify-content-center">

                <div class="col-md-12">
                  <h4 class="slideshow-h">Clients Who Love Us</h4>
                  <h3 class="slideshow-h3"> <span class="slides-span">Deinertech Software  </span> – Our Testimonials</h3>
                  <p class="slides-p">Deinertech Software, our clients are more than just customers – They’re partners in success. We’re<br />
                    passionate about delivering exceptional results, and hearing positive feedback from satisfied clients<br />
                    fuels our dedication. See what some of our happy clients have to say about their experiences with us:</p>
                </div>


                <div class="col-md-5 mb-3 mb-md-0">
                  <div class="card h-100">
                    <div class="card-sildess">
                      <h4 class="slides-h5 ">
                        Usha Yadav</h4>
                      <h6 class="co-founder">Co- Founder || Usun Solar Energy Private Limited</h6>
                      <p class="card-slideshowp">
                        It is truly amazing to work with Deinertech Software team
                        They work very efficiently and complete all deliverables  on time...The best part about them is their in-
                        depth knowledge about their  subject, they are highly professional.
                        Their team consistently goes above and beyond to ensure our satisfaction. Their innovative solutions and attention to detail have greatly
                         contributed to the success of our projects. We look forward to continuing our partnership with them and highly recommend their services to anyone in need of reliable and expert software development."
                      </p>

                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="card h-100" >
                    <div class="card-sildess">
                      <h4 class="slides-h5">
                        Deepak Kumar Singh
                      </h4>
                      <h6 class="co-founder">Director || JP Electrical & Power System</h6>
                      <p class="card-slideshowp">
                        I recently hired Deinertech for website design and social media marketing services in Pune, and I am
                        extremely satisfied with their work. Their team is very professional and helped me increase brand awareness and engagement on social media platforms.
                        The website they designed is not only visually appealing but also user-friendly and responsive, which has significantly improved our online presence. 
                        Their social media strategies have been highly effective, resulting in increased traffic and better customer interaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <h4 class="slideshow-h">Clients Who Love Us</h4>
                  <h3 class="slideshow-h3"> <span class="slides-span">Deinertech Software  </span> – Our Testimonials</h3>
                  <p class="slides-p">Deinertech Software, our clients are more than just customers – They’re partners in success. We’re<br />
                    passionate about delivering exceptional results, and hearing positive feedback from satisfied clients<br />
                    fuels our dedication. See what some of our happy clients have to say about their experiences with us:</p>
                </div>
                <div class="col-md-5 mb-3 mb-md-0">
                  <div class="card h-100 ">
                    <div class="card-sildess">
                      <h4 class="slides-h5">

                        Kumar Saurabh
                      </h4>
                      <h6 class="co-founder"> Founder || Currymate Spices</h6>
                      <p class="card-slideshowp">
                        I recently engaged with Deinertech Software for their web development services, and overall, I was quite
                        satisfied with the experience. The team was responsive and attentive to my requirements throughout
                        the project. They demonstrated strong technical expertise and delivered a website that met my
                        expectations in terms of functionality and design. However, there were a few minor delays in the
                        delivery timeline, which could be improved. Nonetheless, I would recommend Deinertech Software for

                        their reliable and quality service.

                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="card h-100" >
                    <div class="card-sildess gajana">
                      <h4 class="slides-h5">

                        Mukesh Yadav

                      </h4>
                      <h6 class="co-founder">Founder || Gajana Travel</h6>
                      <p class="card-slideshowp">
                        I have been a client of Deinertech Software since 2023. They have developed my travel website and
                        helped promote my business across five locations. I'm pleased with the response we've received. I'd like
                        to express my gratitude to the entire team at Deinertech Software. Highly recommended
                        Their team’s dedication and expertise have played a crucial role in expanding our reach and improving
                         our customer engagement. The website they created is not only visually
                         stunning but also highly functional, providing our customers with a seamless booking experience!

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <h4 class="slideshow-h">Clients Who Love Us</h4>
                  <h3 class="slideshow-h3"> <span class="slides-span">Deinertech Software  </span> – Our Testimonials</h3>
                  <p class="slides-p">Deinertech Software, our clients are more than just customers – They’re partners in success. We’re<br />
                    passionate about delivering exceptional results, and hearing positive feedback from satisfied clients<br />
                    fuels our dedication. See what some of our happy clients have to say about their experiences with us:</p>


                </div>
              

                  <div class="col-md-5 mb-3 mb-md-0">
                  <div class="card h-100">
                    <div class="card-sildess">
                      <h4 class="slides-h5 gajana">Mukesh Yadav
                        </h4>
                      <h6 class="co-founder">Founder || Gajana Travel</h6>
                      <p class="card-slideshowp">
                      I have been a client of Deinertech Software since 2023. They have developed my travel website and
                        helped promote my business across five locations. I'm pleased with the response we've received. I'd like
                        to express my gratitude to the entire team at Deinertech Software. Highly recommended 
                        Their team’s dedication and expertise have played a crucial role in 
                        expanding our reach and improving our customer engagement. The website 
                        they created is not only visually stunning but also highly functional,
                         providing our customers with a seamless booking experience !
                   
                      </p>
                    </div>
                    </div>
                  </div>
              
                <div class="col-md-5">
                  <div class="card h-100">
                    <div class="card-sildess">
                      <h4 class="slides-h5">
                        Inderpreet Singh Gill </h4>
                      <h6 class="co-founder">Founder || The IPS Productions</h6>
                      <p class="card-slideshowp">
                        I appreciate the in-depth analysis of our website's performance and the thorough review of our social
                        media efforts. The breakdown of engagement metrics, audience growth, and content performance
                        allows us to see the tangible results. These insights are valuable in shaping our content strategy and
                        targeting the right audience. Keep up the good work.
                        Your team’s attention to detail and proactive approach have made a significant difference in 
                        our digital strategy. We look forward to continuing our collaboration.!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
          <span class="" aria-hidden="true"></span>
          <span class="visually-hidden"></span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
          <span class="" aria-hidden="true"></span>
          <span class="visually-hidden"></span>
        </button>
      </div>


      <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js" integrity="sha384-oBqDVmMz4fnFO9kDk6r1pQOSp5dFz5lK2Q19WvJpXfP09UO08ePUj7I7xJ7gH0K5" crossorigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.min.js" integrity="sha384-0s2ff0z6T6HgkT6EsmJPRkRQ0rE9kQWjlP8VkgYYGxM1rD3A4T2v2jxHD3skv2jF" crossorigin="anonymous"></script>







    </>
  )
}
export default CardsSlider;