import React, { useState } from "react";
import About from "./Navbar/About";
import Footer from "./Footer/Footer";
import './helathcare.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Requstf from "./Requst/Requst";

function Banking() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      <Helmet>
        <title>Banking and Finance  Deinertech</title>
        {/* <meta name="description" content="Explore comprehensive banking and finance solutions by Deinertech Software Pvt Ltd, designed to empower financial institutions, improve operational efficiency, and enhance customer experience." />
        <meta name="keywords" content="Banking, Finance, Core Banking, Risk Management, Digital Banking, Analytics, Finance Solutions" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Banking and Finance | Deinertech" />
        <meta property="og:description" content="Explore comprehensive banking and finance solutions by Deinertech Software Pvt Ltd, designed to empower financial institutions, improve operational efficiency, and enhance customer experience." />
        <meta property="og:url" content="https://www.deinertech.com/banking-and-finance" /> 
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Deinertech" /> */}
      </Helmet>
      <About></About>



      <div class="container-banking">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Banking and Finance</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" style={{color:'black'}} >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Banking and Finance</h6>
        </div>
      </div>

        <div class="welcome">
        <div class="text-center Wel-come">
          <h2 className="heading-h2">Welcome To <span class="span">Deinertech Banking and Finance Solutions</span></h2>
        </div>
        <div class="hel-th">
          <h5 class="helath-p mt-0">Deinertech, we understand the complexities of the banking and finance industry. That's why we offer comprehensive solutions designed to empower financial institutions, improve operational efficiency, and enhance customer experience.</h5>
        </div>
      </div>

      <div class="our-solution">
        <div class="text-center mt-2 our-solutions">
          <h2 class="solution-h2 mt-4">Our Services</h2>
        </div>
      </div>



      {/* card */}

      <div class="container">
        <div class="row justify-content-center">


          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc">
                <h4 class="elc-h3">Core Banking Solutions</h4>
                <h6 class="elc-h6">Streamline your banking operations with our robust
                core banking solutions. From account management 
                to transaction processing, we  provide a scalable .
                and secure platform to meet your evolving needs</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-elc">
                <h4 class="elc-h3"> Risk Management	</h4>
                <h6 class="elc-h6">	Mitigate risks and ensure regulatory compliance with
                our advanced risk management solutions. From credit 
                risk to cybersecurity, we help safeguard  Your
                institution and protect your customers' assets.</h6>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={require('./image/banking.jpg')} alt="Health Image" />

          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                  Digital Banking	</h4>
                <h6 class="elc-h6">
                  Embrace digital transformation with our innovative
                 digital banking solutions. Offer your customers a 	
                seamless and convenient banking experience through
                 mobile banking apps, online banking portals,and digital
                  payment solutions.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                  Analytics and Insights</h4>
                <h6 class="elc-h6">
                  Unlock actionable insights and make informed
                 decisions with our data analytics and business
                  intelligence solutions. Gain a
                  deeper understanding 
                of customer behavior, market trends, and financial
               performance to drive growth and profitability.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose1">
          <h2 class="h2-choose">Why Choose Us</h2>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Industry Expertise: With a deep understanding of the banking and finance sector, our team of experts brings industry-specific knowledge and insights to every project.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Innovative Solutions: Stay ahead of the competition with our cutting-edge technology solutions tailored to meet the unique challenges and opportunities of the financial services industry.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Customer-Centric Approach: We prioritize customer satisfaction and strive to deliver solutions that enhance the overall banking experience for your customers, driving loyalty and retention.</h5>
             </div>
             </div>
        </div>
     </div>
   </div>
  </div>



 


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="box-start1 shadow rounded">
              <div class="box-contant">
                <h3 class="box-h3">

                  Transform Your Financial Services with Deinertech</h3>
                <h6 class="box-h6">
                  Partner with Deinertech to leverage the latest technologies and best practices in
                  banking and finance. Contact us today to learn more about our solutions and how
                  we can help you achieve your business goals.
                </h6>
                 <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
<Footer></Footer>




    </>

  )
}
export default Banking;