import React from 'react';
import './Services.css';

const Servicesc = () => {
    return (
        <div className="services-container">
            <h4 className="services-heading">Our Services</h4>
            <hr className="services-divider" />
            <h2 className="services-subheading">We Provide Best Service For Your Business</h2>
            <br />
            <div className="services-grid">
                <div className="service-card">
                    <i class="bi bi-facebook ser-icon"><p class="serv-p">Facebook Ads</p></i>
                </div>
                <div className="service-card">
                    <i class="bi bi-magnet ser-icon"><p class="serv-p">Lead Generation</p></i>
                </div>
                <div className="service-card">
                    <i class="bi bi-graph-up-arrow ser-icon"><p class="serv-p">Search Engine Optimization (SEO)</p></i>
                </div>
                <div className="service-card">
                <i class="bi bi-google ser-icon"><p class="serv-p">Google Ads</p></i>
                </div>
                <div className="service-card">
                    <i class="bi bi-globe2 ser-icon"> <p class="serv-p">Business Website</p></i>
                </div>
            </div>
        </div>
    );
}

export default Servicesc;
