import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './conatactm.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";


function Contactm() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (
    <>
       <Helmet>
        <title>Content Marketing Deinertech Software</title>
        {/* <meta name="description" content="Explore Deinertech Software's Content Marketing services. Unlock your business potential with tailored digital marketing solutions." />
        <meta name="keywords" content="Content Marketing, Digital Marketing, Business Growth, Deinertech Software, SEO, IT Solutions" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Content Marketing | Deinertech Software" />
        <meta property="og:description" content="Explore Deinertech Software's Content Marketing services. Unlock your business potential with tailored digital marketing solutions." />
      
        <meta property="og:url" content="https://www.deinertech.com/conatct" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>
      <div class="container-content">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Content Marketing</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Content Marketing</h6>
        </div>
      </div>



      <div class="Acoount-info">
        <h2 class="conantctm-h2 mb-4">Unlock Business Potential With Deinertech Software</h2>
      
        <h6 class="dev-p1">Welcome to Deinertech Software, your premier partner in leveraging technology for business growth.
           We specialize in providing tailored solutions that empower businesses to thrive in the digital era.
           Whether you’re looking to enhance your online presence, streamline operations, or boost customer satisfaction,
          we have the expertise and tools to make it happen.</h6>
       
        <h1 class="pos-h4">Our Services</h1>
      </div>

      <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical">
                    <h4 class="elc-h3">Conant Marketing Solutions</h4>
                    <h6 class="elc-h6">Amplify your brand's impact with Conant Marketing. Our tailored strategies cover everything from search engine optimization (SEO) and pay-per-click (PPC) advertising to social media campaigns, all driven by data to ensure effective results.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical">
                    <h4 class="elc-h3">Creative Content Development</h4>
                    <h6 class="elc-h6">Bring your vision to life with our innovative content solutions. Whether you need engaging blog posts, captivating videos, or comprehensive marketing collateral, we create content that resonates with your audience and drives engagement.</h6>
                </div>
            </div>
        </div>

        <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/content-marketing.png'} alt="Conant Marketing" />
        </div>

        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Comprehensive Analytics and Reporting</h4>
                    <h6 class="elc-h6">Maximize your marketing ROI with our in-depth analytics and reporting services. We provide insights that help you understand your audience better, optimize campaigns, and achieve your business goals effectively.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Customer Engagement Strategies</h4>
                    <h6 class="elc-h6">Enhance customer relationships with our engagement strategies. From personalized email marketing to interactive social media campaigns, we help you connect with your audience and build lasting loyalty.</h6>
                </div>
            </div>
        </div>
    </div>
</div>



  <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose3">
              <h2 class="">Why Choose Deinertech Software?</h2>
      

              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">Experience: With years of experience in the industry, we bring a wealth of knowledge and expertise to every project.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Innovation: We stay ahead of technology trends and adopt innovative solutions to meet the evolving needs of our clients.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Collaboration: We believe in collaboration and work closely with our clients to understand their goals and deliver tailored solutions.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Results-Driven: Our focus is on delivering tangible results that drive business growth and success.</h5>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
     

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Get in Touch</h3>
                <h6 class="box-h6">Ready to unlock your business potential? Contact Deinertech Software today to discuss your project requirements and discover how we can help you achieve your goals.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer></Footer>



















    </>
  )
}
export default Contactm;