import React from "react";
import './Enquire.css';
import { Link } from "react-router-dom";
import About from "../Navbar/About";
import Companyform from "../Companyform/Companyform";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Enquiery() {
  return (
    <>
      <Helmet>
        <title>Contact Us Deinertech Software</title>
        <meta name="description" content="Get in touch with Deinertech Software. Contact us for inquiries about our services, career opportunities, and more." />
        <meta name="keywords" content="Deinertech Software, contact, inquiry, services, career opportunities" />
        <meta name="author" content="Deinertech Software" />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content="Get in touch with Deinertech Software. Contact us for inquiries about our services, career opportunities, and more." />
        <meta property="og:image" content="https://www.deinertech.com/images/contact-us-og-image.jpg" />
        <meta property="og:url" content="https://www.deinertech.com/contact-form" />
        <meta property="og:type" content="website" />
      </Helmet>

      <About></About>

      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Contact Us</h1>
          <h6 class="exper-h">
            <Link to="/" className="dropdown-item">
              Home <i class="bi bi-chevron-double-right"></i>
            </Link>
          </h6>
          <h6 class="exper-h">Contact Us</h6>
        </div>
      </div>

      <div class="container cards-custm " >
        {/* Phone Number Card */}
        <div class="row" >
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card shadow rounded h-100 " style={{minHeight: '213px'}} >
              <img class="card-img-top enq-img" src={'./phonecall.png'} alt="Phone Icon" />
              <div class="card-body">
                <h6 class="enq-h6">
                  <a href="tel:+919209298516" className="contact-link">+91 920 929 8516</a><br />
                  <br/>
                  <a href="tel:+918766833680" className="contact-link">+91 876 683 3680</a>
                </h6>
              </div>
            </div>
          </div>
        </div>

        {/* Email Card */}
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card shadow rounded h-100" style={{minHeight: '213px'}}>
              <img class="card-img-top enq-img" src={'./Mail.png'} alt="Email Icon" />
              <div class="card-body">
                <h6 class="enq-h6">
                  <a href="mailto:contact@deinertech.com" className="contact-link">contact@deinertech.com</a><br /><br/>
                 
                </h6>
              </div>
            </div>
          </div>
        </div>

        {/* Location Card */}
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card exper-card shadow rounded h-100" style={{minHeight: '213px'}}>
              <img class="card-img-top enq-img" src={'./location.png'} alt="Location Icon" />
              <div class="card-body">
                <h6 class="enq-h6">
                   Kalp Avenue, Bhumkar Chowk, Shankar Kalat Nagar, Wakad, Pune, Maharashtra 411057
                  
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="intership-h">
        <h1>How We Can Help</h1>
      </div>

      <Companyform></Companyform>

      <div class="intership-h">
        <h1 class="form-h1">Find Us Here</h1>
      </div>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.310149031754!2d73.75130177519371!3d18.605114682504922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b931052188bb%3A0xf5fb140754427d76!2sDeinertech%20Software%20Private%20Limited!5e0!3m2!1sen!2sin!4v1728111839025!5m2!1sen!2sin"
     width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   

      <Footer></Footer>
    </>
  );
}

export default Enquiery;
