import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import About from "../Navbar/About";
import Requstf from "../Requst/Requst";
import Footer from "../Footer/Footer";
import './webdesign.css';

function Webdesign() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }

  return (
    <>
      <Helmet>
        <title>Web Designing Services Deinertech Software</title>
        {/* <meta name="description" content="Discover top-notch web designing services at Deinertech Software. Our expert team offers custom website design, responsive layouts, UX optimization, and SEO-friendly designs to enhance your online presence and drive engagement." />
        <meta name="keywords" content="web design, custom website design, responsive design, UX optimization, SEO-friendly design, Deinertech Software" />
        <meta name="robots" content="index, follow" />
      
        <meta property="og:title" content="Web Designing Services | Deinertech Software" />
        <meta property="og:description" content="Discover top-notch web designing services at Deinertech Software. Our expert team offers custom website design, responsive layouts, UX optimization, and SEO-friendly designs to enhance your online presence and drive engagement." />
        <meta property="og:url" content="https://www.deinertech.com/Webdesign" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About />
      <div className="container-webdevelopment">
        <div className="text-center mt-5">
          <h1 className="helth-h1">Web Designing</h1>
          <h6 className="Helath-h">
            <Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link>
          </h6>
          <h6 className="Helath-h">Web Designing</h6>
        </div>
      </div>

      <div className="Acoount-info">
        <h1 className="conantctm-h2 mb-0" style={{fontSize:"30px"}}>Web Designing</h1>
        <br />
        <h6 className="dev-p1">
          At Deinertech Software, we offer comprehensive web design services tailored to meet
          your business needs and elevate your online presence. Our expert web designers
          combine creativity with technical expertise to deliver visually stunning and user-friendly
          websites that engage and convert visitors. Here's what our web design services entail:
        </h6>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="elc mb-4">
              <div className="box-graphical">
                <h4 className="elc-h3">Custom Website Design</h4>
                <h6 className="elc-h6">We specialize in creating custom-designed
                  websites that reflect your brand identity and
                  resonate with your target audience. Our team
                  collaborates closely with you to understand
                  your vision and objectives, ensuring every aspect of your website is uniquely crafted to
                  suit your requirements.</h6>
              </div>
            </div>

            <div className="elc">
              <div className="box-graphical">
                <h4 className="elc-h3">Responsive Design</h4>
                <h6 className="elc-h6">In today's mobile-driven world, responsive design
                  is crucial. We design websites that adapt
                  seamlessly to different devices and screen sizes,
                  providing an optimal viewing experience for
                  users across desktops, tablets, and smartphones.</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-center">
            <img className="Helth-img mb-4" src={'/webdesigner.png'} alt="Web Designer Image" />
          </div>

          <div className="col-md-4">
            <div className="elc mb-4">
              <div className="box-graphical right-border">
                <h4 className="elc-h3">User Experience (UX) Optimization</h4>
                <h6 className="elc-h6">User experience is at the heart of our design process. We prioritize usability and intuitive navigation to enhance user engagement and satisfaction. By incorporating UX best practices, we ensure that visitors can easily find information and accomplish tasks on your website.</h6>
              </div>
            </div>

            <div className="elc">
              <div className="box-graphical right-border">
                <h4 className="elc-h3">SEO-Friendly Design</h4>
                <h6 className="elc-h6">We integrate SEO considerations into our web design process to help improve your website's visibility and rankings on search engines. Our SEO-friendly approach includes optimizing site structure, metadata, and content for better organic search performance.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-md-4">
          <div className="elc mb-4">
            <div className="box-graphical1">
              <h4 className="elc-h3">Visual Appeal and Brand Consistency</h4>
              <h6 className="elc-h6">Our designs prioritize aesthetics without compromising functionality. We pay attention to every detail, from color schemes and typography to imagery and layout, to create visually compelling websites that align with your brand identity.</h6>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2">
              <div className="box-contant">
                <h3 className="box-h3">Ongoing Support and Maintenance</h3>
                <h6 className="box-h6">
                  We provide ongoing support and maintenance services to ensure your website remains secure, up-to-date,
                  and performing optimally. Whether you need content updates, technical assistance, or additional enhancements,
                  we're here to support your digital presence.
                </h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer />
    </>
  )
}

export default Webdesign;
