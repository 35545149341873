import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';  
import About from "../Navbar/About";
import Footer from "../Footer/Footer";
import './Ourcompany.css';
import Slideshows from "../Slideshow/Slideshow";
import Deinerteam from "../deinerteam/Deinerteam";
import CEOCard from "../Ceo/Ceo";

function OurCompany() {
    const images = [
        require("../image/Government.png"),
        require("../image/msme.png"),
        require("../image/Makeinindia.jpg"),
        require("../image/unnamed-1.png"),
        require("../image/Government.png"),
        require("../image/msme.png"),
        require("../image/Makeinindia.jpg"),
        require("../image/unnamed-1.png"),
        require("../image/msme.png"),
        require("../image/Makeinindia.jpg"),
        require("../image/unnamed-1.png"),
    ];



    return (
        <>
        
            {/* Add SEO using Helmet */}
            <Helmet>
                <title>Our Company Deinertech Software </title>
                <meta name="description" content="Explore career opportunities with Deinertech Software. Learn about our company, mission, vision, and the benefits of working with us." />
                <meta name="keywords" content="Deinertech Software, careers, company, IT solutions, digital marketing, India, job opportunities" />
                <meta property="og:title" content="OurCompany" />
                <meta property="og:description" content="Discover career opportunities at Deinertech Software. Learn about our company, mission, and vision, and why we're a great place to work." />
                <meta property="og:url" content="https://deinertech.com/our-company" />
                <meta property="og:type" content="website" />
            </Helmet>

            <About />

            <div className="container-ouvercompay w-100">
                <div className="text-center mt-5">
                    <h1 className="hosp-h1">Our Company</h1>
                    <h6 className="hosp-h">
                        <Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link>
                    </h6>
                    <h6 className="hosp-h">Our Company</h6>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-12 information">
                        <h3 className="info">WHO WE ARE</h3>
                        <h1 className="it-partner">Delivering Results-Driven
                            <span className="ourc-s"> IT Solutions and Digital Marketing</span> 
                        </h1>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-6 custom-padding">
                        <div className="content">
                            <p className="ourcompany-p">
                                <span className="ourcompay-span">Deinertech Software</span> is your trusted partner for all your IT needs in India. We offer a comprehensive suite of affordable, high-quality services designed to empower your business growth. Whether you need a stunning website,
                                robust software development, effective digital marketing campaigns, or eye-catching graphic design, our team of experts is here to deliver exceptional results. Deinertech Software is your trusted partner for results-oriented digital marketing in Pune, India. We’re a full-service agency passionate about helping businesses
                                achieve their goals through effective and measurable solutions. We leverage website design, development, and optimization alongside targeted digital marketing activities to boost brand recognition, generate qualified leads, and maximize return on marketing investment.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-6 custom-padding d-flex justify-content-end">
                        <img className="image-ourcompay" src={'/deinerimgjpg.jpg'} alt="Deinertech Software" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="box-ourcompay  shadow rounded">
                    <div className="box-heading">
                        <img className="" src={'/history.png'} alt="History" />
                        <h5 className="box-h">OUR HISTORY</h5>
                    </div>
                    <p className="hosp-p">
                        Deinertech Software has been transforming businesses with innovative digital solutions since 2023. We specialize in IT infrastructure,consulting, digital marketing,
                        e-commerce marketing and software development, enabling global companies and well-known brands to outshine in the digital landscape.
                        <br />
                    </p>
                    <p className="hosp-p">Our passion is to render ideas and solutions that propel organizations towards excellence. We imprint in fabricating long-term partnerships with our clients, built on peerless service and a commitment to engrafting value. Since our humble beginnings in 2023, our dedication, customer-centric approach and expertise in software solutions have fuelled our success. Today, we are proud to functionalize in three major Indian cities: Pune (headquarters), Delhi and Patna. Our diverse and talented staff is dedicated in subsidizing customers to achieve the most daunting and ambitious IT persuits.</p>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-sm-5 mb-3 mb-sm-0">
                        <div className="box-ourcompay1  shadow rounded h-100">
                            <div className="box-heading">
                                <img className="" src={'/misstion.png'} alt="Mission" />
                                <h5 className="box-h">Our Mission</h5>
                            </div>
                            <p className="hosp-p">
                                <span className="ourcomapyd-span">Guiding Success Through Digital Channels</span> <br />
                                Our passion is to render ideas and solutions that propel organizations towards excellence. We imprint in fabricating long-term partnerships with our clients, built on peerless service and a commitment to engrafting value. Since our humble beginnings in 2023, our dedication, customer-centric approach and expertise in software solutions have fuelled our success. Today, we are proud to functionalize in three major Indian cities: Pune (headquarters), Delhi and Patna. Our diverse and talented staff is dedicated in subsidizing customers to achieve the most daunting and ambitious IT persuits.
                            </p>
                        </div>
                    </div>

                    <div className="col-sm-5">
                        <div className="box-ourcompay1 shadow rounded h-100">
                            <div className="box-heading">
                                <img className="" src={'/vision.png'} alt="Vision" />
                                <h5 className="box-h">Our Vision</h5>
                            </div>
                            <p className="hosp-p">
                                <span className="ourcomapyd-span">Elevating Brand Experiences</span><br />
                                Our passion is to render ideas and solutions that propel organizations towards excellence. We imprint in fabricating long-term partnerships with our clients, built on peerless service and a commitment to engrafting value. Since our humble beginnings in 2023, our dedication, customer-centric approach and expertise in software solutions have fuelled our success. Today, we are proud to functionalize in three major Indian cities: Pune (headquarters), Delhi and Patna. Our diverse and talented staff is dedicated in subsidizing customers to achieve the most daunting and ambitious IT persuits.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <CEOCard/>
           <h1 className="our-clients" style={{textAlign:"center",marginTop:"-118px"}}><span className="certification">Certification</span></h1> 
          <div className="marquee-slider mb-5">
             <div className="marquee">
                    <div className="marquee-inner">
                        {images.concat(images).map((image, index) => (
                            <img key={index} className="ouvercomapy-image" src={image} alt={`Slider ${index}`} />
                        ))}
                    </div>
                </div>
            </div>
                   
                
            

            <Slideshows />
           
            <div className="container">
                
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-12 information">
                        <h2 className="info1">Quality Policy –<span className="ourc-s"> Our Promise of Excellence</span></h2>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="content">
                            <p className="ourcomay-p1">
                                <span className="ourcompay-span">Deinertech Software, </span>quality is more than a goal - it's our unwavering commitment.
                               We understand that your business is unique and we strive to exceed your expectations with every interaction. 

                            </p>

                            <div className="record-c d-flex">
                                <i className="bi bi-record-circle-fill icon-circle"></i>
                                <p className="high-quality">High-quality products: A potent history of rendering quality goods that match our brand image.</p>
                            </div>

                            <div className="record-c d-flex">
                                <i className="bi bi-record-circle-fill icon-circle"></i>
                                <p className="high-quality">Proven Sales History: A history of online sales success and customer satisfaction.</p>
                            </div>

                            <div className="record-c d-flex">
                                <i className="bi bi-record-circle-fill icon-circle"></i>
                                <p className="high-quality">Excellent Customer Service: A commitment to rendering par excellent customer service that meets our standards.</p>
                            </div>

                            <div className="record-c d-flex">
                                <i className="bi bi-record-circle-fill icon-circle"></i>
                                <p className="high-quality">Reliable inventory management: The ability to consistently fill orders and maintain adequate inventory levels.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                        <img className="image-ourcompay" src={'./business-privacy-policy.jpg'} alt="Privacy Policy" />
                    </div>
                </div>
            </div>

            <br />
            <br />

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-12 information">
                        <h3 class="info">Why Choose Deinertech Software?</h3>
                        <br />

                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">  World-Class Project Management: On-time, on-budget delivery through meticulous planning and execution.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">  Strong Client Partnerships: Transparent communication and collaboration throughout the process</span></i></h6>

                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">  Comprehensive Digital Expertise: From web development and design to digital marketing and IT support – we do it all.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">  One-Stop-Shop: Simplify your digital needs with a single trusted partner.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">  Robust IT Support: Minimize downtime and ensure seamless operations with our 24/7 IT support.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">   Modern, User-Centric Websites: Beautiful and functional websites built with the latest technologies.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">   Data Security First: Strict adherence to security and compliance standards protects your information.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">  Cost-Effective Solutions: Maximize your ROI with high-quality solutions that fit your budget.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">   Tailored for Your Success: Customizable solutions designed to meet your unique needs.</span></i></h6>
                        <h6>  <i class="bi bi-megaphone-fill mega-ph"><span class="mega-phone">   Scalable for Growth: Solutions that adapt and evolve alongside your growing busines</span></i></h6>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default OurCompany;
