import React from "react";
import './Soluation.css';

function Solution() {
  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
          <img className="img-fluid solution-img " src="/home img 2.png" alt="Process GIF" />
          </div>
          <div className="col-md-6">
            <a href="/ourcompany" className="btn btn-primary our-company mb-3">Our Company</a>
            <h3 className="heading">Specialized <span className="heading1"> IT solutions and digital marketing</span> for your business success.</h3>
            <div class="paragraph-container">
        <p className="paragraph">
            <span className="para">Deinertech Software</span> is your pioneer
               business partner for all your IT solution needs in Pune, India. Our solutions
              are based on a holistic approach that involves using a variety of digital
              marketing strategies and tactics to reach and engage customers across multiple
              channels and touch points.We propose a full range of affordable and high
              quality services designed to help your business progression.
              Our digital experts specialize in fabricating
             stunning websites, powerful software development, effective digital marketing
              campaigns and captivating graphic design that are nothing short of perfection.
            We propose services that not only cover all
           your criteria but also provide you with maximum marketing ROI designed to take
         your business to the next level.
        </p>
    </div>


            <hr className="my-4" />       
      <div className="container">
      <div className="row align-items-left">
        <div className="col-md-6">
          <div className=" iconthub d-flex">
          <i class="bi bi-hand-thumbs-up-fill"></i><h5 class="">Moneyback <br/>Guarantee</h5>
         
          </div>
        </div>
   

        <div className="col-md-6">
          <div className="iconthub d-flex">
          <i class="bi bi-gear-fill"></i>
            <h5 className="">24*7 Technical Support</h5>
          </div>
        </div>
      </div>
    </div>
    <hr className="my-4" /> 
    </div>
       </div>
         </div>
      
    </>
  );
}

export default Solution;