import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Homepage from './pages/Homepage';
import OurCompany from './pages/Ourcomay/Ourcompay';
import Footer from './pages/Footer/Footer';
import ImageSlider from './pages/Imageslider';
import Revenu from './pages/Revenu';
import Health from './pages/Health';
import Banking from './pages/Banking';
import Slider from './pages/Slider';
import Advaer from './pages/Advaer';
import Travel from './pages/Travel';
import RealEs from './pages/RealEs';
import Information from './pages/Information';
import About from './pages/Navbar/About';
import Deinertech from './pages/Deinertech';
import Soluation from './Solution/Soluation';
import Services from './pages/Services/Services';
import Erpsoluation from './pages/ErpSoluation/ErpSoluation';
import Domain from './pages/Domain/Domain';
import Accounting from './pages/Accouting/Accountings';
import HospitalM from './pages/HospitalM/HospitalM';
import Payroll from './pages/Payroll/Payroll';
import Pos from './pages/PosSoftware/Pos';
import Inventory from './pages/Inventory/Inventory';
import Schoolm from './pages/School/Schoolm';
import Hrms from './pages/HR/Hrms';
import Deinererp from './pages/deinererp/Deinererp';
import Development from './pages/Development/development';
import Digitalm from './pages/DigitalM/Digitalm';
import Graphical from './pages/Graphical/Graphical';
import Webdesign from './pages/WebDesign/Webdesign';
import Flipcard from './pages/Flipcard/Flipcard';
import Working from './pages/Workingp/Workingp';
import Webdevelopment from './pages/Webdevlopment/Webdevelopment';
import Mobliedev from './pages/Moblieappdev/Moblieappdev';
import UIUX from './pages/UIUX/UIUX';
import Contactm from './pages/ConatactM/Conatactm';
import Socialm from './pages/SocialM/Socialm';
import Searchm from './pages/Searchem/Searchm';
import Emailm from './pages/Emailm/Emilm';
import LeadGen from './pages/Lead Generation/LeadGeneration';
import Whatsapp from './pages/Whatsapp/Whatsapp';
import Dataeng from './pages/Dataeng/Dataeng';
import Carrer from './pages/Career/Career';
import Googler from './pages/Googler/Googler';
import ResponsiveForm from './pages/Form/Form';
import Intership from './pages/Intership/Intership';
import Enquiery from './pages/Enquire/Enquire';
import Companyform from './pages/Companyform/Companyform';
import Ecommercesm from './pages/Ecommerces/ecommerces';
import Privacy from './pages/Privacy Policy/Privacy Policy';
import ReturnPrivacy from './pages/ReturnPolicy/ReturnPolicy';
import Terms from './pages/Terms&condtion/Terms';
import ImageWithHoverContent from './pages/Googler/Googler';
import Blog from './pages/Blog/Blog ';
import Javas from './pages/Javap/Javap';
import Readmorej from './pages/Readmorej/Readmorej';

import Builder from './pages/Bulider/Bulider';
import Tower from './pages/Bulider/Tower';
import Truck from './pages/Bulider/Truck';
import Javaut from './pages/Bulider/Javaut';
import Informationj from './pages/Bulider/Informationj';
import Bydeiner from './pages/Bulider/Bydeinertech';
import April from './pages/Bulider/April';
import Blog2024 from './pages/Bulider/Blog2024';
import CommentForm from './pages/Postcommand/Postcommand';
import Slideshows from './pages/Slideshow/Slideshow';
import CardsSlider from './pages/CardsSlider/CardsSlider';
import Design from './pages/Design/Design';
import VideoE from './pages/VideoEditing/VideoEditing';
import SalesF from './pages/SalesF/SalesF';
import Salesc from './pages/SalesC/Salesc';
import SalesE from './SalesE/SalesE';
import Saless from './SalesE/Saless';
import MarketingC from './SalesE/Marketingc';
import Helaths from './SalesE/Helaths';
import Revenuc from './SalesE/Revenuec';
import Deinerteam from './pages/deinerteam/Deinerteam';
import Reviewg from './pages/Reviewg.js/Reviewg';
import ElfsightWidget from './pages/ElfsightWidget/ElfsightWidget';
import EnquireForm from './pages/EnquireForm/EnquireForm';
import Header from './pages/Heaerd/Headerd';
import BlogD from './pages/BlogD/BlogD';
import Erpblog from './pages/ERPBlog';
import BannerSlideshow from './pages/Bannerslideshow';
import Servicesc from './pages/Servicesc/Servicesc';
import Requstf from './pages/Requst/Requst';
import BlogWebd from './pages/Webdesignb/Webblog';
import DigitalBlog from './pages/Digitalblog/Digitalblog';
import Ouverclites from './pages/Ouverclites/Ouverclites';
import CEOCard from './pages/Ceo/Ceo';
import ScrollToTop from './pages/Scrolltop';


import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Expertise from './pages/Expertise/Expertise';
import Eventm from './pages/EventMangement/Eventm';
import Ourservices from './pages/Ourservices/Ourservices';


const ErrorPage = () => (
  <div>
    <h1>404 Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);



const router = createBrowserRouter([


  {
    path:"ouverclites",
    element:<Ouverclites/>
  },
  {
    path:"ceo",
    element:<CEOCard/>
  },

  {
    path:"Servicesc",
    element:<Servicesc/>
  },
  {
    path:"Requestf",
    element:<Requstf/>
  },
  {
    path:"Blogwebd",
    element:<BlogWebd/>
  },

  {
    path: "graphical",
    element: <Graphical/>,
    errorElement: <ErrorPage />
  },
  {
    path:"EnquireF",
    element:<EnquireForm/>
  },

  {
    path:"Banners",
    element:<BannerSlideshow/>
  },

  {
    path:"DigitalBlog",
    element:<DigitalBlog/>
  },


  {
    path: "Erp",
    element: <Erpsoluation />,
    errorElement: <ErrorPage />
  },

  {
    path: "Domain",
    element: <Domain/>,
    errorElement: <ErrorPage />
  },

  {
    path: "webdev",
    element: <Webdevelopment/>,
    errorElement: <ErrorPage />
  },
{
  path: "careers",
  element: <Carrer/>,


},

{
  path: "ErpBlog",
  element: <Erpblog/>,


},
{
  path: "Blog",
  element: <Javas/>,


},
{
  path: "BlogD",
  element: <BlogD/>,


},

{
  path: "contact-form",
  element: <Enquiery/>,


},
{
  path: "internship",
  element: <Intership/>,


},
{
  path: "header",
  element: <Header/>,


},

{
  path: "hover",
  element: <ImageWithHoverContent/>,


},
{
  path: "Readm",
  element: <Readmorej/>,


},



  {
    path: "Solution",
    element: <Soluation />,
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <Deinertech />,
    errorElement: <ErrorPage />
  },
  {
    path: "health-care",
    element: <Health />,
    errorElement: <ErrorPage />
  },
  {
    path: "about",
    element: <About />,
    errorElement: <ErrorPage />
  },
  {
    path: "company",
    element: <OurCompany />,
    errorElement: <ErrorPage />
  },
  {
    path: "footer",
    element: <Footer />,
    errorElement: <ErrorPage />
  },
  {
    path: "images",
    element: <ImageSlider />,
    errorElement: <ErrorPage />
  },
  {
    path: "revenue",
    element: <Revenu />,
    errorElement: <ErrorPage />
  },
  {
    path: "banking-and-finance",
    element: <Banking />,
    errorElement: <ErrorPage />
  },
  {
    path: "slide",
    element: <Slider />,
    errorElement: <ErrorPage />
  },
  {
    path: "advertisement",
    element: <Advaer/>,
    errorElement: <ErrorPage />
  },
  {
    path: "travel-and-hospitality",
    element: <Travel/>,
    errorElement: <ErrorPage />
  },
 {
    path: "real-estate",
    element: <RealEs/>,
    errorElement: <ErrorPage />
  },

  {
    path: "working",
    element: <Working/>,
    errorElement: <ErrorPage />
  },
  {
    path:"information-technologies",
    element: <Information/>,
    errorElement: <ErrorPage />
  },

  {
    path:"Servicesa",
    element: <Services/>,
    errorElement: <ErrorPage />
  },

  {
    path:"Hospital-Management-System",
    element: <HospitalM/>,
    errorElement: <ErrorPage />
  },
  
  {
    path:"Accounting-software",
    element: <Accounting/>,
    errorElement: <ErrorPage />
  },
  {
    path:"payroll-software",
    element: <Payroll/>,
    errorElement: <ErrorPage />
  },

  {
    path:"Domain-expertise",
    element: <Expertise/>,
    errorElement: <ErrorPage />
  },

  {
    path: "pos-software",
    element: <Pos />,
    errorElement: <ErrorPage />
  },
  {
    path: "returnp",
    element: <ReturnPrivacy/>,

  },

  
  {
    path: "event-management-system",
    element: <Eventm />,
    errorElement: <ErrorPage />
  },
  {
    path: "inventory-software",
    element: <Inventory />,
    errorElement: <ErrorPage />
  },

  {
    path: "School-Management-System",
    element: <Schoolm />,
    errorElement: <ErrorPage />
  },

  {
    path: "hr-software",
    element: <Hrms/>,
    errorElement: <ErrorPage />
  },

  {
    path:"/OurServices",
    element:<Ourservices/>
  },

  {
    path:"Terms",
    element:<Terms/>
  },
  {
    path:"erp-solution",
    element:<Deinererp/>
  },

  {
    path:"ourcompany",
    element:<OurCompany/>
  },
  {
    path:"development",
    element:<Development/>
  },

  {
    path:"privacy",
    element:<Privacy/>
  },
  {
    path:"digital",
    element:<Digitalm/>
  },

  {
    path:"Webdesign",
    element:<Webdesign/>
  },

  {
    path:"flipcard",
    element:<Flipcard/>
  },
  {
    path:"Mobiledev",
    element:<Mobliedev/>
  },
  {
    path:"UIUX",
    element:<UIUX/>
  },

  {
    path:"form",
    element:<ResponsiveForm/>
  },

  {
    path:"ecom",
    element:<Ecommercesm/>
  },

  {
    path:"conatct",
    element:<Contactm/>
  },
  {
    path:"social",
    element:<Socialm/>
  },
  {
    path:"searchm",
    element:<Searchm/>

  },

  {
    path:"Email",
    element:<Emailm/>

  },
  {
    path:"LeadG",
    element:<LeadGen/>

  },
  {
    path:"Whatsapp",
    element:<Whatsapp/>

  },
  {
    path:"Dataeng",
    element:<Dataeng/>
  },

  {
    path:"Googler",
    element:<Googler/>
  },
  {
    path:"Companyf",
    element:<Companyform/>
  },

  {
    path:"Blog",
    element:<Blog/>

  },

  

  {
    path:"Bulider",
    element:<Builder/>

  },
  {
    path:"Tower",
    element:<Tower/>

  },
  {
    path:"Truck",
    element:<Truck/>
  },
  {
    path:"Javaut",
    element:<Javaut/>
  },
  {
    path:"Informationj",
    element:<Informationj/>
  },
  {
    path:"Bydeiner",
    element:<Bydeiner/>
  },

  {
    path:"April",
    element:<April/>
  },
  {
    path:"Blog2024",
    element:<Blog2024/>
  },
  {
    path:"Command",
    element:<CommentForm/>

  },
  {
    path:"Slideshows",
    element:<Slideshows/>

  },
  {
    path:"CardsSlides",
    element:<CardsSlider/>
  },

  {
    path:"Design",
    element:<Design/>
  },

  {
    path:"VideoE",
    element:<VideoE/>
  },
  {
    path:"SalesF",
    element:<SalesF/>
  },
  {
    path:"Salesc",
    element:<Salesc/>

  },
  {
    path:"SalesE",
    element:<SalesE/>

  },
  {
    path:"Saless",
    element:<Saless/>
  },
  {
    path:"Marketingc",
    element:<MarketingC/>
  },
  {
    path:"Helaths",
    element:<Helaths/>
  },
  {
    path:"Revenuc",
    element:<Revenuc/>
  },
  {
    path:"deinerteam",
    element:<Deinerteam/>
  },
  {
    path:"reviewg",
    element:<Reviewg/>
  },
  {
    path:"Elfsight",
    element:<ElfsightWidget/>
  }


  


]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
