import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './Socialm.css';
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";


function Socialm(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    return(
       <>
           <Helmet>
        <title>Social Media Marketing Deinertech Software</title>
        {/* <meta name="description" content="Elevate your brand's presence with Deinertech Software's Social Media Marketing solutions. We specialize in crafting tailored strategies to maximize impact." />
        <meta name="keywords" content="Social Media Marketing, Deinertech Software, Digital Marketing, Brand Awareness, Online Presence, Social Media Strategy" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Social Media Marketing | Deinertech Software" />
        <meta property="og:description" content="Elevate your brand's presence with Deinertech Software's Social Media Marketing solutions. We specialize in crafting tailored strategies to maximize impact." />
        <meta property="og:url" content="https://www.deinertech.com/social" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
       <About></About>
       <div class="container-Socialm">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Social Media Marketing</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Social Media Marketing</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
            <h3 class="conantctm-h2 mb-4">Maximize Your Brand’s Impact With Deinertech Software’s Social Media Marketing Solutions</h3>
           
          <h5 class="dev-p1">In today’s digital age, social media has become a powerful platform for businesses to connect with their  audience,  
            build brand awareness, and drive conversions. At Deinertech Software, we specialize in crafting tailored social media  marketing strategies
             that elevate your brand’s presence and impact in the digital landscape.</h5>
            

             <h2 class="pos-h4">Our Approach to Social Media Marketing</h2>
         </div>

          
  {/* <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Strategic Planning</h4>
            <h6 class="elc-h6">We begin by understanding your business goals,target audience, and competitive landscape. Our team then develops a strategic social media plan that aligns with your objectives and drives engagement.</h6>
          </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Platform Optimization</h4>
            <h6 class="elc-h6">Each social media platform offers unique opportunities for engagement. We optimize your presence on platforms such as Facebook,  Instagram, Twitter, LinkedIn, and  more to maximize reach and impact.</h6>
            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/webdeveloper.png'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Content Creation</h4>
            <h6 class="elc-h6">Engaging content is at the heart of successful
             social media marketing. Whether it's compellingvisuals, informative videos, or engaging posts,we create content that resonates with your audience and encourages  interaction</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Community Engagement</h4>
            <h6 class="elc-h6">Building a strong community around your brand is  essential. We engage with your audience, respond to comments and  messages promptly, and foster meaningful conversations to enhance brand loyalty. </h6>


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-social">
            <h4 class="elc-h3">Data-Driven Insights</h4>
            <h6 class="elc-h6">Our approach is data-driven. We monitor key  metrics, analyze performance, and refine strategies  to ensure continuous improvement and optimal results.</h6>

         </div>
        </div>
        </div>
        </div> */}

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical">
                    <h4 class="elc-h3">Strategic Social Media Planning</h4>
                    <h6 class="elc-h6">At Conant Marketing, we start by analyzing your business objectives, target audience, and market dynamics. Our team crafts a tailored social media strategy designed to enhance engagement and drive brand growth.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical">
                    <h4 class="elc-h3">Platform-Specific Optimization</h4>
                    <h6 class="elc-h6">Each social media platform has its own unique strengths. We optimize your brand's presence across platforms like Facebook, Instagram, Twitter, and LinkedIn to maximize visibility and foster meaningful interactions.</h6>
                </div>
            </div>
        </div>

        <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/socialmediam.jpg'} alt="Social Media Marketing" />
        </div>

        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Engaging Content Creation</h4>
                    <h6 class="elc-h6">Compelling content is crucial for social media success. Our creative team produces eye-catching visuals, informative videos, and engaging posts that resonate with your audience and spark interaction.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Community Building & Engagement</h4>
                    <h6 class="elc-h6">Fostering a vibrant community around your brand is essential. We actively engage with your audience, promptly respond to comments and messages, and nurture meaningful conversations to strengthen brand loyalty.</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-social">
                    <h4 class="elc-h3">Data-Driven Insights & Analytics</h4>
                    <h6 class="elc-h6">Our strategies are anchored in data. We track key performance indicators, analyze engagement metrics, and continuously refine our approach to ensure optimal results and sustained growth.</h6>
                </div>
            </div>
        </div>
    </div>
</div>



     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Deinertech Software for Social Media Marketing?</h2>
          <br/>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Expertise: Our team comprises experienced social media marketers who stay updated with the latest trends and best practices.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Creativity: We bring creativity and innovation to every campaign, ensuring that your brand stands out in a crowded digital space.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Results-Oriented: Our focus is on delivering measurable results, whether it's increased engagement, lead generation, or sales conversions.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel"> Client-Centric: We prioritize open communication, collaboration, and transparency to ensure that our strategies align with your business objectives.
             </h5>
             </div>
          </div>
          </div>
          </div>
          </div>
          </div>

  <br/>
  <br/>

        <div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-10 col-sm-12 mx-auto d-block">
        <div class="box-start2">
          <div class="box-contant">
            <h3 class="box-h3">Get Started Today</h3>
            <h6 class="box-h6">Ready to elevate your brand's presence on social media? Partner with Deinertech Software for results-driven
                 social media marketing solutions. Contact us today to 
                discuss your social media marketing needs and take your brand to new heights of success.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
        <Footer></Footer>



   

         













       </>
    )
}
export default Socialm;