import React, { useState } from "react";
import About from "./Navbar/About";
import Footer from "./Footer/Footer";
import Requstf from "./Requst/Requst";
import './helathcare.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Health() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
    <Helmet>
        <title>Health care Deinertech Software Pvt Ltd</title>
        {/* <meta name="description" content="Explore innovative healthcare software solutions by Deinertech Software Pvt Ltd. Our technology enhances patient care and streamlines healthcare operations." />
        <meta name="keywords" content="Healthcare, Software, EHR, Telemedicine, Patient Portal, Practice Management, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Health care | Deinertech Software Pvt Ltd" />
        <meta property="og:description" content="Explore innovative healthcare software solutions by Deinertech Software Pvt Ltd. Our technology enhances patient care and streamlines healthcare operations." />
        <meta property="og:url" content="https://deinertech.com/Health" />
        <meta property="og:image" content="https://deinertech.com/health-image.png" /> */}
      </Helmet>
      <About></About>



      <div class="container-helthcare ">
        <div class="text-center mt-5">
          <h1 class="helth-h1" >Health care </h1>
          <h5 class="Helath-h"  ><Link to="/" className="dropdown-item" style={{color:'black'}} >Home  <i class="bi bi-chevron-double-right"></i></Link></h5>
          <h5 class="Helath-h">Health Care</h5>
        </div>
      </div>



      <div class="welcome">
        <div class="text-center Wel-come">
          <h2 className="heading-h2">Welcome To <span class="span">Deinertech Software Pvt Ltd</span></h2>
        </div>
        <div class="hel-th">
          <h5 class="helath-p mt-0">Deinertech Software Pvt Ltd, we’re dedicated to revolutionizing the healthcare industry through innovative software solutions.  Our cutting-edge technology is designed to streamline operations, improve patient care, and enhance overall efficiency for healthcare providers.</h5>
        </div>
      </div>

      <div class="our-solution">
        <div class="text-center mt-1 our-solutions">
          <h2 class="solution-h2 mt-4">Our Solutions</h2>
        </div>
      </div>



      {/* card */}

      <div class="container">
        <div class="row justify-content-center">


          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc h-100">
                <h4 class="elc-h3">Electronic Health Records (EHR)</h4>
                <h6 class="elc-h6">Say goodbye to cumbersome paper-based records.
                Our EHR system simplifies data management,
                ensures accuracy, and enhances collaboration
                  among healthcare professionals.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-elc h-100">
                <h4 class="elc-h3">Practice Management</h4>
                <h6 class="elc-h6">Optimize your practice's workflow with our
                comprehensive practice management tools.
                From appointment scheduling to billing
                and reporting, we've got you covered.</h6>
            </div>
          </div>
        </div>

        <div class="col-md-4 text-center">
          <img class="Helth-img mb-4" src={require('./image/health1.png')} alt="Health Image" />

        </div>

        <div class="col-md-4">
          <div class="elc mb-4">
            <div class="box-elc right-border h-100">
              <h4 class="elc-h3">Telemedicine</h4>
              <h6 class="elc-h6">Embrace the future of healthcare with our
                telemedicine platform. Connect with patients
                remotely, conduct virtual consultations, and
                deliver high-quality care anytime, anywhere.</h6>
            </div>
          </div>

          <div class="elc">
            <div class="box-elc right-border h-100">
              <h4 class="elc-h3">Patient Portal</h4>
              <h6 class="elc-h6">Empower patients with easy access to their health
                information, appointment scheduling, and secure
                communication with healthcare providers through
                our intuitive patient portal.</h6>
            </div>
          </div>
        </div>
      </div>
    </div >



  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose1">
          <h2 class="h2-choose">Why Choose Us</h2>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Reliability : Our software is built on robust technology, ensuring reliability and security for your sensitive healthcare data.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Customization: Tailored to meet the unique needs of your practice, our software can be customized and scaled as your business grows.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Customization: Tailored to meet the unique needs of your practice, our software can be customized and scaled as your business grows.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">User-Friendly:  Designed with simplicity in mind, our user-friendly interface makes it easy for healthcare professionals and patients to navigate the system effortlessly.</h5>
             </div>
          </div>

     
        
        </div>
      </div>
   </div>
  </div>



  <div class="container">
    <div class="row ">
      <div class="col-lg-12 d-flex justify-content-center">
        <div class="box-start1 shadow rounded">
          <div class="box-contant ">
            <h3 class="box-h3">
            Get Started Today</h3>
            <h6 class="box-h6">
            Join the thousands of healthcare providers who trust Deinertech Software Pvt Ltd for their digital transformation journey. Contact us today to schedule a demo and experience the power of our innovative healthcare software firsthand.
            </h6>
            <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

  <Footer></Footer>
           
               



        </>
        
    )
}
export default Health;