import React from "react";
import './Deinertecht.css';

function Deinerteam() {
  return (
    <>
      <div className="team-intro">
        <h1>Globally Sourced, Locally Smart.</h1>
        <p>
          We’re a team of creative and strategic thinkers with international experience and deep cultural<br/>
          knowledge. We unlock powerful solutions for your unique challenges, anywhere in the world.
        </p>
      </div>

      <div className="custom-card-container">
        <div className="custom-card shadow rounded">
          <img src={'./Ramanand.jpeg'} alt="Card Image" className="custom-card-image" />
          <div className="custom-card-content">
            <h2 className="emp-name">Ramanand Roy</h2>
            <p>Director</p>
          </div>
        </div>
      </div>

      <div className="card-container">
        <div className="custom-card shadow rounded">
          <img src={'./prveen.jpg'} alt="Card Image 1" className="custom-card-image" />
          <div className="custom-card-content">
            <h2 className="emp-name">Praveen Kumar</h2>
            <p>Co-Founder & Managing Director</p>
            <br/>
            <p>Entrepreneur, Business Strategist, Social Media & Digital Marketing Specialist.</p>
          </div>
        </div>
        <div className="custom-card shadow rounded">
          <img src={'./seema.jpg'} alt="Card Image 2" className="custom-card-image" />
          <div className="custom-card-content">
            <h2 className="emp-name">Seema Rai</h2>
            <p>Vice President</p>
            <br/>
            <p>Messaging, Research & Content Marketing, General Manager,</p>
          </div>
        </div>
      </div>

      <div className="card-container">
        <div className="custom-card shadow rounded">
          <img src={'./seema.jpg'} alt="Card Image 1" className="custom-card-image" />
          <div className="custom-card-content">
            <h2 className="emp-name">Sobha Rai</h2>
            <p>Human Resources</p>
            <br/>
            <p>Attracts, retains, and develops talent to build a strong workforce.</p>
          </div>
        </div>
        <div className="custom-card shadow rounded">
          <img src={'./sajjan.jpg'} alt="Card Image 2" className="custom-card-image" />
          <div className="custom-card-content">
            <h2 className="emp-name">Sajjan Shingare</h2>
            <p>Marketing & Strategy</p>
            <br/>
            <p>Marketing Maverick & Social Entrepreneur | Brewing Innovation</p>
          </div>
        </div>
        <div className="custom-card shadow rounded">
          <img src={'./dummy.jpg'} alt="Card Image 2" className="custom-card-image" />
          <div className="custom-card-content">
            <h2 className="emp-name">Sumit Shinde</h2>
            <p>Associate Software Developer</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Deinerteam;
