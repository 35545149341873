import Requstf from "../pages/Requst/Requst";
import React, { useState } from "react";
import About from "../pages/Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer/Footer";
import { Helmet } from "react-helmet";


function Helaths() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (

    <>
      <Helmet>
        <title>Health Cloud Solutions Deinertech Software</title>
        {/* <meta name="description" content="Explore comprehensive Health Cloud solutions by Deinertech Software. Enhance patient care, improve clinical outcomes, and streamline healthcare operations." />
        <meta name="keywords" content="Health Cloud, Healthcare IT, Patient Management, Managed IT Services, Deinertech Software, Healthcare Solutions" />
        <meta name="author" content="Deinertech Software Pvt Ltd" /> */}
        {/* <meta property="og:title" content="Health Cloud Solutions | Deinertech Software" />
        <meta property="og:description" content="Explore comprehensive Health Cloud solutions by Deinertech Software. Enhance patient care, improve clinical outcomes, and streamline healthcare operations." />
        <meta property="og:url" content="https://www.deinertech.com/Helaths" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Deinertech Software" /> */}
      </Helmet>
      <About></About>
      <div class="container-helthc">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Health Cloud</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Health Cloud</h6>
        </div>
      </div>
       <div class="Acoount-info">
       <h2 class="pos-h4">Elevate Your Business with Managed IT Services by Deinertech Software</h2>
       <h6 class="pos-h6">Deinertech Software offers comprehensive managed IT services designed to optimize your technology  <br />infrastructure, enhance operational efficiency, and drive business growth. Our tailored solutions cater to  <br />businesses of all sizes, providing proactive support, strategic guidance, and scalable IT solutions.</h6>
       <h1 class="pos-h4">Health Cloud</h1>
      </div>
        <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Patient-Centric Care Management</h4>
                <h6 class="elc-h6">Health Cloud revolutionizes healthcare delivery by offering patient-centric care management solutions. It centralizes patient data from disparate sources, including electronic
                  health records (EHRs), wearables, and IoT devices, providing healthcare providers with a holistic view of patient health. By prioritizing the patient experience, Health Cloud enables personalized
                  care coordination, proactive interventions, and improved health outcomes.</h6>
            </div>
            </div>

            <div class="elc">
              <div class="box-graphical">
                <h4 class="elc-h3">360-Degree Patient View</h4>
                <h6 class="elc-h6">With Health Cloud, healthcare providers gain access to a comprehensive 360-degree view of each patient's health profile.
                  This includes medical history, treatment plans, medications, lab results, and social determinants of health. By consolidating patient information
                  into a single platform, Health Cloud enhances clinical decision-making, fosters collaboration among care teams, and ensures continuity of care across healthcare settings.</h6>

              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'./Health-Cloud-768x768.webp'} alt="Health Image" />


          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Population Health Management</h4>
                <h6 class="elc-h6">Health Cloud empowers healthcare organizations to proactively manage population health and improve health outcomes at scale. It leverages data analytics
                  and predictive modeling to identify high-risk patients, target interventions, and prevent avoidable hospitalizations. By stratifying patient populations based on risk
                  factors and social determinants of health, Health Cloud enables targeted interventions, resource allocation, and population health initiatives</h6>


              </div>
            </div>




            <div class="elc">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Compliance and Security</h4>
                <h6 class="elc-h6">Compliance and security are paramount in healthcare, and Health Cloud is designed with industry-leading compliance and
                  security features. It adheres to healthcare regulations such as HIPAA and GDPR, ensuring patient data privacy and confidentiality.
                  Health Cloud provides robust data encryption, access controls, audit trails, and compliance reporting capabilities, safeguarding sensitive patient information and mitigating security risks.</h6>



              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="col-md-4">
          <div class="elc mb-4">
            <div class="box-acc">
              <h4 class="elc-h3">Care Coordination and Collaboration</h4>
              <h6 class="elc-h6">Health Cloud facilitates seamless care coordination and collaboration among healthcare providers,
                patients, and caregivers. It offers tools for appointment scheduling, task assignment, secure messaging, and care plan
                management. Healthcare teams can collaborate in real-time, share critical information, and track patient progress,
                leading to more efficient care delivery, reduced care gaps, and improved care quality</h6>

            </div>
          </div>
        </div>
      </div>
   

      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose2">
              <h2 class="">Why Choose Deinertech Software for Managed IT Services?</h2>
           

              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">Expertise: Benefit from our team's extensive experience and expertise in managing complex IT environments across various industries.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Cost-Effectiveness: Our managed IT services offer predictable pricing and cost savings compared to hiring an in-house IT team.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Scalability: Scale your IT resources and services based on your business needs and growth trajectory, ensuring agility and flexibility.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Compliance and Governance: Stay compliant with industry regulations and standards with our compliance monitoring and governance services.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-5-circle-fill number-icon"></i>
                  <h5 class="h5-hel"> Strategic Partnership: We collaborate closely with your team to understand your business goals and provide strategic IT guidance that aligns with your objectives.</h5>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
     

<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Experience Reliable and Proactive IT Support</h3>
                <h6 class="box-h6">Partner with Deinertech Software for managed IT services that empower your business to thrive in today's
                digital landscape. Contact us today to learn more about how we can support your IT needs and drive your business forward.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer></Footer>

    </>
  )
}
export default Helaths;