import React, { useState } from "react";
import About from "./Navbar/About";
import Footer from "./Footer/Footer";
import './helathcare.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Requstf from "./Requst/Requst";
function RealEs() {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
  
    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
  
  return (
    <>
      
      <Helmet>
        <title>Real Estate Deinertech Software</title>
        {/* <meta name="description" content="Explore comprehensive real estate solutions by Deinertech, designed to empower professionals, streamline processes, and enhance customer experiences." />
        <meta name="keywords" content="Real Estate, Property Management, CRM, Marketing, Listing Platforms, Virtual Tours, 3D Visualization" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Real Estate Solutions | Deinertech" />
          <meta property="og:description" content="Explore comprehensive real estate solutions by Deinertech, designed to empower professionals, streamline processes, and enhance customer experiences." />
          <meta property="og:url" content="https://www.deinertech.com/real-estate" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Deinertech" /> */}
      </Helmet>
      <About></About>
      <div class="container-reals">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Real Estate</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Real Estate</h6>
        </div>
      </div>




<div class="welcome">
        <div class="text-center Wel-come">
          <h2 className="heading-h2">Welcome To <span class="span">Deinertech Real Estate Solution</span></h2>
        </div>
        <div class="hel-th">
          <h5 class="helath-p mt-0">Deinertech, we understand the dynamic nature of the real estate industry. Our comprehensive solutions are
          designed to empower real estate professionals, streamline processes, and enhance customer experiences.</h5>
        </div>
      </div>

      <div class="our-solution">
        <div class="text-center mt-2 our-solutions">
          <h2 class="solution-h2 mb-0">Our Services</h2>
        </div>
      </div>



      {/* card */}

      <div class="container">
        <div class="row justify-content-center">


          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc">
                <h4 class="elc-h3">Property Management</h4>
                <h6 class="elc-h6"> Simplify your hotel operations with our
                  comprehensive hotel management system.
                  and guest services, our solution helps you deliver
                  exceptional guest experiences.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-elc">
                <h4 class="elc-h3">  CRM and Marketing	</h4>
                <h6 class="elc-h6"> Build stronger relationships with clients and
                  prospects using our CRM and marketing tools.
                  Stay organized, track leads, and run targeted
                  class campaigns to drive  sales and conversions.</h6>





              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={require('./image/left-img.png')} alt="Health Image" />

          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                  Listing Platforms</h4>
                <h6 class="elc-h6">

                  Showcase your properties to a wider audience with our  customizable
                  listing platforms. Our user-friendly interfaces and powerful search features help attract
                  qualified buyers and renters.
                </h6>

              </div>
            </div>

            <div class="elc">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                  Virtual Tours and 3D Visualization</h4>
                <h6 class="elc-h6">

                  Bring properties to life with immersive virtual tours
                  and 3D visualization. Enhance the buying experience for clients by allowing them to  explore
                  properties from anywhere, at any time.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose1">
              <h2 class="h2-choose">Why Choose Us</h2>
                <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel"> Industry Expertise: With a deep understanding of the real estate market, our team brings valuable insights and expertise to help you succeed.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Technology-driven Solutions: Leverage the latest technology trends, such as AI and automation, to streamline processes and stay ahead of the competition.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">
                  Customizable Solutions: Our solutions are flexible and customizable to meet the unique needs of your real estate business, whether you're a broker, developer, or property manager.</h5>
                </div>






              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="box-start1 shadow rounded">
              <div class="box-contant">
                <h3 class="box-h3">

                  Unlock Your Real Estate Potential with Deinertech
                </h3>
                <h6 class="box-h6">
                  Partner with Deinertech to unlock new opportunities and achieve your real estate goals.
                  Contact us today to learn more about our solutions and how we can help you succeed in the
                  ever-evolving real estate market.
                </h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer></Footer>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}




    </>

  )
}
export default RealEs;