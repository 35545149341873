import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './graphical.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Graphical() {
  return (
    <>
       <Helmet>
        <title>Graphic Design Deinertech Software</title>
        {/* <meta name="description" content="Enhance your brand with expert graphic design services from Deinertech Software. Our services include branding, marketing collateral, digital design solutions, and more to elevate your visual identity." />
        <meta name="keywords" content="Graphic Design, Branding, Marketing Collateral, Digital Design, Print Design, Illustration, Infographics, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Graphic Design - Deinertech Software" />
        <meta property="og:description" content="Enhance your brand with expert graphic design services from Deinertech Software. Our services include branding, marketing collateral, digital design solutions, and more to elevate your visual identity." />
        <meta property="og:url" content="https://www.deinertech.com/graphical" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>


      <div class="container-graphic">
        <div class="text-center mt-5">
          <h1 class="exper-h1">Graphic Design</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">Graphic Design</h6>
        </div>
      </div>

      <div class="Acoount-info">
        <h1 class="pos-h4 mb-4">Graphic Design</h1>
      
     <h6 class="dev-p1">Transform your brand's vision into captivating visuals with our expert Graphic Design services at Deinertech Software. We specialize in creating stunning designs that leave a lasting impact and elevate your brand identity.</h6>

      </div>


      <div class="our-solution">
        <div class="text-center mt-3 our-solutions">
          <h2 class="solution-h2">Our Creative Services</h2>
        </div>
      </div>


      <div class="container">
        <div class="row justify-content-center">


          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Branding and Identity Design</h4>
                <h6 class="elc-h6">Craft a unique brand identity that resonates with
                  your audience. From logos to color palettes and   mtypography, we'll create cohesive branding  elements that reflect your brand's personality</h6>
              </div>
            </div>

            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Marketing Collateral</h4>
                <h6 class="elc-h6">Make a statement with eye-catching Make marketing materials. Our team designs brochures, flyers, posters, and   banners that communicate your message effectively and  drive engagement.</h6>
              </div>
            </div>

            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Digital Design Solutions</h4>
                <h6 class="elc-h6">Enhance your online presence with user-friendly digital designs. We create intuitive
                  website interfaces, mobile app layouts, and email templates that captivate users.</h6>

              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/graphical.jpg'} alt="Health Image" />


          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Print Design Expertise</h4>
                <h6 class="elc-h6">Leave a lasting impression in the physical world. We excel in designing business cards,   letterheads, packaging, and merchandise that showcase your brand's professionalism.</h6>

              </div>
            </div>


            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Illustration and Visual Storytelling</h4>
                <h6 class="elc-h6">Bring concepts to life with custom illustrations and icons. Our artists specialize in creating compelling visuals that enhance brand.storytelling and convey ideas creatively</h6>
              </div>
            </div>

            <div class="elc mb-4">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Infographic Design for Impact</h4>
                <h6 class="elc-h6">Transform complex information into visually
                  engaging infographics. We simplify data and
                  statistics into digestible graphics that inform  and inspire.</h6>

              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose1">
              <h6 class="graphical-h2" style={{color:"blue"}}>OUR PROCESS</h6>
          
              <h2 class="graphical-h2">We believe in collaboration and transparency throughout the <br /> design journey:</h2>
             

              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">Discovery: We listen to your goals and ideas to understand your brand's essence.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Conceptualization: Our team brainstorm creative concepts tailored to your vision.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">
                    Design Iteration: We refine designs based on your feedback and input.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">
                    Finalization: We ensure the final deliverables meet the highest standards of quality and align with your brand guidelines.</h5>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
















    </>
  )
}
export default Graphical;