import React, { useState, useEffect } from "react";
import './footer.css';

function Footer() {


  const images = [
    require("../image/Figma.png"),
    require("../image/aws.png"),
    require("../image/GoDaddy.png"),
    require("../image/Canva.png"),
    require("../image/softwared.jpg"),
    require("../image/Google.png"),



  ];







  const [instaPosts, setInstaPosts] = useState([]);
  const token = "IGQWRNaDFpRHF2bWtkbFV0ZA1NiN2xzNnZAlTTZA4U0lpZA256em52eDlaV0ZATbXNsSkJkLXl4Y1pRMUkwN05uOU05NmRBcEdZASDVueVJkQU1TcEhhTVVRaVFmMjhuNkdnaXpKTVR3R1FySGdnZAwZDZD"; // Replace with your actual Instagram access token
  const maxPosts = 9; // Maximum number of Instagram posts to display

  // Fetch Instagram posts using Graph API
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const response = await fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&access_token=${token}`);
        const data = await response.json();
        setInstaPosts(data.data.slice(0, maxPosts)); // Limit to maxPosts
      } catch (error) {
        console.error("Error fetching Instagram posts:", error);
      }
    };
    
    fetchInstagramPosts();
  }, [token]);



  
  return (
    <>

      <footer className="container-deiberfooter mt-5">
      <div className="footer-slider">
                   
                   <div className="marquee">
                       <div className="marquee-inner">
                           {images.concat(images).map((image, index) => (
                               <img key={index} className="slider-img1" src={image} alt={`Slider ${index}`} />
                           ))}
                       </div>
                   </div>
                   </div>
          

        {/* <div className="clients">
          <div className="container2">
            <div className="slider-container1">
              <div className="slider" style={{ transform: `translateX(-${currentImageIndex * 3}%)` }}>
                {images.map((image, index) => (
                  <img
                    key={index}
                    className="slider-img1"
                    src={image}
                    alt={`Slider ${index}`}
                  />
                ))}
              </div>
            </div>
          </div> */}

          <div className="container-footer">

            <div className="row footer-text">
              <div className="col-md-3 col-12 mb-3 px-5 text-md-left">
                <img
                  className="footer-img"
                  src={'./DEINERTECH-NAVIGATION.gif'}
                  alt="Deinertech Logo"
                />
                <br />
                <br />
                <div className="location">
                  <h5><i className="bi bi-geo-alt-fill icon-gradient"></i> Pune</h5>
            
                </div>
                <address>
                  <h6 style={{fontWeight:"400n"}}>
                    Kalp Avenue, Bhumkar Chowk, Shankar <br />
                    Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra 411057
                  </h6>
                </address>
              </div>

              <div className="col-md-3 col-12 mb-3 text-md-left px-5">
                <h3 class="Quick">Quick Links</h3>
                <br />
                <a href="/privacy" target="_blank" rel="noopener noreferrer" className="privacy">
                  <p> <i className="bi bi-send-arrow-up"></i> Privacy Policy </p>
                </a>
                <a href="/returnp" target="_blank" rel="noopener noreferrer" className="privacy">
                  <p> <i className="bi bi-send-arrow-up"></i> Return Policy </p>
                </a>
                <a href="/Terms" target="_blank" rel="noopener noreferrer" className="privacy">
                  <p> <i className="bi bi-send-arrow-up"></i> Terms & Conditions</p>
                </a>
                <div className="mb-3 icon-group">
                  <a href="https://www.instagram.com/deinertechsoftware" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram inst-btn"></i></a>
                  <a href="https://youtube.com/@deinertechsoftware?si=1hfpHHzrs6OTSIM6" target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube youtube-btn"></i></a>
                  <a href="https://www.facebook.com/share/mDdMkDrWPC6era9G/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook facebook-btn"></i></a>
                  <a href="https://www.linkedin.com/company/deinertech-software-private-limited" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin linkdin-btn"></i></a>
                </div>
              </div>

              <div className="col-md-3 px-5">
                <h3 class="Quick">Official Info:</h3>
                <br />
                <p><i className="bi bi-envelope-at "></i> <a href="mailto:contact@deinertech.com">contact@deinertech.com</a></p>
                <p><i className="bi bi-telephone"></i><a href="tel:+919209298516" style={{textDecoration:"none"}}> +91 9209298516 </a></p>
               <p><i className="bi bi-telephone"></i><a href="tel:+918766833680" style={{textDecoration:"none"}}> +91 8766833680 </a> </p>
                <p style={{color:"white",fontWeight:"bold"}}><i className=""></i>GSTIN-27AAKCD0859P1ZZ</p>
                <h6 style={{color:"white",fontWeight:"bold"}}>Open Hours</h6>
                <p>Mon-Sat: 9:30 am - 7 pm<br /> Sunday: CLOSED</p>
              </div>


              <div className="col-md-3 col-12 mb-3 px-5">
                <div className="row row-cols-3 row-cols-md-3 g-3">
                  {instaPosts.map((post, index) => (
                    <div className="col" key={index}>
                      <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                        <img src={post.media_url} className="img-fluid insta-img" alt={`Instagram Post ${index + 1}`} />
                      </a>
                    </div>
                  ))}
                </div>

       
                <br /><br /><br />




                <div className="col-12 text-center">
                  <a href="https://www.instagram.com/deinertechsoftware" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary inst-btn-log">Instagram</button></a>
                    <a href="https://api.whatsapp.com/send/?phone=919209298516&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" class="whatsapp-link">
               <img src="./WhatsApp_icon-removebg-preview.png" class="whats-app" alt="WhatsApp" />
                  <span class="tooltip">How can I help you?</span>
                   </a>
                </div>
              </div>

              <h5 className="software">© 2024 <span className="reserved">Deinertech Software –</span> All rights reserved.</h5>
            </div>
          </div>
   
       

      </footer>


    </>
  );
}

export default Footer;

