import { React, useRef } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import ResponsiveForm from "../Form/Form";
import './Career.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";




function Carrer() {

  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Career Deinertech Software</title>
        <meta name="description" content="Career opportunities at Deinertech Software. Join our team and grow your career in digital marketing, web development, sales, and more." />
        <meta name="keywords" content="Deinertech Software, career, jobs, digital marketing, web development, UI/UX, content writing" />
        <meta name="author" content="Deinertech Software" />
        <meta property="og:title" content="Career Deinertech Software"/>
        <meta property="og:description" content="Explore exciting career opportunities at Deinertech Software. Join our dynamic team and advance your career in various fields." />
        <meta property="og:url" content="https://www.deinertech.com/career" />
        <meta property="og:type" content="website" />
      </Helmet>
      <About></About>
      <div class="container-carre w-100">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Career</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Career</h6>
        </div>
      </div>
      <div class="container mt-4">
        <div class="card carrer-card">
          <div class="row no-gutters">
            <div class="col-md-5">
              <div class="card-body">
                <h1 class="card-title carrer-title">We work together for success.</h1>
                <br />
                <p class="carrer-text">One of the key benefits of partnering with Remote IT Solutions is our security expertise.</p>
              </div>
            </div>
            <div class="col-md-6">
              <img src={'./h6-image-2.png'} class="card-img" alt="Placeholder Image" />
            </div>
          </div>
        </div>
      </div>
   


      <div class="container">
        <div class="row">
          <div class="col-md-12 centered-div">
            <h2 class="carrer-h2">Exciting Job Opportunity at Deinertech Software</h2>
            <p class="carrer-p">Ever wonder what it’s like to be a digital marketer? If you’re looking to actively seek out digital marketing jobs in Pune, check out the career opportunities in digital marketing at Deinertech Software. Get a hands-on experience in creating and managing online marketing campaigns with Deinertech Software. </p>
          
          </div>
        </div>
      </div>
      <div class="container mt-3 join-con">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Join Our Dynamic Team as a Content Writer!
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                   
                    <p className="carrer-p">Are you a talented writer with a passion for crafting compelling content? Do you enjoy creating blog posts,
                      guides, and marketing materials that inform and inspire? If so, Deinertech Software has an exciting opportunity for you!</p>
                  

                    <h3>The Role</h3>
                    <p className="carrer-p">You’ll be an integral part of our growing editorial team, shaping the voice of our brand and fueling our online presence. Your key responsibilities will include:</p>
                    <ul class="b">
                      <li class="carrer-li">Content Creation: Research, ideate, and write high-quality blog posts, guides, website copy, and other marketing materials that engage our target audience.</li>
                      <li class="carrer-li">Brand Voice: Collaborate in developing a consistent brand voice that reflects our company’s mission and values.</li>
                      <li class="carrer-li">Audience Engagement: Create content with the goal of connecting with our customers, addressing their pain points, and showcasing our solutions.</li>
                      <li class="carrer-li">SEO Integration: Learn and apply basic SEO principles to ensure our content attracts organic traffic.</li>
                    </ul>
                  <h3>Qualifications</h3>
                    <ul class="b">
                      <li class="carrer-li">1-5 years of proven experience with PHP and MySQL development.</li>
                      <li class="carrer-li" >Strong WordPress Expertise: Deep understanding of WordPress architecture, themes, plugins, and customization.</li>
                      <li class="carrer-li" >Front-end Proficiency: Solid skills in HTML, CSS, JavaScript (jQuery is a plus).</li>
                      <li class="carrer-li">Attention to Detail: A meticulous approach to coding, debugging, and delivering polished websites.</li>
                      <li class="carrer-li">Passion for Learning: Eagerness to stay updated with the evolving web development landscape.</li>
                    </ul>
                
                    <p className="carrer-p">Location: Pune, Maharashtra</p>
                    <p className="carrer-p">Positions Available: 1</p>
                    <p className="carrer-p">Job Type: Full-Time</p>
                    <div class="apply">
                      <button class="career-btn  carrer-btn " onClick={scrollToForm}>Apply for this job</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                    Join Our Dynamic Team as a Web Developer (PHP + WordPress) </button>
                </h2>

                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                   
                    <p className="carrer-p">Are you a PHP and WordPress enthusiast with a knack for building top-notch web solutions? Do you excel at transforming client requirements into functional and visually appealing
                      websites? Deinertech Software is seeking talented Web Developers like you to expand our team.</p>
                 


                    <h3 >The Role</h3>

                    <p className="carrer-p">You’ll play a key role in developing and maintaining client websites using the latest technologies and best practices. Your primary responsibilities include:</p>


                    <ul class="b">
                      <li class="carrer-li">WordPress Development: Design and build custom WordPress themes, plugins, and websites that exceed expectations.</li>
                      <li class="carrer-li"> Front-end Development: Create engaging user interfaces with HTML, CSS, JavaScript, jQuery, and Bootstrap.</li>
                      <li class="carrer-li" > Database Integration: Seamlessly connect web applications to MySQL databases for dynamic data-driven solutions.</li>
                      <li class="carrer-li">  Client Collaboration: Work with clients to understand their needs and effectively translate them into web solutions.</li>
                      <li class="carrer-li">Best Practices: Adhere to coding standards, implement SEO optimization and ensure cross-browser compatibility.</li>
                    </ul>
               


                    <h3>Qualifications</h3>
                    <ul class="b">


                      <li class="carrer-li">   1-5 years of proven experience with PHP and MySQL development.</li>
                      <li class="carrer-li">Strong WordPress Expertise: Deep understanding of WordPress architecture, themes, plugins, and customization.</li>
                      <li class="carrer-li">Front-end Proficiency: Solid skills in HTML, CSS, JavaScript (jQuery is a plus).</li>
                      <li class="carrer-li" >Attention to Detail: A meticulous approach to coding, debugging, and delivering polished websites.</li>
                      <li class="carrer-li">Passion for Learning: Eagerness to stay updated with the evolving web development landscape.</li>
                    </ul>
                  
                    <p className="carrer-p">Location: Pune, Maharashtra</p>
                    <p className="carrer-p">Positions Available: 1</p>
                    <p className="carrer-p">Job Type: Full-Time</p>
                    <div class="apply">
                      <button class="career-btn  carrer-btn" onClick={scrollToForm}>Apply for this job</button>

                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Join Our Dynamic Team as a Sales & Marketing Associate!
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                  
                    <p className="carrer-p">Do you have a knack for connecting with people and a passion for promoting innovative solutions? Are you ready to launch your career in the exciting world of sales and marketing?
                      Deinertech Software is seeking a driven individual to fuel our growth and make a real impact.</p>
              
                    <h3>The Role</h3>
                    <p className="carrer-p">As our Sales & Marketing Associate, you’ll be a key player in generating leads, building our brand, and driving sales success. Your day-to-day will be a dynamic mix of
                      outreach, marketing support, and behind-the-scenes work that keeps our efforts running smoothly.</p>
                  

                    <h3>Your Impact:</h3>
                    <ul class="b">
                      <li class="carrer-li">Lead Generation Dynamo: Find potential clients through research and creative outreach techniques.</li>
                      <li class="carrer-li">Marketing Collaborator: Help create eye-catching marketing materials, from social media posts to case studies.1</li>
                      <li class="carrer-li"> Event Organizer: Assist with planning and executing webinars, tradeshows, and other events that showcase our expertise.</li>
                      <li class="carrer-li"> Sales Support Superstar: Manage contact databases, schedule meetings, and provide top-notch support to our sales team.</li>
                    
                    </ul>


                    <h3>What We’re Looking For:</h3>

                    <ul class="b">
                      <li class="carrer-li"> Recent Graduate or 0-2 Years of Experience: A degree in Business, Marketing, Communications, or a related field is a major plus.</li>
                      <li class="carrer-li">Communication Guru: You’re a natural with both written and verbal communication.</li>
                      <li class="carrer-li">Organization Wizard: You juggle multiple projects seamlessly and have a keen eye for detail.</li>
                      <li class="carrer-li">Tech Curious: A genuine interest in technology and how it solves problems.</li>
                      <li class="carrer-li">Go-Getter Attitude: You’re proactive, adaptable, and always eager to learn.</li>

                    </ul>
                 
                    <p className="carrer-p">Location: Pune, Maharashtra</p>
                    <p className="carrer-p">Positions Available: 1</p>
                    <p className="carrer-p">Job Type: Full-Time</p>
                    <div class="apply">
                      <button class="career-btn  carrer-btn" onClick={scrollToForm}>Apply for this job</button>

                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                    UI/UX Developer

                  </button>
                </h2>
                <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                   
                    <p className="carrer-p">Deinertech Software is where innovation meets exceptional user experiences. If you have a passion for design and technology, we have exciting opportunities for you:</p>
                  

                    <h3>The Role</h3>
                    <p className="carrer-p">Are you a creative problem solver who thrives on transforming user needs into beautiful and functional interfaces?
                      Join our team and play a crucial role in shaping the future of our digital products.</p>
                 

                    <h3>Responsibilities</h3>
                    <ul class="b">
                      <li class="carrer-li">User-Focused Design: Conduct user research, create wireframes, mockups, and prototypes that deliver exceptional user experiences.</li>
                      <li class="carrer-li"> Frontend Expertise: Translate designs into clean, responsive code using HTML, CSS, JavaScript, and modern frameworks.</li>
                      <li class="carrer-li">Collaboration is Key: Partner with designers, developers, and product managers to bring projects to life.</li>
                      <li class="carrer-li">  Iterative Improvement: Participate in usability testing, analyze feedback, and continuously refine designs.</li>
                    
                    </ul>


                    <h3>Qualifications</h3>

                    <ul class="b">
                      <li class="carrer-li"> Experience: 1-3+ years of proven experience in UI/UX design and development.</li>
                      <li class="carrer-li">Portfolio Power: A strong portfolio showcasing your design thinking and frontend development skills.</li>
                      <li class="carrer-li">Technical Toolkit: Proficiency in HTML, CSS, JavaScript, and at least one modern frontend framework (React, Angular, Vue.js, etc.).</li>
                      <li class="carrer-li">Design Fluency: Expertise in design tools like Figma, Sketch, Adobe XD, or similar.</li>

                    </ul>
                 
                    <p className="carrer-p">Location: Pune, Maharashtra</p>
                    <p className="carrer-p">Positions Available: 1</p>
                    <p className="carrer-p">Job Type: Full-Time</p>
                    <div class="apply">
                      <button class="career-btn  carrer-btn" onClick={scrollToForm}>Apply for this job</button>

                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3" ref={formRef}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="accordion" id="accordionExample">
              {/* Your accordion items */}
            </div>
          </div>
        </div>
      </div>

      <ResponsiveForm></ResponsiveForm>

   
      <Footer></Footer>







    </>
  )
}
export default Carrer;