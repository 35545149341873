import Requstf from "../pages/Requst/Requst";
import React, { useState } from "react";
import About from "../pages/Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer/Footer";
import { Helmet } from "react-helmet";


function Saless(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  
    return(
       <>
      <Helmet>
           <title>Service Cloud Deinertech Software</title>
           {/* <meta name="description" content="Explore Deinertech Software's Service Cloud offerings, including omni-channel support, efficient case management, and AI-powered insights for enhanced customer service." />
           <meta name="keywords" content="Service Cloud, Help Desk Services, Omni-Channel Support, Case Management, AI-Powered Insights, Deinertech Software" />
           <meta name="author" content="Deinertech Software Pvt Ltd" />

           <meta property="og:title" content="Service Cloud - Deinertech Software" />
        <meta property="og:description" content="Explore Deinertech Software's Service Cloud offerings, including omni-channel support, efficient case management, and AI-powered insights for enhanced customer service." />
       <meta property="og:url" content="https://www.deinertech.com/Saless" 
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Deinertech Software" /> */}
       </Helmet>
       

       <About></About>
       <div class="container-salesc">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Service Cloud</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Service Cloud</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
   
            <h2 class="conantctm-h2 ">Efficient Help Desk Services by Deinertech Software</h2>
        
          <h6 class="dev-p1 mt-0">At Deinertech Software, we understand the importance of a responsive and efficient help desk in ensuring smooth IT operations for businesses. Our help desk services are designed to provide timely assistance and  resolution to IT issues, empowering you to focus on your core business activities.</h6>
           

             <h1 class="pos-h4">Service Cloud</h1>
         </div>

          
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Omni-Channel Support</h4>
            <h6 class="elc-h6">Service Cloud offers a comprehensive omni-channel support system, allowing customers 
                to interact with support agents across various channels like phone, email, chat, social media, and self-service portals.
                 This versatility ensures that customers
                 can reach out through their preferred channels, enhancing accessibility and satisfaction. 
                 Agents can seamlessly switch between channels while maintaining context, providing a seamless and personalized support experience.</h6>
                
          </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Efficient Case Management</h4>
            <h6 class="elc-h6">With Service Cloud's robust case management capabilities, support agents can efficiently track,
                 prioritize, and resolve customer inquiries and issues. Cases are automatically assigned and
                  routed based on predefined rules, ensuring timely resolution and customer satisfaction. Agents can easily 
                  collaborate and escalate cases when necessary, streamlining the resolution process and minimizing customer wait times</h6> 

            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/Service-Cloud-768x768.webp'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Optimized Service Console</h4>
            <h6 class="elc-h6">The Service Console serves as a centralized workspace for support agents to manage cases, 
                view customer information, and collaborate with team members. Agents can customize the
                 console layout to prioritize tasks and automate repetitive tasks, boosting productivity and 
                 efficiency. With a user-friendly interface and intuitive navigation, the Service Console empowers 
                 agents to focus on resolving customer issues effectively, driving satisfaction and loyalty</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">AI-Powered Insights and Recommendations</h4>
            <h6 class="elc-h6">Leveraging advanced artificial intelligence (AI) capabilities, Service Cloud provides intelligent insights and 
                recommendations to support agents in their daily activities. AI-driven features such as sentiment analysis, case classification, 
                and suggested solutions enable agents to resolve issues faster and more accurately. By harnessing AI-powered insights.</h6> 
       


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-acc">
            <h4 class="elc-h3">Knowledge Base Empowerment</h4>
            <h6 class="elc-h6">Service Cloud includes a powerful knowledge base where organizations can store and manage a wealth of resources such as articles, 
                FAQs, and troubleshooting guides. This knowledge base empowers agents with quick access to relevant information,
                 enabling them to provide accurate and consistent answers to customer queries. By leveraging the knowledge base, organizations can enhance agent efficiency, reduce resolution times, and improve overall service quality.</h6>

         </div>
        </div>
        </div>
        </div>
     <br/>

     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Deinertech Software’s Help Desk Services?</h2>
          <br/>

          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Rapid Response: Our help desk team is known for its quick response times, ensuring that IT issues are addressed promptly to keep your business running smoothly.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Personalized Support: We understand the unique needs of your business and provide personalized support tailored to your specific requirements.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Continuous Improvement: We continually monitor and evaluate our help desk processes to enhance efficiency and deliver an exceptional customer experience.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">Scalability: Whether you're a small business or a large enterprise, our help desk services are scalable to accommodate your growing IT support needs.</h5>
             </div>
          </div>
               
        </div>
      </div>
   </div>
  </div>
  <br/>
  <br/>

  

<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Experience Seamless IT Support</h3>
                <h6 class="box-h6">Partner with Deinertech Software for reliable and efficient help desk services that prioritize your business's IT needs. Contact us today to learn more about how we can support your organization's success.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
<Footer></Footer>

         
       </>
    )
}
export default  Saless;