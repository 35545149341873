import Requstf from "../Requst/Requst";
import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Whatsapp() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }

  return (
    <>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>WhatsApp Marketing Deinertech Software</title>
        {/* <meta name="description" content="Boost engagement and drive conversions with Deinertech Software's tailored WhatsApp marketing solutions. Leverage WhatsApp to reach your target audience and achieve your marketing goals." />
        <meta name="keywords" content="WhatsApp Marketing, Deinertech Software, personalized messaging, promotional campaigns, customer support, lead generation, marketing solutions" />
        <meta name="author" content="Deinertech Software" />

     
        <meta property="og:title" content="WhatsApp Marketing - Deinertech Software" />
        <meta property="og:description" content="Boost engagement and drive conversions with Deinertech Software's tailored WhatsApp marketing solutions. Leverage WhatsApp to reach your target audience and achieve your marketing goals." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.deinertech.com/Whatsapp" />
        <meta property="og:site_name" content="Deinertech Software" /> */}
      </Helmet>

      <About/>
      <div class="container-Whatsapp">
        <div class="text-center mt-5">
          <h1 class="helth-h1">WhatsApp Marketing</h1>
          <h6 class="Helath-h">
            <Link to="/" className="dropdown-item">Home <i class="bi bi-chevron-double-right"></i></Link>
          </h6>
          <h6 class="Helath-h">WhatsApp Marketing</h6>
        </div>
      </div>

      {/* WhatsApp Marketing Section */}
      <div class="Acoount-info">
        <h3 class="conantctm-h2 mb-4">Boost Engagement And Drive Conversions With Deinertech Software’s WhatsApp Marketing Solutions</h3>
      
        <h6 class="dev-p1">WhatsApp has become a ubiquitous communication tool, with billions of users worldwide. Leveraging WhatsApp for marketing purposes can be highly effective in reaching and engaging your target audience. At Deinertech Software, we offer tailored WhatsApp marketing solutions to help businesses boost engagement, drive conversions, and achieve their marketing goals.</h6>
       
        <h2 class="pos-h4">Our WhatsApp Marketing Approach</h2>
      </div>

      {/* WhatsApp Marketing Details */}
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Personalized Messaging</h4>
                <h6 class="elc-h6">We believe in the power of personalized communication. Our WhatsApp marketing strategies include crafting personalized messages that resonate with your audience, addressing their specific needs and preferences.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-graphical">
                <h4 class="elc-h3">Promotional Campaigns</h4>
                <h6 class="elc-h6">Launch targeted promotional campaigns on WhatsApp to showcase your products or services, share special offers, and drive sales. We create compelling content and visuals that capture attention and encourage action.</h6>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/whatsapp03.png'} alt="Health Image" />
          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Customer Support</h4>
                <h6 class="elc-h6">Provide exceptional customer support through WhatsApp. We enable businesses to engage with customers in real-time, address inquiries, resolve issues promptly, and build strong relationships that foster loyalty.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Lead Generation</h4>
                <h6 class="elc-h6">Use WhatsApp to generate leads and nurture prospects. We implement lead capture mechanisms within WhatsApp chats, collect contact information, and follow up with personalized messages to move leads through the sales funnel.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Sections */}
      <div class="container">
        <div class="col-md-4">
          <div class="elc mb-4">
            <div class="box-social">
              <h4 class="elc-h3">Analytics and insights</h4>
              <h6 class="elc-h6">Gain valuable insights into campaign performance with WhatsApp analytics. Track metrics such as message open rates, response rates, click-through rates, and conversions to measure the effectiveness of your WhatsApp marketing efforts.</h6>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose2">
              <h2 class="">Why Choose Deinertech Software for WhatsApp Marketing?</h2>
              <br />
              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Expertise: Our team has extensive experience in leveraging WhatsApp for marketing purposes, helping businesses achieve tangible results.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Compliance: We ensure compliance with WhatsApp's policies and guidelines to deliver a seamless and positive user experience.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Creative Content: We create engaging content, visuals, and interactive experiences that drive engagement and conversions on WhatsApp.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Measurement and Optimization: We track key metrics, analyze performance, and optimize campaigns for continuous improvement and maximum ROI.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-10 col-sm-12 mx-auto d-block">
        <div class="box-start2">
          <div class="box-contant">
            <h3 class="box-h3">Get Started with WhatsApp Marketing from Deinertech Software</h3>
            <h6 class="box-h6">Ready to harness the power of WhatsApp for your marketing efforts? Partner with Deinertech Software and let us help you boost engagement, drive conversions, and achieve your marketing objectives through WhatsApp. Contact us today to discuss your WhatsApp marketing needs and start seeing results.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
          </div>
        </div>
      </div>
    </div>
  </div>


      

      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer />
    </>
  )
}

export default Whatsapp;
