import React from "react";
import './Erpsoluation.css';
import { Link } from "react-router-dom";

function Erpsoluation() {
  
      

    return (
        <div>
            <div className="erp">
                <h1 className="soluation">ERP Solutions We<span className="delivery"> Deliver </span></h1>
            </div>
            <br />
        

<div class="container cards-custm ">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Hospital-Management-System" className="dome-link">
            <div class="card exper-card1 shadow rounded ">
              <div className="img-card-erp">
              <img class="card-img-top mt-3" src={'./hospitalcard.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">Hospital Management System</h5>
                 </div>
             </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/School-Management-System" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp ">
              <img class="card-img-top mt-3" src={'./SCHOOLcard.png'} />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">School Management System</h5>
              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/event-management-system" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp ">
              <img class="card-img-top  mt-3" src={'./EVENTcard.png'} />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">Event Management System</h5>
             
             </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/hr-software" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top  mt-3" src={'./hrcard.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">HR Management System</h5>
            </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
     
     
      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/payroll-software" className="dome-link">
            <div class="card exper-card1 shadow rounded">
              <div className="img-card-erp">
              <img class="card-img-top  mt-3" src={'./payrollcard.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">Payroll Software</h5>
                 </div>
             </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Accounting-software" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top mt-3  " src={'./account (1)cart.png'} />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">Accounting Software</h5>
              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/inventory-software" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top mt-3" src={'./inventorycart.png'} />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">Inventory Software</h5>
             
             </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/pos-software" className="dome-link">
            <div class="card exper-card1 shadow rounded">
            <div className="img-card-erp">
              <img class="card-img-top mt-3" src={'./poscard.png'} alt="Healthcare Icon" />
              </div>
              <div class="card-body">
                <h5 class="Blog-D">Pos Software</h5>
            </div>
            </div>
            </Link>
          </div>
        </div>
      </div>

      
        </div>









    );
}

export default Erpsoluation;
