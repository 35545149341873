import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';  
import About from "./Navbar/About";
import Delivering from "./Home/Delivering";
import Solution from "../Solution/Soluation";
import Services from "./Services/Services";
import Revenu from "./Revenu";
import Erpsoluation from "./ErpSoluation/ErpSoluation";
import Slider from "./Slider";
import Domain from "./Domain/Domain";
import ImageSlider from "./Imageslider";
import Working from "./Workingp/Workingp";
import Flipcard from "./Flipcard/Flipcard";
import ImageWithHoverContent from "./Googler/Googler";
import Footer from "./Footer/Footer";
import ElfsightWidget from "./ElfsightWidget/ElfsightWidget";
import EnquireForm from './EnquireForm/EnquireForm';
import Header from './Heaerd/Headerd';
import './Deinertech.css'; 
import CustomBannerSlideshow from './Bannerslideshow';
import Servicesc from './Servicesc/Servicesc';
import Ouverclites from './Ouverclites/Ouverclites';




function Deinertech() {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        
        // Set the src attribute to the Tidio script URL
        script.src = "//code.tidio.co/ehkwxhbwkb6i3v9zprsjtcpcb9891032.js";
        
        // Ensure the script is loaded asynchronously
        script.async = true;
        
        // Append the script to the document body
        document.body.appendChild(script);
        
        // Cleanup function to remove the script when the component unmounts
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true,
        });

        // Check local storage to determine if form should be shown
        const formShown = localStorage.getItem('formShown');
        if (!formShown) {
            setShowForm(true); // Show the form if not shown before
        }
    }, []);

    const handleFormSubmit = (formData) => {
        console.log('Form submitted:', formData);
        // Handle form submission, e.g., send data to the server
        localStorage.setItem('formShown', 'true'); // Mark the form as shown
        setShowForm(false); // Hide the form after submission
    };

    return (
        <>
            {/* Add SEO using Helmet */}
            <Helmet>
                <title>Home Deinertech Software</title>
                <meta name="description" content="DeinerTech offers innovative digital solutions tailored to your business needs. From ERP solutions to web development, we deliver excellence." />
                <meta name="keywords" content="DeinerTech, digital solutions, ERP, web development, software services, IT solutions" />
                
                {/* Additional Meta Tags for Home - Deinertech Software */}
                <meta property="og:url" content="https://deinertech.com" />
                <meta property="og:title" content="Achieve More with Our Expert IT Services" />
                <meta property="og:description" content="Deinertech Software, we understand that every business faces unique challenges and has specific goals. That’s why we offer a comprehensive suite of affordable, high-quality IT solutions tailored to meet your distinct needs.
                 Our integrated approach combines a variety of digital marketing strategies and ERP solutions, empowering you to effectively reach and engage your customers across multiple channels.
                 Whether you're looking to enhance your online presence, streamline your operations, or drive business growth, our expert team is here to support you every step of the way." />
                 <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home-Deinertech Software" />
                <meta name="twitter:description" content="Discover how Deinertech Software can transform your digital presence with innovative solutions." />
         
            </Helmet>

          
            
            <Header className="margin-bottom" />
            <About className="margin-bottom" />
             <CustomBannerSlideshow></CustomBannerSlideshow>
        
            <div className="page-padding">
                <div data-aos="fade-up" className="margin-bottom"><Solution /></div>
                <div data-aos="fade-up" className="margin-bottom"><Services /></div>
                <div data-aos="fade-up" className="margin-bottom"><Flipcard /></div>
                <div data-aos="fade-up" className="margin-bottom"><Revenu /></div>
                <div data-aos="fade-up" className="margin-bottom"><Erpsoluation /></div>
                <div data-aos="fade-up" className="margin-bottom"><Domain /></div>
                <div data-aos="fade-up" className="margin-bottom"><Working /></div>
                <div data-aos="fade-up" className="margin-bottom"><Ouverclites/></div>
                <ElfsightWidget className="margin-bottom" />
                <div data-aos="fade-up" className="margin-bottom"><ImageWithHoverContent /></div>
            </div>
           <Footer/>
        </>
    );
}

export default Deinertech;
