import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './Form.css';

const ResponsiveForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    file: null,
    skills: '',
    gender: '',
    job: '',
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, file });
  };

  const validateForm = () => {
    const { name, email, phone, address, skills, gender, job, file } = formData;

    // Check if all required fields are filled
    if (!name || !email || !phone || !address || !skills || !gender || !job || !file) {
      return 'Please fill all the required fields.';
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address.';
    }

    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Perform validation
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      setSuccessMessage(''); // Clear success message on validation error
      return;
    }

    // Set a temporary success message immediately after validation passes
    setSuccessMessage('Your application has been sent successfully!');
    setError(''); // Clear any previous errors

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('file', formData.file);
    formDataToSend.append('skills', formData.skills);
    formDataToSend.append('gender', formData.gender);
    formDataToSend.append('job', formData.job);

    try {
      const response = await fetch('https://deinertech-backend.onrender.com/send', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear form fields after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: '',
        file: null,
        skills: '',
        gender: '',
        job: '',
      });

    } catch (error) {
      console.error('Email sending failed:', error);
      setError('Failed to send your application. Please try again.');
      setSuccessMessage(''); // Clear success message on error
    }
  };

  return (
    <Container className="centered-container1">
      <div className="form-container">
        <h1 className="my-4 carrer-h1">Get <span className="connected">connected</span> with us!</h1>
        <h5 className="my-4 carrer-h5">Give you update all times.</h5>
        {error && <div className="alert alert-danger">{error}</div>}
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Form.Group controlId="formPhone">
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formAddress">
                <Form.Control
                  type="text"
                  placeholder="Enter current address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Form.Group controlId="formGender">
                <div>
                  <Form.Check
                    inline
                    label="Male"
                    type="radio"
                    name="gender"
                    value="male"
                    checked={formData.gender === 'male'}
                    onChange={handleChange}
                    required
                  />
                  <Form.Check
                    inline
                    label="Female"
                    type="radio"
                    name="gender"
                    value="female"
                    checked={formData.gender === 'female'}
                    onChange={handleChange}
                    required
                  />
                  <Form.Check
                    inline
                    label="Other"
                    type="radio"
                    name="gender"
                    value="other"
                    checked={formData.gender === 'other'}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formJob">
                <Form.Control
                  type="text"
                  placeholder="Enter job position"
                  name="job"
                  value={formData.job}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <Form.Group controlId="formFile">
                <Form.Label>Upload File</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formSkills">
                <Form.Control
                  as="textarea"
                  placeholder="Describe your skill..."
                  rows={3}
                  name="skills"
                  value={formData.skills}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button className="form-btn" variant="primary" type="submit">
            Send
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ResponsiveForm;
