import React, { useState, useEffect } from 'react';
import './slider.css';

function Slider() {
    const images = [
        require('./image/html-5-1 1.png'),
        require('./image/ReactJS.png'),
        require('./image/css 1.png'),
        require('./image/nextjs 1.png'),
        require('./image/js 1.png')
     
        

    ];

    const images1 = [
        require('./image/kotlin-1.png'),
        require('./image/swift-1 1.png'),
        require('./image/ReactJS 1.png'),
        require('./image/Sliderimg.png'),
        require('./image/javafree.png'),
    
       
    
      
        
  
        
    ];

    const images2 = [
        require('./image/php-3 1.png'),
        require('./image/nodejs-1 1.png'),
        require('./image/python-1.png'),
        require('./image/javafree 1.png'),
        require('./image/wordpress-1.png')
  
    ];

    const images3 = [
        require('./image/cpanel-1.png'),
        require('./image/google-1 1.png'),
        require('./image/azure-1 1.png'),
        require('./image/Sliderimg1.png'),
        require('./image/cpanel-1.png'),
        
    
     
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImage1Index, setCurrentImage1Index] = useState(0);
    const [currentImage2Index, setCurrentImage2Index] = useState(0);
    const [currentImage3Index, setCurrentImage3Index] = useState(0);

    const imagesPerSlide =3;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % Math.ceil(images.length / imagesPerSlide));
        }, 3100);
        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage1Index(prevIndex => (prevIndex + 1) % Math.ceil(images1.length / imagesPerSlide));
        },3000);
        return () => clearInterval(interval);
    }, [images1.length]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage2Index(prevIndex => (prevIndex + 1) % Math.ceil(images2.length / imagesPerSlide));
        }, 2000);
        return () => clearInterval(interval);
    }, [images2.length]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage3Index(prevIndex => (prevIndex + 1) % Math.ceil(images3.length / imagesPerSlide));
        }, 4000);
        return () => clearInterval(interval);
    }, [images3.length]);

    return (
        <div className="slider-wrapper">
            <div className="slider-title">
                <h1 className="services">
                    Technologies and <span className="delivery-slider"> platforms </span> we work
                </h1>
            </div>
            <br/>
            <br/>

            <div className="container">
                <div className="row">
                    <div className="col-md-4 slider-column">
                        <div className="slider-title-content">
                            <h2 className="image-title">Web <span className="platform">Front-End <i className="bi bi-arrow-right"></i></span></h2>
                        </div>
                    </div>
                    <div className="col-md-8 slider-column">
                        <div className="slider-content">
                            <div className="slider-container slider-container-right">
                                <div className="slider" style={{ transform: `translateX(-${currentImageIndex * (80 / imagesPerSlide)}%)` }}>
                                    {images.map((image, index) => (
                                        <img key={index} className="slider-image" src={image} alt={`Slider ${index}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
         
                    <div className="col-md-4 slider-column">
                        <div className="slider-title-content">
                            <h2 className="image-title">Web <span className="platform">Back-End <i className="bi bi-arrow-right"></i></span></h2>
                        </div>
                    </div>
                    <div className="col-md-8 slider-column">
                        <div className="slider-content">
                            <div className="slider-container slider-container-right">
                                <div className="slider" style={{ transform: `translateX(-${currentImage2Index * (80 / imagesPerSlide)}%)` }}>
                                    {images2.map((image, index) => (
                                        <img key={index} className="slider-image" src={image} alt={`Slider ${index}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 slider-column">
                        <div className="slider-title-content">
                            <h2 className="image-title">Mobile <span className="platform">Development <i className="bi bi-arrow-right"></i></span></h2>
                        </div>
                    </div>
                    <div className="col-md-8 slider-column">
                        <div className="slider-content">
                            <div className="slider-container slider-container-left">
                                <div className="slider" style={{ transform: `translateX(${currentImage1Index * (80 / imagesPerSlide)}%)` }}>
                                    {images1.map((image, index) => (
                                        <img key={index} className="slider-image" src={image} alt={`Slider ${index}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 slider-column">
                        <div className="slider-title-content">
                            <h2 className="image-title">Cloud <span className="platform">Services <i className="bi bi-arrow-right"></i></span></h2>
                        </div>
                    </div>
                    <div className="col-md-8 slider-column">
                        <div className="slider-content">
                            <div className="slider-container slider-container-left">
                                <div className="slider" style={{ transform: `translateX(${currentImage3Index * (80 / imagesPerSlide)}%)` }}>
                                    {images3.map((image, index) => (
                                        <img key={index} className="slider-image" src={image} alt={`Slider ${index}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slider;
