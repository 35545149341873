import React, { useState } from "react";
import About from "./Navbar/About";
import Footer from "./Footer/Footer";
import Requstf from "./Requst/Requst";
import './helathcare.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Advaer() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (
    <>
 <Helmet>
        <title>Advertisement Services Deinertech</title>
        {/* <meta name="description" content="Discover the power of DeineTech's Advertising Services, offering innovative digital marketing, creative campaigns, brand strategy, and media planning to drive business success." />
        <meta name="keywords" content="Advertisement, Digital Marketing, Creative Campaigns, Brand Strategy, Media Planning, Deinertech" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="" />
       <meta property="og:description" content="Discover the working process of Deinertech. Learn how we work for our customers through discovery, planning, execution, and delivery." />
        <meta property="og:url" content="https://www.deinertech.com/advertisement" /> 
                <meta property="og:type" content="website" /> */}
      </Helmet>

      <About></About>



      <div class="container-Advertisement">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Advertisement</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Advertisement</h6>
        </div>
      </div>



      <div class="welcome">
        <div class="text-center  Wel-come">
          <h1 className="heading-h2">Welcome To <span class="span">Deinertech Advertising Services</span></h1>
        </div>
        <div class=" hel-th">
          <h5 class="helath-p mt-0">Deinertech, we specialize in delivering results-driven advertising solutions to help businesses thrive in today’s competitive market. Our strategic approach, innovative techniques, and creative prowess make us the go-to partner for your advertising needs.</h5>
        </div>
      </div>

      <div class="our-solution">
        <div class="text-center mt-2 our-solutions">
          <h2 class="solution-h2 mb-0">Our Services</h2>
        </div>
      </div>



      {/* card */}

      <div class="container">
        <div class="row justify-content-center">


          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc">
                <h4 class="elc-h3"> Digital Marketing</h4>
                <h6 class="elc-h6">Leverage the power of digital channels to reach your
                 target audience effectively. Our digital marketing
               services include SEO, PPC, social media  marketing
                 marketing, email marketing, and more.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-elc">
                <h4 class="elc-h3"> Creative Campaigns</h4>
                <h6 class="elc-h6">	  Stand out from the crowd with compelling and
                 engaging ad campaigns. From eye-catching  visuals
                to persuasive copywriting, we create campaigns  
                 that resonate with your audience  and drive results.	</h6>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={require('./image/digital-marketing.png')} alt="Health Image" />

          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-elc right-border">
                <h4 class="elc-h3">Brand Strategy</h4>
               
                <h6 class="elc-h6">  Build a strong brand presence with our strategic brand	
                  evelopment services. We define  your  brand  identity,
                 experience across all touchpoints.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-elc right-border">
                <h4 class="elc-h3">
                Media Planning and Buying  </h4>
                <h6 class="elc-h6">
                Maximize your ad spend with our data-driven media
                planning and buying services. We  intelligence
                 solutions. Gain a
                  deeper understanding identify the 
                most relevant channels, negotiate optimal rates, and ensure your ads reach the right audience atthe  right time.</h6>

              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose1">
              <h2 class="h2-choose">Why Choose Us</h2>

     
              <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel"> Industry Expertise: With a deep understanding of the banking and finance sector, our team of experts brings industry-specific knowledge and insights to every project.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Innovative Solutions: Stay ahead of the competition with our cutting-edge technology solutions tailored to meet the unique challenges and opportunities of the financial services industry.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Customer-Centric Approach: We prioritize customer satisfaction and strive to deliver solutions that enhance the overall banking experience for your customers, driving loyalty and retention.</h5>
             </div>

       
       

     
        
        </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="box-start1 shadow rounded">
              <div class="box-contant">
                <h3 class="box-h3">Get Noticed with Deinertech </h3>
                <h6 class="box-h6">
                Ready to take your advertising to the next level? Partner with Deinertech and let's create impactful 
                campaigns that drive growth and success for your business.
                 Contact us today to discuss your advertising needs and get started on your journey to success.
                </h6>   
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>                                                  
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

<Footer></Footer>


    </>

  )
}
export default  Advaer;