import React from 'react';
import { Helmet } from 'react-helmet';
import './Working.css';

function Working() {
    return (
        <>



            <div>
                <div className="erp">
                    <h3 className="process-h6">Working Process</h3>
                    <h2 className="process-heading">Our Working Process How We <br />Work For Our Customers</h2>
                </div>

                <div class="container cards-custm ">
                    <div class="row">

                        <div class="col-lg-3 col-md-4 col-sm-6">

                            <div class="card exper-card1 shadow rounded ">
                                <div className="img-card-erp">
                                    <img src={'./Untitled design (12).png'} alt="Discovery" />
                                </div>
                                <div class="card-body">
                                    <h4 className="Blog-D">Discovery</h4>
                                    <p className="Blogd-p">Begin Your digital journey with Deinertech by uncovering your unique needs and aspirations through strategic discussions and attentive listening</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6">

                            <div class="card exper-card1 shadow rounded">
                                <div className="img-card-erp ">
                                    <img src={'./Untitled design (13).png'} alt="Planning" />
                                </div>
                                <div class="card-body">
                                    <h4 className="Blog-D">Planning</h4>
                                    <p className="Blogd-p">When We receive your message. Our support teCraft a comprehensive project plan tailored to your goals, maximizing effective while minimizing costs</p>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="card exper-card1 shadow rounded">
                                <div className="img-card-erp ">
                                    <img src={'./Untitled design (14).png'} alt="Execute" />
                                </div>
                                <div class="card-body">
                                    <h4 className="Blog-D">Execute</h4>
                                    <p className="Blogd-p">With precision and expertise, our team brings your vision to life, leveraging cutting-edge technology and best practices to deliver results.</p>


                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6">

                            <div class="card exper-card1 shadow rounded">
                                <div className="img-card-erp">
                                    <img src={'./Untitled design (12).png'} alt="Discovery" />
                                </div>
                                <div class="card-body">
                                    <h4 className="Blog-D">Deliver</h4>
                                    <p className="Blogd-p">Meticulously review and refine each component, ensuring every deliverable meets and surpasses your expectations, propelling business to new success.</p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Working;
