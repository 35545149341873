import React, { useState, useEffect } from 'react';
import Requstf from './Requst/Requst';
import './Banners.css';

const slides = [
  { type: 'image', src: 'banner (2).mp4', title: 'Delivering Excellence With Digital Ideas' },
  { type: 'video', src: '1.mp4', textLine1: 'Professional Web Development Company in Pune', textLine2: 'Web Solutions for Business Growth', paragraph: 'Expert web development services tailored to your business needs.' },
  { type: 'video', src: '2.mp4', textLine1: 'Trusted ERP Solutions Provider in Pune', textLine2: 'ERP Solutions for Business Growth', paragraph: 'Scale your business with ERP solutions.' },

  { type: 'video', src: '4.mp4', textLine1: 'Best Digital Marketing Company in Pune', textLine2: ' Digital Marketing for Business Growth', paragraph: 'Boost your online presence and drive business growth.' },
];

const CustomBannerSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  const handleEnquiryClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="custom-banner-slideshow position-relative">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`custom-slide ${index === currentSlide ? 'custom-slide-active' : ''}`}
        >
          <div className="position-relative video-contanier ">
            <video
              style={{ width: '100%',}}
              className="banner-imge"
              src={slide.src}
              autoPlay
              muted
              loop

            />
            {index === 0 && slide.title && (
              <div className="title-overlay">
                <h1 className="slide-title">{slide.title}</h1>
              </div>
            )}
            <div className="text-overlay">
              <p className="slide-paragraph">{slide.textLine1}</p>
              <h2 className="slide-text-line2">{slide.textLine2}</h2>
              <p className="slide-paragraph">{slide.paragraph}</p>
            </div>
            {index !== 0 && ( // Show button only for slides other than the first one
              <div className="button-overlay">
                <button
                  className="Banner-button btn btn-warning"
                  onClick={handleEnquiryClick}
                >
                  Book a Free Strategy Call
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="custom-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`custom-dot ${index === currentSlide ? 'custom-dot-active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
      {showForm && <Requstf onClose={handleCloseForm} />}
    </div>
  );
};

export default CustomBannerSlideshow;
