import React from "react";
import About from '../Navbar/About';
import { Link } from "react-router-dom";
import './deinererp.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";


function Deinererp() {
  return (
    <>
     <Helmet>
        <title>ERP Solution Deinertech Software</title>
        <meta name="description" content="Explore ERP software solutions in Pune with Deinertech Software. Our comprehensive ERP platform seamlessly manages all aspects of your business, from production to accounting." />
        <meta name="keywords" content="ERP Solution, ERP Software, Business Management, Inventory Management, Accounting Software, Hospital Management, University Management, Payroll Software" />
       
        <meta property="og:title" content="ERP Solution Deinertech Software" />
         <meta property="og:description" content="Explore ERP software solutions in Pune with Deinertech Software. Our comprehensive ERP platform seamlessly manages all aspects of your business, from production to accounting." />
         <meta property="og:url" content="https://www.deinertech.com/erp-solution" />
        <meta property="og:type" content="website" />
       <meta property="og:site_name" content="Deinertech Software" />
      </Helmet>
      <About></About>


      <div class="container-Expertise">
        <div class="text-center mt-5">
          <h1 class="exper-h1">ERP Solution</h1>
          <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="exper-h">ERP Solution</h6>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-1 custom-padding ">
            <div class="content">
            <h2 class="ourservices-h2">ERP Software  Solutions In Pune.</h2>
               <p class="ourcomay-p">Discover how Deinertech Software can transform your business operations with our comprehensive ERP solution. Our platform seamlessly manages all aspects of your business, including production, sales, inventory management, and accounting. Gain
                detailed insights through business quotes and financial reports, enabling informed decision-making and driving business growth.
                Benefit from our tailored solutions and expert support to implement an ERP system that enhances efficiency and productivity across your organization. Experience the immediate impact of Deinertech ERP Solutions on your business.</p>
             
            </div>
          </div>

          <div class="col-md-5 order-1 order-md-2 custom-padding d-flex justify-content-end">
          <img class="erp-soluation-img " src={'/erp.png'} alt="Hospital Mang" />

          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-5 custom-padding d-flex justify-content-end">
            <img class="image-ourcompay " src={'./A Comprehensive ERP Solution.png'} alt="Hospital Mang" />

          </div>
          <div class="col-md-6 custom-padding ">
            <div class="content">
              <h2 class="ourservices-h2">A Comprehensive ERP Solution:</h2>
              <p class="ourcomay-p">In today's technology-driven business landscape, a comprehensive ERP solution is essential for driving development and success. At Deinertech Software, we understand the importance of efficient business operations as the backbone of national progress. Our tailored ERP solution is designed to empower businesses with utmost support, including optimizing order processing and enabling real-time tracking of deliveries. Experience the transformative
                 impact of Deinertech ERP Solutions in enhancing operational efficiency and driving business growth.</p>
             </div>
          </div>
         </div>
      </div>


      <div class="container">
        <div class="row">
          <div class="col-md-6 order-2 order-md-1 custom-padding ">
            <div class="content">
            <h2 class="ourservices-h2">Managing Business Becomes Easier:</h2>
              <p class="ourcomay-p">Managing your business becomes effortless with an advanced ERP Management System. 
                Stay informed and updated about every aspect of your business operations without constantly 
                relying on individual managers for updates. This ERP solution provides comprehensive tracking of
                 business progress, enabling you to identify and address any deficiencies affecting performance
                  swiftly and effectively. Experience the convenience and efficiency of modern ERP Solutions 
                  in optimizing your business management processes.</p>
             
            </div>
          </div>

          <div class="col-md-5  order-1 order-md-2 custom-padding d-flex justify-content-end">
          <img class="erp-soluation-img " src={'/managening business.png'} alt="Hospital Mang" />

          </div>
        </div>
      </div>

      
      
    
  



      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Hospital-Management-System" className="dome-link">
            <div class="card  custom-card  ourerp-card ">
              <img class="card-img-top custm-img1" src={'/doctore.png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h4 class="our-card">HOSPITAL MANAGEMENT SYSTEM</h4>
                <h6 class="our-info">Implementing a Hospital Management <br/> System (HMS) is crucial for optimizing <br/> healthcare operations. It enhances <br/> efficiency, patient care,  and overall management within medical facilities.</h6>
                <h6><Link to="/Hospital-Management-System" className="read-our">Read More</Link></h6>
              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/School-Management-System" className="dome-link">
            <div class="card custom-card   ourerp-card ">
              <img class="card-img-top custm-img1" src={'/college.png'} />
              <div class="card-body">
                <h4 class="our-card">UNIVERSITY / SCHOOL MANAGEMENT SYSTEM</h4>
                <h6 class="our-info">A reliable University/School Management System is essential for efficient academic administration. It streamlines processes, enhances communication, and facilitates student and staff management.</h6>
                <h6><Link to="/School-Management-System" className="read-our">Read More</Link></h6>


              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/Accounting-software" className="dome-link">
            <div class="card custom-card  ourerp-card ">
              
              <img class="card-img-top custm-img1" src={'/accounting (1).png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h4 class="our-card">ACCOUNTING SOFTWARE SOLUTIONS </h4>
                <h6 class="our-info">Selecting the right Accounting Software Solution is crucial for effective financial management. Our expertise in software development ensures tailored solutions that streamline accounting processes.</h6>
                <h6><Link to="/Accounting-software" className="read-our">Read More</Link></h6>

              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>



      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 ">
          <Link to="/payroll-software" className="dome-link">
            <div class="card  custom-card   ourerp-card">
              <img class="card-img-top custm-img1" src={'/dividend.png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h4 class="our-card">PAYROLL SOFTWARE SOLUTIONS</h4>
                <h6 class="our-info">Explore seamless payroll management <br/> with our innovative software solutions. <br/> Our platform automates payroll <br/> processes, simplifies tax calculations, <br/>and offers customizable reporting, empowering businesses to streamline operations.</h6>
                <h6><Link to="/payroll-software" className="read-our">Read More</Link></h6>



              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/inventory-software" className="dome-link">
            <div class="card  custom-card ourerp-card ">
              <img class="card-img-top custm-img1" src={'./custmoer.png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h5 class="our-card">INVENTORY SOFTWARE SOLUTIONS</h5>
                <h6 class="our-info">Efficient inventory management is vital for business success. Our Inventory Software Solutions provide comprehensive tools to streamline inventory tracking, optimize stock levels, and enhance supply chain efficiency. Whether you’re managing a small business or a  large enterprise, our software ensures you maintain the perfect balance between stock availability and operational efficiency. </h6>

                <h6><Link to="/inventory-software" className="read-our">Read More</Link></h6>



              </div>
            </div>
            </Link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/hr-software" className="dome-link">
            <div class="card  custom-card ourerp-card  ">
              <img class="card-img-top custm-img1" src={'./hrlogo.png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h4 class="our-card">HR SOFTWARE SOLUTIONS</h4>
                <h6 class="our-info">Efficient inventory management is vital for business success. Our Inventory Software Solutions provide comprehensive tools to streamline inventory tracking, optimize stock levels, and enhance supply chain efficiency, ensuring accurate forecasting, reducing operational costs.Experience seamless integration with your existing systems</h6>
                <h6><Link to="/hr-software" className="read-our">Read More</Link></h6>



              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>


      <div class="container cards-custm">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/event-management-system" className="dome-link">
            <div class="card  custom-card  ourerp-card">
              <img class="card-img-top custm-img1" src={'/network.png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h4 class="our-card">EVENT MANAGEMENT SYSTEM</h4>
                <h6 class="our-info">We’re passionate about everything we create. But our designs aren’t for us, our clients, or the judges of an aCreating an effective Event Management System requires expertise across multiple platforms. As a professional software development firm, we specialize in developing robust and responsive software.</h6>
                <h6><Link to="/event-management-system" className="read-our">Read More</Link></h6>



              </div>
            </div>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6">
          <Link to="/pos-software" className="dome-link">
            <div class="card custom-card   ourerp-card ">
              <img class="card-img-top custm-img1" src={'./experiences.png'} alt="Healthcare Icon" />
              <div class="card-body">
                <h4 class="our-card">POS SOFTWARE SOLUTIONS</h4>
                <h6 class="our-info">Our POS Software Solutions are designed to elevate your retail  experience. With user-friendly interfaces and robust features, our systems streamline transactions, optimize inventory control, and provide actionable insights for business growth and customer satisfaction.Our POS Software Solutions are designed to elevate your retail experience. </h6>

                <h6><Link to="/pos-software" className="read-our">Read More</Link></h6>



              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer></Footer>











    </>
  )
}
export default Deinererp;