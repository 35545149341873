import Requstf from "../Requst/Requst";
import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";




function Mobliedev() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  return (
    <>
       <Helmet>
        <title>Mobile App Development Deinertech Software</title>
        {/* <meta name="description" content="Transform ideas into reality with Deinertech Software's mobile app development services. We create feature-rich, user-friendly mobile apps that drive business growth." />
        <meta name="keywords" content="Mobile App Development, iOS, Android, Cross-Platform, Deinertech Software, App Development Services, Custom Mobile Apps, User Experience, Business Growth" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Mobile App Development | Deinertech Software" />
        <meta property="og:description" content="Transform ideas into reality with Deinertech Software's mobile app development services. We create feature-rich, user-friendly mobile apps that drive business growth." />
        <meta property="og:url" content="https://www.deinertech.com/Mobiledev" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>
      <div class="container-Moblieappdev">
        <div class="text-center mt-5">
          <h1 class="helth-h1">Mobile App Development</h1>
          <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
          <h6 class="Helath-h">Mobile App Development</h6>
        </div>
      </div>



      <div class="Acoount-info">
        <h3 class="conantctm-h2 mb-4 ">Transform Ideas Into Reality With Deinertech Software’s Mobile App Development Services</h3>

        <h6 class="dev-p1">In the mobile-first era, having a powerful and user-friendly mobile app is essential for businesses to engage customers, drive sales, and enhance brand loyalty. Deinertech Software specializes in creating innovative and feature-rich mobile apps that deliver exceptional user experiences and drive business growth.</h6>
      
        <h2 class="pos-h4">Our Mobile App Development Approach</h2>
      </div>


      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical">
                <h4 class="elc-h3">Customized Solutions</h4>
                <h6 class="elc-h6">We believe in turning your ideas and vision into
                  reality. Our team works closely with you to
                  understand your business goals, target audience,
                  and app requirements. We then design  and develop
                  customized mobile apps that meet your specific  needs.</h6>
              </div>
            </div>

            <div class="elc">
              <div class="box-graphical">
                <h4 class="elc-h3">User-Centric Design</h4>
                <h6 class="elc-h6">User experience (UX) is paramount in mobile
                  app development. We prioritize intuitive
                  navigation, engaging interfaces, fast load
                  times, and smooth functionality to ensure a
                  positive and enjoyable user experience</h6>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/mobiledev.png'} alt="Health Image" />


          </div>

          <div class="col-md-4">
            <div class="elc mb-4">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Platform Diversity</h4>
                <h6 class="elc-h6"> Whether you need an app for iOS, Android, or
                  cross-platform compatibility, we have the
                  expertise to deliver. Our developers stay
                  updated with the latest technologies and
                  development frameworks to create high-
                  performance apps that work seamlessly
                  across devices.</h6>


              </div>
            </div>

            




            <div class="elc">
              <div class="box-graphical right-border">
                <h4 class="elc-h3">Scalability and Flexibility</h4>
                <h6 class="elc-h6">Our mobile apps are built with scalability and flexibility in mind. Whether you're a startup  looking to launch your first app or an enterprise seeking to expand your mobile presence, our solutions can grow with your business.</h6>


              </div>
            </div>
          </div>
        </div>
      </div>

    


      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="Choose1">
              <h2 class="">Why Choose Deinertech Software for Mobile App Development?</h2>
     


              <div class="row mb-4 align-items-center">
                <div class="col-md-12 col-12 text-center d-flex built">
                  <i class="bi bi-1-circle-fill  number-icon"></i>
                  <h5 class="h5-hel">Expertise: Our team comprises experienced mobile app developers skilled in native app development, cross-platform development, and emerging technologies like AI and IoT.</h5>
                </div>
                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-2-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Innovative Solutions: We leverage the latest tools, frameworks, and technologies to create innovative and feature-rich mobile apps that stand out in the market.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-3-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Security and Compliance: We prioritize security measures and adhere to industry standards and regulations to ensure that your app is secure, compliant, and user-friendly.</h5>
                </div>

                <div class="col-md-11 col-12 d-flex built">
                  <i class="bi bi-4-circle-fill number-icon"></i>
                  <h5 class="h5-hel">Collaborative Approach: We believe in collaboration and transparency throughout the development process, keeping you informed and involved at every stage.</h5>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-sm-12 mx-auto d-block">
            <div class="box-start1  shadow rounded">
              <div class="box-contant">
                <h3 class="box-h3">Empower Your Business with a Custom Mobile App from Deinertech Software</h3>
                <h6 class="box-h6">Ready to revolutionize your business with a mobile app that engages users and drives results? Partner with Deinertech Software for professional mobile app development services that elevate your brand's mobile presence. Contact us today to discuss your app development needs and embark on a journey towards digital success.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
      <Footer></Footer>



















    </>
  )
}
export default Mobliedev;