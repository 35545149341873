// Reviewg.js
import React, { useRef, useEffect } from 'react';
import './Review.css'; // Import the CSS for styling

const Reviewg = () => {



  return (
<>


</>
  );
};

export default Reviewg;
