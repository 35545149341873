import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './accounting.css'
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";

function Accounting() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }
      const [activeCollapse, setActiveCollapse] = useState(null);
  
      const handleCollapseToggle = (id) => {
          setActiveCollapse(activeCollapse === id ? null : id);
      };
    return (

        <>
            <Helmet>
                <title>Accounting Software Deinertech Software</title>
                {/* <meta name="description" content="Explore our Business Accounting Software, designed for efficient bookkeeping, audit preparation, and comprehensive fiscal management. Accessible on all devices." />
                <meta name="keywords" content="Accounting Software, Business Accounting, Bookkeeping, Financial Reports, Invoice Management, Payroll Processing" />
                <meta name="author" content="DeinerTech Software Pvt Ltd" />

                <meta property="og:title" content="Accounting Software - DeinerTech Software" />
                <meta property="og:description" content="Explore our Business Accounting Software, designed for efficient bookkeeping, audit preparation, and comprehensive fiscal management. Accessible on all devices." />
                 <meta property="og:url" content="https://www.deinertech.com/Accounting-software" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="DeinerTech Software" /> */}

             
            </Helmet>
            <About></About>

            <div className="container-Acc">
                <div className="text-center mt-5">
                    <h1 className="hosp-h1">Accounting Software</h1>
                    <h6 className="hosp-h"><Link to="/" className="dropdown-item">Home <i className="bi bi-chevron-double-right"></i></Link></h6>
                    <h6 className="hosp-h">Accounting Software</h6>
                </div>
            </div>


            <div class="container">
                <div class="container-info">
                    <div class="row">
                        <div class="col-md-5 d-flex justify-content-center align-items-center">
                            <img class="image-info " src={'/Accounting-Software.png'} alt="Hospital Mang" />
                            {/* <img src="your-image.jpg" alt="Image" class="image"/> */}
                        </div>
                        <div class="col-md-7">
                            <div class="content">
                            <h1 className="account-heding"> <span className="account-h">Accounting </span> Management System </h1>
                            <h5 className="account-h4">Efficient Business Accounting Software</h5>
                            <p className="hos-p">Our Business Accounting Software offers an intuitive approach to organizing  your accounting department.
                                Designed with advanced user interface and  globally recognized accounting principles, it incorporates both global
                                standards and locally governed accounting practices. This comprehensive  software is suitable for all business types,
                                enabling efficient bookkeeping and audit preparation to monitor performance and make informed decisions.</p>

                            <h5 className="account-h4">Robust Accounting Management System</h5>
                            <p className="hos-p">Our Business Accounting Software is a robust Accounting Management  System
                                equipped with dynamic tools for day-to-day accounting cycles.  Generate various reports including journals,
                                T-accounts, profit and loss  statements, balance sheets, and comprehensive fiscal reports effortlessly. 
                                The software is user-friendly, requiring minimal training and basic  accounting knowledge to master its functionality. </p>

                            <h5 className="account-h4">Accessible Anytime, Anywhere</h5>
                            <p className="hos-p">Access our Accounting Software from any device, including smartphones,tablets, and computers,
                                offering online enterprise solutions without  restrictions. Rest assured, our software
                                prioritizes data security with highly adaptive security protocols to protect your valuable information. </p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            
  

            <div className="Acoount-info">
                <h3 className="account-h3"> Business <span className="acc-spn">Accounting Software</span> Module key</h3>
                <h4 className="h4-acc">Features:</h4>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 bulet-point-event">
                        <div className="bullet-points-left bullet-points p-md-5">
                            <ul>
                                <li>Invoicing &amp; Billing</li>
                                <li>Accounts Receivables</li>
                                <li>Accounts Payable</li>
                                <li>Sales Orders</li>
                                <li>Multi-Currency Invoicing</li>
                                <li>Recurring Billing</li>
                                <li>Expense Tracking</li>
                                <li>Expense Reimbursements</li>
                                <li>Bank Reconciliation</li>
                                <li>Voucher Customisations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="bullet-points p-md-5">
                            <ul className="left-ul">
                                <li>Payment Gateway Integration</li>
                                <li>Inventory Management</li>
                                <li>Multiple Warehouses</li>
                                <li>Purchase Orders &amp; Quotes</li>
                                <li>Vendor Management</li>
                                <li>Payroll Processing</li>
                                <li>Profit &amp; Loss Statement</li>
                                <li>Service Tax, VAT &amp; GST</li>
                                <li>Journals &amp; Ledgers</li>
                                <li>Financial Reports</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{marginTop:"-10px"}}>
              <div className="box-contant" >
                <h3 className="box-h3">Streamline Your Accounting Operations with Our Comprehensive Software</h3>
                <h6 className="box-h6">Our Accounting Software simplifies financial records, payroll, and performance tracking. It streamlines communication between accounting staff while providing real-time analytics for better decision-making. Easily manage invoices, expenses, and reports to save time and boost productivity.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
            <Footer></Footer>
        </>
    )
}
export default Accounting;
