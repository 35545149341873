import React from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
function Terms(){
    return(
       <>
         <Helmet>
        <title>Terms & Conditions Deinertech Software</title>
        {/* <meta name="description" content="Review the terms and conditions of using the services provided by Deinertech Software. Learn about our disclosure policies, security measures, and more." />
        <meta name="keywords" content="Terms & Conditions, Deinertech Software, Privacy Policy, Security, Disclosure" />
        <meta name="author" content="Deinertech Software" />
        <meta property="og:title" content="Terms & Conditions - Deinertech Software" />
        <meta property="og:description" content="Review the terms and conditions of using the services provided by Deinertech Software. Learn about our disclosure policies, security measures, and more." />
        <meta property="og:url" content="https://www.deinertech.com/Terms" />
      <meta property="og:type" content="website" /> */}
      </Helmet>
      <About></About>
       
              <div class="container-Expertise">
                <div class="text-center mt-5">
             
                    <h1 class="exper-h1">Term & Condition</h1>
                    
                    <h6 class="exper-h" ><Link to="/" className="dropdown-item" >Home <i class="bi bi-chevron-double-right"></i></Link></h6>
                    <h6 class="exper-h">Term & Condition</h6>
                </div>
            </div>
        


         <div className="col-md-10">
            <div className="content right-column bullet-points-right dataeng-col  ">
              <h2 class="retuen-h2">Disclosure and Onward Transfer</h2>
              <p class="retuen-h2">We will not sell or rent your personal information to any third party. We may disclose your information to:</p>
   
              <ul>
              <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>   Subsidiaries and affiliates</p>
              
              <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>    Vendors and service providers who assist us in operating the Site and Services</p>

              <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>   Government authorities or law enforcement agencies as required by law</p>

              
              <p class="return-p"> <i class="bi bi-circle-fill fill-circle"></i>    Successor entities in case of mergers or acquisitions </p>
           
              <p class="return-p"> We will ensure that any third-party receiving your information is contractually obligated to keep it confidential and secure. </p>

 
              </ul>
            </div>
          </div>
       

          <div className="col-md-10">
            <div className="content right-column bullet-points-right dataeng-col  ">
              <h4 class="retuen-h2">Links to Third-Party Sites</h4>
              <p class="retuen-h2">The Site may contain links to other websites not controlled by Deinertech. We are not responsible for the content or privacy practices of these external websites. <br/> We recommend reviewing their privacy policies before using them.</p>
             
   
            </div>
          </div>


          
          <div className="col-md-10">
            <div className="content right-column bullet-points-right dataeng-col  ">
              <h4 class="retuen-h2">Security</h4>
              <p class="retuen-h2">We employ various security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. These include firewalls, password protection, and secure data storage practices. However, no website or internet transmission is completely secure.</p>
              
   
            </div>
          </div>


          
          <div className="col-md-10">
            <div className="content right-column bullet-points-right dataeng-col  ">
              <h4 class="retuen-h2">Contact Us</h4>
              <p class="retuen-h2">If you have any questions regarding this Privacy Policy, please contact us at contact@deinertech.com. This Privacy Policy is created by Deinertech Software Private Limited.</p>
           
   
            </div>
          </div>
          <Footer></Footer>


        






        

 
</>
    )
}
 export default Terms;