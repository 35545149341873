import React, { useState } from "react";
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import './school.css';
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";

function Schoolm() {

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
  // State to keep track of which collapsible section is open
  const [activeCollapse, setActiveCollapse] = useState(null);

  // Function to handle the click event and update the activeCollapse state
  const handleCollapseToggle = (id) => {
    setActiveCollapse(activeCollapse === id ? null : id);
  };

  return (
    <>
      <Helmet>
        <title>School Management System-Deinertech</title>
        {/* <meta name="description" content="Explore our School Management System designed to support continuous administrative logs, improve school management, and provide resources for teachers and staff." />
        <meta name="keywords" content="School Management System, Education Management, School ERP, Student Records, College Management" />
        <meta name="author" content="DeinerTech Software Pvt Ltd" />
        <meta property="og:title" content="School Management System | DeinerTech" />
        <meta property="og:description" content="Explore our School Management System designed to support continuous administrative logs, improve school management, and provide resources for teachers and staff." />
        <meta property="og:url" content="https://www.deinertech.com/School-Management-System" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <About />

      <div className="container-SchoolM">
        <div className="text-center mt-5">
          <h1 className="hosp-h1">School Management System</h1>
          <h6 className="hosp-h">
            <Link to="/" className="dropdown-item">Home <i class="bi bi-chevron-double-right"></i></Link>
          </h6>
          <h6 className="hosp-h">School Management System</h6>
        </div>
      </div>



      <div class="container">
        <div class="container-info">
          <div class="row">
            <div class="col-md-5 d-flex justify-content-center align-items-center">
              <img class="image-info " src={'/school-management-hd-png-download.png'} alt="Hospital Mang" />
              {/* <img src="your-image.jpg" alt="Image" class="image"/> */}
            </div>
            <div class="col-md-7">
              <div class="content">
                <h2 className="account-heding">Online <span className="account-h">Retail University</span> & School Information System</h2>
                <h4 className="account-h4">Empower Your School with Our Comprehensive Management System</h4>
                <p className="hos-p">Our School Management System is designed to support continuous administrative logs and create a conducive environment for students.
                  While teachers play a vital role in school operations, effective management is equally essential.
                  Our School Management System facilitates smooth school functioning by providing teachers with audio/video resources and offering management modules for staff management logs, accountancy, stock management, and more.
                  The system also enhances communication between staff and parents, ensuring timely updates on student progress.
                  With real-time data tracking, school administrators can make informed decisions to improve educational outcomes.
                  Moreover, it simplifies attendance tracking and report generation, saving time and resources for the entire institution.

                </p>


              </div>
            </div>
          </div>
        </div>
      </div>





      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="hrms-info">

              <h4 className="hrms-h3">Salient Features Include:</h4>
              <ul className="custom-list">
                <li>Dashlet driven dashboard</li>
                <li>Innovative ‘Search bar’ enables anybody to use EXPERT School ERP System within seconds of login</li>
                <li>EXPERT School ERP Software is designed for a great user experience with a user friendly interface, meaning that anyone <br /> with basic  computer knowledge can quickly use the system with minimum training</li>
                <li>Easy to learn and easy to master</li>
                <li>Displays latest news, Events, discussions, birthdays, leave applications</li>


              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="collapse-div">
        <div class="container">
          <hr class="my-hr" />
        </div>
        <p className="collapse-p">
          <a
            className={`btn btn-white ${activeCollapse === 'collapseExample' ? 'active' : ''}`}
            role="button"
            onClick={() => handleCollapseToggle('collapseExample')}
          >
            Messages
          </a>
          <a
            className={`btn btn-white ${activeCollapse === 'collapseExample1' ? 'active' : ''}`}
            role="button"
            onClick={() => handleCollapseToggle('collapseExample1')}
          >
            Admission
          </a>
          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample2' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample2')}
          >
            Student Details
          </button>
          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample3' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample3')}
          >
            Manage Users
          </button>
          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample4' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample4')}
          >
            Manage News
          </button>
          <br />
          <br />


          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample6' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample6')}
          >
            Timetable
          </button>

          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample7' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample7')}
          >
            Attendance
          </button>


          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample8' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample8')}
          >
            Settings
          </button>


          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample9' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample9')}
          >
            Human Resources
          </button>

          <button
            className={`btn btn-white ${activeCollapse === 'collapseExample10' ? 'active' : ''}`}
            type="button"
            onClick={() => handleCollapseToggle('collapseExample10')}
          >
            Finance
          </button>
        </p>
        <div class="container">
          <hr class="my-hr" />
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample' ? 'show' : ''}`} id="collapseExample">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                  <li>Inbuilt messaging system</li>
                  <li>Easy and quick way to send messages to any user of the system</li>
                  <li>Effective communication between administration, teachers, students, and parents</li>
                  <li>Recording communications with students</li>
                  <li>Automatic messages about events, news, fee schedules, new forums, and holidays, etc., personalized with your school</li>
                  <li>Attach files with news</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample1' ? 'show' : ''}`} id="collapseExample1">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                  <li>Unique ID for all students</li>
                  <li>Comprehensive admission form</li>
                  <li>Multiple guardian addition facility</li>
                  <li>Emergency contact facility available</li>
                  <li>Previous education details can be recorded</li>
                  <li>Customizable as per school standards</li>
                  <li>Photo upload facility</li>
                  <li>Provide Biometric ID of the students</li>
                  <li>Manage student records. Attach any type of document and data related to student.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample2' ? 'show' : ''}`} id="collapseExample2">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                  <li>Normal student view facility based on batches</li>
                  <li>View complete information of a student from their profile regarding reports, fees, activities, remarks, records</li>
                  <li>Search existing and former students</li>
                  <li>Advanced search facility to search for specific type of students based on large number of filters</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample3' ? 'show' : ''}`} id="collapseExample3">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">

                  <li>Search for any users using the search bar</li>
                  <li>View profile of all users based on the roles</li>
                  <li> View/Edit users password and privileges</li>
                  <li>Set privileges to users as per their roles</li>
                  <li>Allows organization to set standards for different roles based on their responsibility</li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`collapse ${activeCollapse === 'collapseExample4' ? 'show' : ''}`} id="collapseExample4">
          <div class="container mt-4">
            <div class="row">
              <div className="col-12">
                <ul className="custom-list">
                  <li>Create/edit/delete news</li>
                  <li>Search for any news using search bar</li>
                  <li>View all news facility also available</li>
                  <li>Add News using rich text format</li>
                  <li>Edit/Delete news</li>
                  <li>Published news has the facility to comment also</li>
                  <li>Delete comment facility also available</li>
                  <li>Attach files to the news article</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

 


      <div className={`collapse ${activeCollapse === 'collapseExample6' ? 'show' : ''}`} id="collapseExample6">
        <div class="container mt-4">
          <div class="row">
            <div className="col-12">
              <ul className="custom-list">
                <li>Drag and drop timetable</li>
                <li>Alerts on subject limits per week while creating timetable</li>
                <li>Alerts on employee subject limits while creating timetable</li>
                <li>Conflict warnings if the same teacher assigned with another class for the same period</li>
                <li>Timetable creation in advance</li>
                <li>Edit/Delete timetable facility available</li>
                <li>Timetable tracking</li>
                <li>Effective employee work allotment</li>
                <li>View teacher’s timetable</li>
                <li>View complete institution timetable on just one click</li>

              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className={`collapse ${activeCollapse === 'collapseExample7' ? 'show' : ''}`} id="collapseExample7">
        <div class="container mt-4">
          <div class="row">
            <div className="col-12">
              <ul className="custom-list">
                <li>Easy marking of attendance</li>
                <li> Note/Remarks for attendance can be given</li>
                <li>Different types of attendance report can be taken</li>
                <li>Reports can be filtered using the available filters</li>
                <li>Integrate with hardware devices RFID and Biometric</li>

              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className={`collapse ${activeCollapse === 'collapseExample8' ? 'show' : ''}`} id="collapseExample8">
        <div class="container mt-4">
          <div class="row">
            <div className="col-12">

              <ul className="custom-list">
                <li> Basic education institute information can be given which is reflected on reports and financial reports</li>
                <li>   Basic configuration like country, currency, time zone, etc can be set</li>
                <li>  Addition of school logo and displayed on reports, receipts and login screens </li>
                <li>  Other general settings like enabling grading system as per school requirements, automatic unique ID for all the users, Oauth/Azure <br /> application integration </li>
                <li>  Managing courses and batches</li>
                <li>  Batch transfer and graduation facility, generate Transfer Certificates</li>
                <li>  Managing major subjects including elective subjects</li>
                <li> Importing subjects from previous batches</li>
                <li> Add custom fields for admission form</li>
                <li>  Activate SMS module</li>
                <li>  Automatic SMS alerts</li>
                <li>  Set SMS configuration for alerts to be send to specific contact numbers for respective actions</li>
                <li>  Send SMS to single or group users or to all users</li>
                <li>Manage student categories</li>
                <li> Assign Tutors to Batches</li>
                <li> Assign roll numbers to students</li>

              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className={`collapse ${activeCollapse === 'collapseExample9' ? 'show' : ''}`} id="collapseExample9">
        <div class="container mt-4">
          <div class="row">
            <div className="col-12">
              <ul className="custom-list">
                <li>Manage all employees from admission to exit</li>
                <li> Customizable admission form</li>
                <li>Manage entire payroll efficiently</li>
                <li>Customizable and robust payroll form</li>
                <li>Automatic Loss of Pay calculation</li>
                <li>Generate pay slips on daily, weekly, monthly basis</li>
                <li> Efficient leave management system available</li>
                <li>Authenticated pay slip approval system</li>
                <li>One click pay slip approval and reject facilities</li>
                <li>Normal and advanced search facility</li>


              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className={`collapse ${activeCollapse === 'collapseExample10' ? 'show' : ''}`} id="collapseExample10">
        <div class="container mt-4">
          <div class="row">
            <div className="col-12">
              <ul className="custom-list">


                <li>Comprehensive Fee classification system</li>
                <li>Separate fee collection date designing system for faster finance process</li>
                <li>Easy analysis of fee defaulters and fee structure available</li>
                <li>Easy fee submission process</li>
                <li> Fee import facility for faster system use</li>
                <li> Manage and Pay instant fees</li>
                <li> Manage entire expenses and incomes</li>
                <li>Customize and manage different category of expenses and incomes</li>
                <li>Adjust settings for printing fee receipts</li>
                <li>  Financial Report generation facility with customizable date range</li>
                <li>Online payment facility</li>
                <li>Pay slip approval system</li>
                <li> Manage Assets and Liabilities</li>
                <li> Manage Donations</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container"  >
        <div className="row justify-content-center">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="box-start2 shadow rounded" style={{ marginTop: "-10px" }}>
              <div className="box-contant" >
                <h3 className="box-h3">Transform Your School Operations with Deinertech Software</h3>
                <h6 className="box-h6">Ready to implement a comprehensive and user-friendly School Management System that enhances educational administration? Partner with Deinertech Software for professional software solutions that streamline your school’s operations. Contact us today to discuss your needs and take a step towards modernizing your educational management.</h6>
                <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}

      <Footer />
    </>
  );
}

export default Schoolm;
