
import About from "../Navbar/About";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import Requstf from "../Requst/Requst";
import React, { useState } from "react";



function Emailm(){
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  }
    return(
       <>
         <Helmet>
        <title>Email Marketing Deinertech Software</title>
        {/* <meta name="description" content="Unlock the power of email marketing with Deinertech Software. Build relationships, drive conversions, and achieve your business goals with our comprehensive email marketing solutions." />
        <meta name="keywords" content="Email Marketing, Email Campaigns, Marketing Automation, Email Content Creation, Deinertech Software" />
        <meta name="author" content="Deinertech Software Pvt Ltd" />
        <meta property="og:title" content="Email Marketing | Deinertech Software" />
        <meta
          property="og:description"
          content="Unlock the power of email marketing with Deinertech Software. Build relationships, drive conversions, and achieve your business goals with our comprehensive email marketing solutions."
        />
        <meta property="og:url" content="https://www.deinertech.com/Email" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Deinertech Software" /> */}
      </Helmet>
       <About></About>
       <div class="container-Email">
    <div class="text-center mt-5">
      <h1 class="helth-h1">Email Marketing</h1>
      <h6 class="Helath-h" ><Link to="/" className="dropdown-item" >Home  <i class="bi bi-chevron-double-right"></i></Link></h6>
      <h6 class="Helath-h">Email Marketing</h6>
    </div>
  </div>
       


            <div class="Acoount-info">
            <h2 class="conantctm-h2 mb-3">Unlock The Power Of Email Marketing With Deinertech Software</h2>
        
          <h6 class="dev-p1">Email marketing remains one of the most effective channels for reaching and engaging your target audience.
             At Deinertech Software, we offer comprehensive email marketing solutions designed to help you build relationships,
              drive conversions, and achieve your business goals.</h6>
           
             <h2 class="pos-h4">Our Email Marketing Approach</h2>
         </div>

         <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical">
                    <h4 class="elc-h3">Comprehensive Email Marketing Strategy</h4>
                    <h6 class="elc-h6">We begin with a thorough assessment of your business goals and customer insights. Our team crafts a tailored email marketing strategy that effectively engages your audience and drives measurable results.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical">
                    <h4 class="elc-h3">Engaging Content Development</h4>
                    <h6 class="elc-h6">Our talented content creators craft compelling email content tailored to your audience. From captivating newsletters to enticing promotional messages, we ensure every email delivers value and encourages action.</h6>
                </div>
            </div>
        </div>

        <div class="col-md-4 text-center">
            <img class="Helth-img mb-4" src={'/Email-Marketing.png'} alt="Email Marketing" />
        </div>

        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Subscriber Growth & Targeting</h4>
                    <h6 class="elc-h6">Building a robust email list is essential for success. We help you attract subscribers organically and segment your audience based on preferences and behaviors to deliver personalized content that resonates.</h6>
                </div>
            </div>

            <div class="elc">
                <div class="box-graphical right-border">
                    <h4 class="elc-h3">Smart Automation & Customization</h4>
                    <h6 class="elc-h6">Harness the power of automation to optimize your email marketing. We create automated workflows and personalized campaigns that send tailored messages at the right time to enhance engagement and conversion.</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="elc mb-4">
                <div class="box-social">
                    <h4 class="elc-h3">Performance Tracking & Enhancement</h4>
                    <h6 class="elc-h6">We continuously monitor vital metrics like open rates and click-through rates. Using data-driven insights, we refine your email campaigns to improve performance and maximize your return on investment.</h6>
                </div>
            </div>
        </div>
    </div>
</div>


          
  {/* <div class="container">
    <div class="row justify-content-center">
    <div class="col-md-4">
     <div class="elc mb-4">
          <div class="box-graphical">
            <h4 class="elc-h3">Strategic Planning</h4>
            <h6 class="elc-h6">We start by understanding your business objectives, target audience, and unique selling propositions. Our team then develops a strategic email marketing plan that aligns with your goals and delivers measurable results.</h6>
                
                
           
           
             
         </div>
        </div>

        <div class="elc">
          <div class="box-graphical">
            <h4 class="elc-h3">Compelling Content Creation</h4>
            <h6 class="elc-h6">Our team of content creators and designers  craft engaging and relevant email content that resonates  with your audience. Whether it's newsletters, promotional  offers, or educational content, we ensure each email delivers  value and drives action. </h6>  
            </div>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <img class="Helth-img mb-4" src={'/Email-Marketing.png'} alt="Health Image"/>
        
  
      </div>
                
      <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">List Building and Segmentation</h4>
            <h6 class="elc-h6">Building a quality email list is crucial for
            success. We help you grow your subscriber base organically and segment your list based
            on demographics, behaviors, and interests to deliver personalized content.</h6>
       
          
          </div>
        </div>


      

        <div class="elc">
          <div class="box-graphical right-border">
            <h4 class="elc-h3">Automation and Personalization</h4>
            <h6 class="elc-h6">Leverage the power of automation to streamline 
             your email marketing efforts. We set up automated
            workflows, personalized email campaign,and dynamic content to deliver targeted  messages
            at the right time.</h6> 


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
     <div class="col-md-4">
        <div class="elc mb-4">
          <div class="box-social">
            <h4 class="elc-h3">Testing and Optimization</h4>
            <h6 class="elc-h6">We continuously monitor and analyze key metrics such as open rates, click-through rates, and conversions. Based on data-driven insights, we optimize your email campaigns for improved performance and ROI.</h6>

         </div>
        </div>
        </div>
        </div>
     <br/> */}

     <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="Choose2">
          <h2 class="">Why Choose Deinertech Software for Email Marketing?</h2>
          <br/>
        
          <div class="row mb-4 align-items-center">
            <div class="col-md-12 col-12 text-center d-flex built">
            <i class="bi bi-1-circle-fill  number-icon"></i>
            <h5 class="h5-hel">Expertise: Our team has extensive experience in crafting and executing successful email marketing campaigns across various industries.</h5>
            </div>
            <div class="col-md-11 col-12 d-flex built">
            <i class="bi bi-2-circle-fill number-icon"></i>
            <h5 class="h5-hel">Personalization: We believe in the power of personalized communication to drive engagement and conversions.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-3-circle-fill number-icon"></i>
             <h5 class="h5-hel">Compliance: We adhere to industry best practices and regulations to ensure compliance with email marketing laws and standards.</h5>
             </div>

             <div class="col-md-11 col-12 d-flex built">
             <i class="bi bi-4-circle-fill number-icon"></i>
             <h5 class="h5-hel">Reporting and Analytics: Gain valuable insights into your email campaign performance with detailed reporting and analytics, allowing you to make informed decisions and drive continuous improvement.</h5>
             </div>
          </div>
  

               
        </div>
      </div>
   </div>
  </div>
  <br/>
  <br/>

 
<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12 mx-auto d-block">
            <div class="box-start2">
              <div class="box-contant">
                <h3 class="box-h3">Get Started with Email Marketing from Deinertech Software</h3>
                <h6 class="box-h6">Ready to harness the full potential of email marketing for your business? Partner with Deinertech Software and let 
                us help you create impactful email campaigns that drive results. Contact us today to discuss your email marketing 
                needs and take your digital marketing efforts to new heights.</h6>
                  <button onClick={togglePopup} className="btn btn-primary web-btn">REQUEST A QUOTE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  {isPopupOpen && <Requstf onClose={togglePopup} />} {/* Render the form if the popup is open */}
        <Footer></Footer>



   

         













       </>
    )
}
export default Emailm;